import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import store from "@/store";

import { getTags, postTag, editTag, deleteTag } from "@/api/django/tags";
import Logger from "@/services/LoggerService";

import { editDocumentTags } from "@/api/django/documents";
import { ApiTag, ApiTagPost } from "../../static/apiModels";

import { SettingsModule } from "./settings";

import { CollectionsModule } from "./collections";

import { NotificationsModule } from "./notifications";

export interface TagSlot {
  tag: ApiTag | null;
  key: string;
}

export interface ITagsState {
  tags: ApiTag[];
  loading: boolean;
  slots: TagSlot[];
  tagSelectionDialog: boolean;
  showSingleTagSlotSelectionDialog: boolean;
  createTagDialog: boolean;
  editDialog: boolean;
  selectedTag: ApiTag | null;
  tagSelectionMode: "🔫" | "🎹";
}

const l = new Logger("TagsModule");

@Module({
  dynamic: true,
  store,
  name: "tags",
  namespaced: true,
})
class Tags extends VuexModule implements ITagsState {
  tags: ApiTag[] = [];

  loading: boolean = false;

  tagSelectionDialog = false;

  showSingleTagSlotSelectionDialog = false;

  createTagDialog = false;

  editDialog = false;

  slots: TagSlot[] = [
    {
      key: "a",
      tag: null,
    },
    {
      key: "s",
      tag: null,
    },
    {
      key: "d",
      tag: null,
    },
    {
      key: "f",
      tag: null,
    },

    {
      key: "q",
      tag: null,
    },

    {
      key: "w",
      tag: null,
    },

    {
      key: "e",
      tag: null,
    },

    {
      key: "r",
      tag: null,
    },
  ];

  possibleSlots: TagSlot[] = [
    {
      key: "a",
      tag: null,
    },
    {
      key: "s",
      tag: null,
    },
    {
      key: "d",
      tag: null,
    },
    {
      key: "f",
      tag: null,
    },

    {
      key: "q",
      tag: null,
    },

    {
      key: "w",
      tag: null,
    },

    {
      key: "e",
      tag: null,
    },

    {
      key: "r",
      tag: null,
    },

    {
      key: "y",
      tag: null,
    },

    {
      key: "x",
      tag: null,
    },

    {
      key: "c",
      tag: null,
    },

    {
      key: "v",
      tag: null,
    },

    {
      key: "t",
      tag: null,
    },

    {
      key: "g",
      tag: null,
    },

    {
      key: "b",
      tag: null,
    },

    {
      key: "z",
      tag: null,
    },

    {
      key: "h",
      tag: null,
    },

    {
      key: "n",
      tag: null,
    },
  ];

  tagSlotToEdit: TagSlot | null = null;

  selectedTag: ApiTag | null = null;

  tagSelectionMode: "🔫" | "🎹" = "🔫";

  get getTagSelectionMode() {
    return this.tagSelectionMode;
  }

  get selectionModeIsGun() {
    return this.tagSelectionMode === "🔫";
  }

  @Mutation
  switchTagSelectionMode() {
    this.tagSelectionMode = this.tagSelectionMode === "🎹" ? "🔫" : "🎹";
  }

  get getSelectedTag() {
    return this.selectedTag;
  }

  get tagShootingMode() {
    return SettingsModule.getTaggingMode && this.getSelectedTag;
  }

  get slotToEdit() {
    return this.tagSlotToEdit;
  }

  @Mutation
  setSlotToEdit(slotToEdit: TagSlot | null) {
    this.tagSlotToEdit = slotToEdit;
  }

  @Mutation
  setSelectedTag(selectedTag: ApiTag | null) {
    this.selectedTag = selectedTag;
  }

  @Mutation
  setDialog(val: boolean) {
    this.tagSelectionDialog = val;
  }

  @Mutation
  setSingleTagSlotSelectionDialog(val: boolean) {
    this.showSingleTagSlotSelectionDialog = val;
  }

  @Mutation
  setCreationDialog(val: boolean) {
    this.createTagDialog = val;
  }

  @Action
  editTagServerside(tag: ApiTag) {
    editTag(tag);
    this.editTaginTags(tag);
  }

  @Mutation
  editTaginTags(tag: ApiTag) {
    // eslint-disable-next-line
    this.tags.forEach(function (item) {
      if (item.url === tag.url) {
        /* eslint-disable no-param-reassign */
        item = tag;
        /* eslint-enable no-param-reassign */
      }
    });
  }

  @Action({ rawError: true })
  deleteTagOnServer(tagUrl: string) {
    deleteTag(tagUrl);
    this.removeTagFromSlot(tagUrl);
    this.deleteTaginTags(tagUrl);
  }

  @Mutation
  deleteTaginTags(tagUrl: string) {
    let tempTag: ApiTag;
    // eslint-disable-next-line
    this.tags.forEach(function (item) {
      if (item.url === tagUrl) {
        /* eslint-disable no-param-reassign */
        tempTag = item;
        /* eslint-enable no-param-reassign */
      }
    });
    // @ts-ignore
    this.tags.splice(this.tags.indexOf(tempTag), 1);
  }

  @Mutation
  removeTagFromSlot(tagUrl: string) {
    for (let index = 0; index < this.slots.length; index += 1) {
      const tagSlot = this.slots[index];
      if (tagSlot.tag !== null) {
        if (tagSlot.tag.url === tagUrl) this.slots[index].tag = null;
      }
    }
  }

  // TODO: Implement
  @Mutation
  removeTagFromSlotToEdit() {
    const currSlotToEdit = this.tagSlotToEdit;

    // Assign tag to slot
    if (currSlotToEdit) {
      this.slots.forEach((tagSlot, index) => {
        if (tagSlot.tag === currSlotToEdit.tag) {
          this.slots[index].tag = null;
        }
      });
    }
  }

  @Mutation
  removeTagFromSlots(tag: ApiTag) {
    // Check if Tag is assigned to a slot
    if (
      !(
        this.slots.filter((tagSlot) => {
          if (tagSlot.tag) return tagSlot.tag === tag;
          return false;
        }).length > 0
      )
    ) {
      NotificationsModule.setError({
        message: `Tag ${tag.name} can't be removed from a slot since it isn't assigned to any slot yet.`,
        icon: "mdi-ambulance",
      });
      // console.warn("Tag hasn't been assigned to any slot.");
      return;
    }

    this.slots.forEach((tagSlot, index) => {
      if (tagSlot.tag === tag) {
        this.slots[index].tag = null;
      }
    });
  }

  @Mutation
  adaptAvailableSlots(count: number) {
    this.slots = [];
    for (let i = 0; i < count; i += 1) {
      this.slots.push(this.possibleSlots[i]);
    }
  }

  @Mutation
  addNewSlot() {
    if (this.slots.length < this.possibleSlots.length)
      this.slots.push(this.possibleSlots[this.slots.length]);
  }

  // Can't use more than one Value for Mutations
  @Mutation
  addTagToSlotToEdit(tag: ApiTag) {
    const currSlotToEdit = this.tagSlotToEdit;

    // Check if Tag is already assigned to a slot
    if (
      this.slots.filter((tagSlot) => {
        if (tagSlot.tag) return tagSlot.tag === tag;
        return false;
      }).length > 0
    ) {
      NotificationsModule.setError({
        message: `Tag ${tag.name} has already been assigned to a different slot.`,
        icon: "mdi-ambulance",
      });

      return;
    }

    // Assign tag to slot
    if (currSlotToEdit) {
      this.slots.forEach((tagSlot, index) => {
        if (tagSlot.key === currSlotToEdit.key) {
          this.slots[index].tag = tag;
          this.showSingleTagSlotSelectionDialog = false;
        }
      });
    }
  }

  @Mutation
  setEditDialog(val: boolean) {
    this.editDialog = val;
  }

  get getEditDialog() {
    return this.editDialog;
  }

  get selectionDialog() {
    return this.tagSelectionDialog;
  }

  get singleTagSlotSelectionDialog() {
    return this.showSingleTagSlotSelectionDialog;
  }

  get creationDialog() {
    return this.createTagDialog;
  }

  get getSlots() {
    return this.slots;
  }

  get getTags(): ApiTag[] {
    return this.tags;
  }

  get getTag(): (url: string) => ApiTag | null {
    return (url: string) => {
      const tag = this.tags.find((t) => t.url === url);
      return tag || null;
    };
  }

  @Action
  postTheDamnTag(tag: ApiTagPost) {
    postTag(tag).then((resp) => {
      NotificationsModule.setSuccess({
        message: `successfully created ${resp.data.name}`,
        icon: "mdi-tag-plus",
      });
      this.context.commit("addTagMutation", resp.data);
    });
  }

  // returns an array of cthe tags in a minimum string to identify them by name.
  get uniqueTags() {
    const names = this.tags.map((tag) => tag.name);
    return names.map((tag, catIndex) => {
      for (let i = 0; i < tag.length; i += 1) {
        const stw = tag.substr(0, i + 1);
        const ambigious = names.some((name, index) => {
          if (index === catIndex) {
            return false;
          }
          return name.startsWith(stw);
        });
        if (!ambigious) {
          return stw;
        }
      }
      return tag;
    });
  }

  get uniqueTag() {
    return (name: string) => {
      return this.uniqueTags[this.tags.findIndex((tag) => tag.name === name)];
    };
  }

  get byUniqueName() {
    return (name: string) => {
      const tag = this.tags.find((tagItem) => {
        return tagItem.name.startsWith(name);
      });

      return tag || {};
    };
  }

  get docHasTag(): Function {
    // @ts-ignore
    return ({ tag, document }) => {
      let hasDocTag = false;
      // @ts-ignore
      document.tag.forEach((element) => {
        if (element === tag.url) {
          hasDocTag = true;
        }
      });
      return hasDocTag;
    };
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  get getLoading() {
    return this.loading;
  }

  @Mutation
  setSlots(slots: TagSlot[]) {
    this.slots = slots;
  }

  @MutationAction({ mutate: ["tags", "loading"] })
  async fetchTags() {
    // @ts-ignore
    this.commit("setLoading", true);
    this.loading = true;
    try {
      const response = await getTags();
      return { tags: response.data.results, loading: false };
    } catch (err: any) {
      // todo build similar messages for collections and all other modules
      NotificationsModule.setError({
        fullData: err.response.data,
        message: "Could not fetch Tags :/ -Please reload the page or try again later",
        icon: "mdi-ambulance",
      });
    }
    return { tags: this.tags, loading: false };
  }

  @Mutation
  addTagMutation(tag: ApiTag) {
    if (!this.tags.find((t) => t.url === tag.url)) {
      this.tags.push(tag);
      this.tags = Object.assign([], this.tags);
      const emptySlot = this.slots.findIndex((slot) => slot.tag === null);
      l.debug("found empty slotindex", emptySlot);
      if (emptySlot !== null) {
        this.slots[emptySlot].tag = tag;
      }
    }
  }

  /*
  @Mutation
  removeTagMutation(tagUrl: string) {
    if (this.getTag(tagUrl)) {
    //@ts-ignore
    let index = this.tags.indexOf(this.getTag(tagUrl));
    this.tags.splice(index, 1);
    }
  } */

  @Action
  async addTag(tag: ApiTag) {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await postTag(tag);
    // @ts-ignore
    this.commit("addTagMutation", response.data);
    // @ts-ignore
    this.commit("setLoading", false);
  }

  // assumes tag already existst
  @Action
  // @ts-ignore
  async addTagToDocument({ tag, document }) {
    l.debug("addTagToDocument", tag, document);
    if (!tag.url) {
      l.error("only add existing tags to documents");
    }
    l.debug("if ok");
    const newDoc = JSON.parse(JSON.stringify(document));
    newDoc.tag.push(tag.url);
    const response = await editDocumentTags(newDoc);
    NotificationsModule.setSuccess({
      // @ts-ignore
      message: `added tag ${this.getTag(tag.url).name} to ${document.es_id}`,
      icon: "mdi-tag-plus",
    });
    // @ts-ignore
    CollectionsModule.updateDocument(response.data);
  }

  @Action
  // @ts-ignore
  async removeTagfromDocument({ tag, document }) {
    l.debug("removeTagfromDocument", tag, document);
    if (!tag.url) {
      l.error("only add existing tags to documents");
    }
    const newDoc = JSON.parse(JSON.stringify(document));
    // @ts-ignore
    newDoc.tag = document.tag.filter((t) => {
      return t !== tag.url;
    });
    l.debug("push ok");
    const response = await editDocumentTags(newDoc);
    // @ts-ignore
    NotificationsModule.setError({
      // @ts-ignore
      message: `removed tag ${this.getTag(tag.url).name} from ${document.es_id}`,
      icon: "mdi-delete",
    });
    // @ts-ignore
    CollectionsModule.updateDocument(response.data);
  }
}

export const TagsModule = getModule(Tags);
