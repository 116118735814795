import { SingleCollection, CollectionBaseInfo } from "@/static/apiModels";

export function singleCollectionToCollectionBaseInfo(sc: SingleCollection): CollectionBaseInfo {
  const baseInfo: CollectionBaseInfo = {
    id: sc.id,
    url: sc.url,
    title: sc.title,
    description: sc.description,
    document_count: sc.es_document.length,
    category: sc.category,
    public: sc.public,
    created: sc.created,
    modified: sc.modified,
  };
  return baseInfo;
}
