/** using https://codepen.io/nourabusoud/pen/zpMVXB MIT - license */
<template>
  <div class="switch-wrapper">
    <input
      data-test="darkmode-switch"
      id="switchCheckbox"
      :checked="darkmode"
      @change="switchDarkmode"
      type="checkbox"
    />
    <label
      for="switchCheckbox"
      class="switch"
      >{{ label || "" }}</label
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SettingsModule } from "../store/modules/settings";

@Component({
  components: {},
  name: "DarkmodeSwitch",
})
export default class DarkmodeSwitch extends Vue {
  @Prop(String) label: string | undefined;

  get darkmode() {
    return SettingsModule.darkmode;
  }

  switchDarkmode() {
    SettingsModule.switchDarkmode();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$switch-width: 45px;
$switch-height: 22.5px;
$switchToggle-width: $switch-height;

$sky-day: #2196f3;
$sky-night: #023660;
$sun: #ffeb3b;
$sun-center: #ffc107;
$sun-light: rgba(255, 235, 255, 0.4);
$moon: #ddd;
$moon-light: rgba(255, 255, 255, 0.2);
$star: #fff;
$cloud: #fefefe;

body {
  padding: 20px;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  color: $sky-night;
  background-color: lighten($moon, 10%);
}

h1 {
  font-size: 1.8em;
}

.switch-wrapper {
  margin: 30px 0;
  font-size: 0;
}

.switch-wrapper input {
  display: none;
}

.switch-label {
  font-size: 1rem;
  display: inline-block;
  line-height: $switch-height;
  vertical-align: top;
  padding: 5px 10px;
  transition: transform ease-in 0.2s;
  font-weight: bold;
  opacity: 0.6;
}

.switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  cursor: pointer;
  border-radius: $switch-width/2;
  border: 2px solid #eee;
  background-color: $sky-day;
  background-image: radial-gradient(circle, $cloud 20%, transparent 30%),
    radial-gradient(circle, $cloud 20%, transparent 30%),
    radial-gradient(circle, $cloud 20%, transparent 30%),
    radial-gradient(circle, $cloud 20%, transparent 30%),
    radial-gradient(circle, $cloud 20%, transparent 30%),
    radial-gradient(circle, $cloud 20%, transparent 30%);
  background-size:
    50% 50%,
    60% 60%,
    40% 40%,
    40% 40%,
    50% 50%,
    40% 40%;
  background-position:
    60% 82%,
    80% 80%,
    82% 80%,
    110% 20%,
    134% 12%,
    130% 20%;
  background-repeat: no-repeat;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}

.switch:before {
  position: absolute;
  content: "";
  height: $switch-height - 5;
  width: $switch-height - 5;
  top: 50%;
  left: 2px;
  cursor: pointer;
  transform: translateY(-50%);
  background-color: $sun;
  background-image: radial-gradient(circle, $sun-center 15%, $sun 100%);
  box-shadow: 0px 0px 20px 10px $sun-light;
  transition: transform ease-in 0.2s;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.switch-label.day {
  transform: scale(1.1);
  opacity: 1;
}

input:checked ~ .switch {
  background-color: $sky-night;
  background-image: radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%),
    radial-gradient(circle, $star 10%, transparent 30%);
  background-size:
    8% 8%,
    7% 7%,
    6% 6%,
    8% 8%,
    8% 8%,
    6% 6%,
    6% 6%,
    8% 8%,
    7% 7%;
  background-position:
    20% 20%,
    45% 30%,
    25% 55%,
    20% 90%,
    40% 60%,
    5% 50%,
    58% 10%,
    58% 60%,
    50% 80%;
  background-repeat: no-repeat;

  &:before {
    background-color: $moon;
    background-image: radial-gradient(circle, darken($moon, 10%) 50%, transparent 50%),
      radial-gradient(circle, darken($moon, 8%) 50%, transparent 50%),
      radial-gradient(circle, darken($moon, 8%) 50%, transparent 50%),
      radial-gradient(circle, darken($moon, 8%) 50%, transparent 50%);
    background-size:
      40% 40%,
      25% 25%,
      50% 50%,
      30% 30%;
    background-position:
      20% 20%,
      65% 30%,
      120% 100%,
      20% 80%;
    box-shadow: 0px 0px 20px 10px $moon-light;
  }
}

.switch-wrapper input:checked {
  ~ .switch-label.day {
    transform: scale(1);
    opacity: 0.6;
  }

  ~ .switch-label.night {
    transform: scale(1.1);
    opacity: 1;
  }
}

.switch-wrapper input:checked ~ .switch:before {
  transform: translate($switch-width - $switchToggle-width, -50%);
}
</style>
