import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";

import Vue from "@/main";
import store from "@/store";
import Logger from "@/services/LoggerService";
import { NotificationsModule } from "./notifications";
import { ApiTag } from "../../static/apiModels";
import { TagsModule } from "./tags";

interface ISettingsState {
  rightSideBar: boolean;
  darkmode: boolean;
  docPagination: object;
  notifications: boolean;
  language: string;
  taggingMode: boolean;
  selectedTag: ApiTag | null;
  headerDialog: boolean;
  TeiEditDialog: boolean;
}

const l = new Logger("SettingsModule.vue");

@Module({
  dynamic: true,
  store,
  name: "settings",
  namespaced: true,
})
class Settings extends VuexModule implements ISettingsState {
  rightSideBar: boolean = false;

  darkmode: boolean = true;

  notifications: boolean = false;

  language = "de";

  docPagination: object = {
    itemsPerPage: 25,
    sortBy: [],
  };

  taggingMode = false;

  selectedTag: ApiTag | null = null;

  headerDialog = false;

  TeiEditDialog: boolean = false;

  get getPagination() {
    return this.docPagination;
  }

  @Mutation
  setDocPagination(val: object) {
    this.docPagination = val;
  }

  get getTaggingMode() {
    return this.taggingMode;
  }

  get getTeiEditDialog() {
    return this.TeiEditDialog;
  }

  @Mutation
  setFromStorage(settings: ISettingsState) {
    this.rightSideBar = settings.rightSideBar;
    this.darkmode = settings.darkmode;
    this.notifications = settings.notifications;
    this.language = settings.language;
    this.taggingMode = settings.taggingMode;
    this.selectedTag = settings.selectedTag;
    this.headerDialog = settings.headerDialog;
    this.TeiEditDialog = settings.TeiEditDialog;
    this.docPagination = settings.docPagination;
  }

  @Mutation
  setTeiEditDialog(TeiEditDialog: boolean) {
    this.TeiEditDialog = TeiEditDialog;
  }

  @Mutation
  setTaggingMode(taggingMode: boolean) {
    this.taggingMode = taggingMode;
  }

  get getHeaderDialog() {
    return this.headerDialog;
  }

  get sidebar() {
    return this.rightSideBar;
  }

  @Mutation
  setHeaderDialog(headerDialog: boolean) {
    this.headerDialog = headerDialog;
    this.rightSideBar = headerDialog;
  }

  @Mutation
  switchHeaderDialog() {
    this.headerDialog = !this.headerDialog;
    this.rightSideBar = this.headerDialog;
  }

  @Mutation
  switchTaggingMode() {
    this.taggingMode = !this.taggingMode;
  }

  @Mutation
  leaveTaggingMode() {
    l.debug("switchTaggingMode was:", this.taggingMode);
    if (!TagsModule.selectionModeIsGun) {
      TagsModule.switchTagSelectionMode();
    }
    this.taggingMode = false;
  }

  @Mutation
  setSelectedTag(selectedTag: ApiTag) {
    this.selectedTag = selectedTag;
  }

  get getSelectedTag() {
    return this.selectedTag;
  }

  get getDarkmode() {
    return this.darkmode;
  }

  @Mutation
  setLanguage(lang: string) {
    this.language = lang;
    l.debug("locale", Vue.$i18n.locale);
    Vue.$vuetify.lang.current = lang;
    Vue.$i18n.locale = lang;
    l.debug("locale", Vue.$i18n.locale);
  }

  get getLanguage() {
    return this.language;
  }

  get getNotificationsStatus() {
    return this.notifications;
  }

  @Mutation
  setDarkmode(value: boolean) {
    Vue.$vuetify.theme.dark = value;
    l.log("setDarkmode", value);
    this.darkmode = value;
  }

  @Mutation
  setNotifications(value: boolean): void {
    l.log("setNotifications", value);
    this.notifications = value;
  }

  @Action
  switchDarkmode() {
    // time out is just for the smoothness of transition
    l.log("switch darkmode");
    setTimeout(() => {
      this.context.commit("setDarkmode", !this.darkmode);
    }, 200);
    if (this.darkmode) {
      NotificationsModule.setNeutral({
        message: "turning off the lights",
        icon: "mdi-weather-night",
      });
    } else {
      NotificationsModule.setNeutral({
        message: "turning on the lights",
        icon: "mdi-weather-sunny",
      });
    }
  }

  @Mutation
  switchNotifications(): void {
    this.notifications = !this.notifications;
  }
}

const SettingsModule = getModule(Settings);

export { SettingsModule, ISettingsState };
