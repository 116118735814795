import { render, staticRenderFns } from "./editTags.vue?vue&type=template&id=ee1d185a"
import script from "./editTags.vue?vue&type=script&lang=ts"
export * from "./editTags.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../layers/heroku_nodejs-pnpm-install/virtual/store/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.2.5_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/layers/heroku_nodejs-pnpm-install/virtual/store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ee1d185a')) {
      api.createRecord('ee1d185a', component.options)
    } else {
      api.reload('ee1d185a', component.options)
    }
    module.hot.accept("./editTags.vue?vue&type=template&id=ee1d185a", function () {
      api.rerender('ee1d185a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/editTags.vue"
export default component.exports