<template>
  <vContainer>
    <v-form @submit="this.l.log('submitted', $event)">
      <v-layout
        row
        justify-space-around
      >
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-hl"
            label="Hauptlemma"
            outline
            v-model="filters.HL"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-nl"
            label="Nebenlemma"
            outline
            v-model="filters.NL"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-id"
            label="ID"
            outline
            v-model="filters.ID"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-nr"
            label="NR"
            outline
            v-model="filters.NR"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-bd"
            label="BD"
            outline
            v-model="filters.BD"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
        <v-flex md1>
          <v-text-field
            data-test="doc-filters-kt"
            label="KT"
            outline
            v-model="filters.KT"
            clearable
            @change="setFilters()"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </v-form>
  </vContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { ApiDocumentsFilter } from "../static/apiModels";
import ApiSelect from "./ApiSelect.vue";
import { DocumentsModule } from "../store/modules/documents";
import Logger from "../services/LoggerService";
import { SearchModule } from "../store/modules/search";

@Component({
  // if you use components add them here
  components: { ApiSelect },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "DocFilters",
})
export default class CollectionFilters extends Vue {
  l = new Logger("DocFilters.vue");

  filters: ApiDocumentsFilter = { exist: false };

  setFilters() {
    if (this.filters.HL === "") {
      delete this.filters.HL;
    }
    if (this.filters.NL === "") {
      delete this.filters.NL;
    }
    if (this.filters.ID === "") {
      delete this.filters.ID;
    }
    if (this.filters.NR === "") {
      delete this.filters.NR;
    }
    if (this.filters.BD === "") {
      delete this.filters.KT;
    }
    if (this.filters.KT === "") {
      delete this.filters.BD;
    }

    if (Object.keys(this.filters).length > 1) {
      this.filters.exist = true;
    }
    DocumentsModule.setFilters(this.filters);
    const filters = DocumentsModule.getFilters;

    if (
      filters.ID &&
      filters.NR === undefined &&
      filters.HL === undefined &&
      filters.NL === undefined &&
      filters.BD === undefined &&
      filters.KT === undefined
    ) {
      SearchModule.fetchSearchedDocumentsId();
    } else {
      SearchModule.fetchSearchedDocuments();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
