export default [
  {
    divider: true,
    text: "Tags",
    value: "tag",
    sortable: true,
    pathInObject: null,
    show: true,
    order: 0,
  },
  {
    divider: true,
    text: "ID",
    value: "es_id",
    sortable: true,
    pathInObject: null,
    show: true,
    order: 1,
  },
  {
    divider: true,
    text: "HL",
    value: "HL",
    sortable: true,
    show: true,
    pathInObject: "HL",
    order: 2,
  },
  {
    divider: true,
    text: "NL",
    value: "NL",
    sortable: true,
    show: true,
    pathInObject: "NL",
    order: 3,
  },

  {
    divider: true,
    text: "POS",
    value: "POS",
    sortable: true,
    show: true,
    pathInObject: "POS",
    order: 4,
  },
  {
    divider: true,
    text: "Ort/LT*",
    value: "Ort/LT*",
    sortable: true,
    show: true,
    pathInObject: "Ort/LT*",
    order: 5,
  },
  {
    divider: true,
    text: "ZW1",
    value: "ZW1",
    sortable: true,
    show: true,
    pathInObject: "ZW1",
    order: 6,
  },
  {
    divider: true,
    text: "ZW2",
    value: "ZW2",
    sortable: true,
    show: true,
    pathInObject: "ZW2",
    order: 7,
  },
  {
    divider: true,
    text: "ZW3",
    value: "ZW3",
    sortable: true,
    show: true,
    pathInObject: "ZW3",
    order: 8,
  },
  {
    divider: true,
    text: "BD/LT",
    value: "BD/LT*",
    sortable: true,
    show: true,
    pathInObject: "BD/LT*",
    order: 4.2,
  },
  {
    divider: true,
    text: "BD/KT",
    value: "BD/KT*",
    sortable: true,
    show: true,
    pathInObject: "BD/KT*",
    order: 4.3,
  },
  {
    divider: true,
    text: "ZW4",
    value: "ZW4",
    sortable: true,
    show: true,
    pathInObject: "ZW4",
    order: 9,
  },
  {
    divider: true,
    text: "ZW5",
    value: "ZW5",
    sortable: true,
    show: true,
    pathInObject: "ZW5",
    order: 10,
  },
  {
    divider: true,
    text: "J",
    value: "J",
    sortable: true,
    show: true,
    pathInObject: "J",
    order: 11,
  },
  {
    divider: true,
    text: "QU",
    value: "QU",
    sortable: true,
    show: true,
    pathInObject: "QU",
    order: 12,
  },
  {
    divider: true,
    text: "Page",
    value: "Page",
    sortable: true,
    show: true,
    pathInObject: "Page",
    order: 13,
  },
  {
    divider: true,
    text: "QDB",
    value: "QDB",
    sortable: true,
    show: true,
    pathInObject: "QDB",
    order: 14,
  },
  {
    divider: true,
    text: "BIBL",
    value: "BIBL",
    sortable: true,
    show: true,
    pathInObject: "BIBL",
    order: 15,
  },
  {
    divider: true,
    text: "Ort",
    value: "Ort",
    sortable: true,
    show: true,
    pathInObject: "Ort",
    order: 16,
  },
  {
    divider: true,
    text: "Sigle1",
    value: "Sigle1",
    sortable: true,
    show: true,
    pathInObject: "Sigle1",
    order: 17,
  },
  {
    divider: true,
    text: "Gemeinde1",
    value: "Gemeinde1",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde1",
    order: 18,
  },
  {
    divider: true,
    text: "Kleinregion1",
    value: "Kleinregion1",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion1",
    order: 19,
  },
  {
    divider: true,
    text: "Großregion1",
    value: "Großregion1",
    sortable: true,
    show: true,
    pathInObject: "Großregion1",
    order: 20,
  },
  {
    divider: true,
    text: "Bundesland1",
    value: "Bundesland1",
    sortable: true,
    show: true,
    pathInObject: "Bundesland1",
    order: 21,
  },
  {
    divider: true,
    text: "Sigle2",
    value: "Sigle2",
    sortable: true,
    show: true,
    pathInObject: "Sigle2",
    order: 22,
  },
  {
    divider: true,
    text: "Gemeinde2",
    value: "Gemeinde2",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde2",
    order: 23,
  },
  {
    divider: true,
    text: "Kleinregion2",
    value: "Kleinregion2",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion2",
    order: 24,
  },
  {
    divider: true,
    text: "Großregion2",
    value: "Großregion2",
    sortable: true,
    show: true,
    pathInObject: "Großregion2",
    order: 25,
  },
  {
    divider: true,
    text: "Bundesland2",
    value: "Bundesland2",
    sortable: true,
    show: true,
    pathInObject: "Bundesland2",
    order: 26,
  },
  {
    divider: true,
    text: "Sigle3",
    value: "Sigle3",
    sortable: true,
    show: true,
    pathInObject: "Sigle3",
    order: 27,
  },
  {
    divider: true,
    text: "Gemeinde3",
    value: "Gemeinde3",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde3",
    order: 28,
  },
  {
    divider: true,
    text: "Kleinregion3",
    value: "Kleinregion3",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion3",
    order: 29,
  },
  {
    divider: true,
    text: "Großregion3",
    value: "Großregion3",
    sortable: true,
    show: true,
    pathInObject: "Großregion3",
    order: 30,
  },
  {
    divider: true,
    text: "Bundesland3",
    value: "Bundesland3",
    sortable: true,
    show: true,
    pathInObject: "Bundesland3",
    order: 31,
  },
  {
    divider: true,
    text: "Sigle4",
    value: "Sigle4",
    sortable: true,
    show: true,
    pathInObject: "Sigle4",
    order: 32,
  },
  {
    divider: true,
    text: "Gemeinde4",
    value: "Gemeinde4",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde4",
    order: 33,
  },
  {
    divider: true,
    text: "Kleinregion4",
    value: "Kleinregion4",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion4",
    order: 34,
  },
  {
    divider: true,
    text: "Großregion4",
    value: "Großregion4",
    sortable: true,
    show: true,
    pathInObject: "Großregion4",
    order: 35,
  },
  {
    divider: true,
    text: "Bundesland4",
    value: "Bundesland4",
    sortable: true,
    show: true,
    pathInObject: "Bundesland4",
    order: 36,
  },
  {
    divider: true,
    text: "Sigle5",
    value: "Sigle5",
    sortable: true,
    show: true,
    pathInObject: "Sigle5",
    order: 37,
  },
  {
    divider: true,
    text: "Gemeinde5",
    value: "Gemeinde5",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde5",
    order: 38,
  },
  {
    divider: true,
    text: "Kleinregion5",
    value: "Kleinregion5",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion5",
    order: 39,
  },
  {
    divider: true,
    text: "Großregion5",
    value: "Großregion5",
    sortable: true,
    show: true,
    pathInObject: "Großregion5",
    order: 40,
  },
  {
    divider: true,
    text: "Bundesland5",
    value: "Bundesland5",
    sortable: true,
    show: true,
    pathInObject: "Bundesland5",
    order: 41,
  },
  {
    divider: true,
    text: "Sigle6",
    value: "Sigle6",
    sortable: true,
    show: true,
    pathInObject: "Sigle6",
    order: 42,
  },
  {
    divider: true,
    text: "Gemeinde6",
    value: "Gemeinde6",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde6",
    order: 43,
  },
  {
    divider: true,
    text: "Kleinregion6",
    value: "Kleinregion6",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion6",
    order: 44,
  },
  {
    divider: true,
    text: "Großregion6",
    value: "Großregion6",
    sortable: true,
    show: true,
    pathInObject: "Großregion6",
    order: 45,
  },
  {
    divider: true,
    text: "Bundesland6",
    value: "Bundesland6",
    sortable: true,
    show: true,
    pathInObject: "Bundesland6",
    order: 46,
  },
  {
    divider: true,
    text: "Sigle7",
    value: "Sigle7",
    sortable: true,
    show: true,
    pathInObject: "Sigle7",
    order: 47,
  },
  {
    divider: true,
    text: "Gemeinde7",
    value: "Gemeinde7",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde7",
    order: 48,
  },
  {
    divider: true,
    text: "Kleinregion7",
    value: "Kleinregion7",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion7",
    order: 49,
  },
  {
    divider: true,
    text: "Großregion7",
    value: "Großregion7",
    sortable: true,
    show: true,
    pathInObject: "Großregion7",
    order: 50,
  },
  {
    divider: true,
    text: "Bundesland7",
    value: "Bundesland7",
    sortable: true,
    show: true,
    pathInObject: "Bundesland7",
    order: 51,
  },
  {
    divider: true,
    text: "Sigle8",
    value: "Sigle8",
    sortable: true,
    show: true,
    pathInObject: "Sigle8",
    order: 52,
  },
  {
    divider: true,
    text: "Gemeinde8",
    value: "Gemeinde8",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde8",
    order: 53,
  },
  {
    divider: true,
    text: "Kleinregion8",
    value: "Kleinregion8",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion8",
    order: 54,
  },
  {
    divider: true,
    text: "Großregion8",
    value: "Großregion8",
    sortable: true,
    show: true,
    pathInObject: "Großregion8",
    order: 55,
  },
  {
    divider: true,
    text: "Bundesland8",
    value: "Bundesland8",
    sortable: true,
    show: true,
    pathInObject: "Bundesland8",
    order: 56,
  },
  {
    divider: true,
    text: "Sigle9",
    value: "Sigle9",
    sortable: true,
    show: true,
    pathInObject: "Sigle9",
    order: 57,
  },
  {
    divider: true,
    text: "Gemeinde9",
    value: "Gemeinde9",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde9",
    order: 58,
  },
  {
    divider: true,
    text: "Kleinregion9",
    value: "Kleinregion9",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion9",
    order: 59,
  },
  {
    divider: true,
    text: "Großregion9",
    value: "Großregion9",
    sortable: true,
    show: true,
    pathInObject: "Großregion9",
    order: 60,
  },
  {
    divider: true,
    text: "Bundesland9",
    value: "Bundesland9",
    sortable: true,
    show: true,
    pathInObject: "Bundesland9",
    order: 61,
  },
  {
    divider: true,
    text: "Sigle10",
    value: "Sigle10",
    sortable: true,
    show: true,
    pathInObject: "Sigle10",
    order: 61.5,
  },
  {
    divider: true,
    text: "Gemeinde10",
    value: "Gemeinde10",
    sortable: true,
    show: true,
    pathInObject: "Gemeinde10",
    order: 61.8,
  },
  {
    divider: true,
    text: "Kleinregion10",
    value: "Kleinregion10",
    sortable: true,
    show: true,
    pathInObject: "Kleinregion10",
    order: 62,
  },
  {
    divider: true,
    text: "Großregion10",
    value: "Großregion10",
    sortable: true,
    show: true,
    pathInObject: "Großregion10",
    order: 63,
  },
  {
    divider: true,
    text: "Bundesland10",
    value: "Bundesland10",
    sortable: true,
    show: true,
    pathInObject: "Bundesland10",
    order: 64,
  },
  {
    divider: true,
    text: "Ort auf Zettel",
    value: "entry.usg.placeName.$",
    sortable: true,
    show: true,
    pathInObject: "entry.usg.placeName.$", // todo check if right
    order: 65,
  },
  {
    divider: true,
    text: "Version",
    value: "_version",
    sortable: true,
    show: true,
    pathInObject: "version", // todo check if right
    order: 66,
  },
  {
    divider: true,
    text: "LT1_teuthonista",
    value: "LT1_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT1_teuthonista",
    order: 4.51,
  },
  {
    divider: true,
    text: "GRAM/LT1",
    value: "GRAM/LT1",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT1",
    order: 4.511,
  },
  {
    divider: true,
    text: "LT2_teuthonista",
    value: "LT2_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT2_teuthonista",
    order: 4.52,
  },
  {
    divider: true,
    text: "GRAM/LT2",
    value: "GRAM/LT2",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT2",
    order: 4.522,
  },
  {
    divider: true,
    text: "LT3_teuthonista",
    value: "LT3_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT3_teuthonista",
    order: 4.53,
  },
  {
    divider: true,
    text: "GRAM/LT3",
    value: "GRAM/LT3",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT3",
    order: 4.533,
  },
  {
    divider: true,
    text: "LT4_teuthonista",
    value: "LT4_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT4_teuthonista",
    order: 4.54,
  },
  {
    divider: true,
    text: "GRAM/LT4",
    value: "GRAM/LT4",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT4",
    order: 4.544,
  },
  {
    divider: true,
    text: "LT5_teuthonista",
    value: "LT5_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT5_teuthonista",
    order: 4.55,
  },
  {
    divider: true,
    text: "GRAM/LT5",
    value: "GRAM/LT5",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT5",
    order: 4.555,
  },
  {
    divider: true,
    text: "LT6_teuthonista",
    value: "LT6_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT6_teuthonista",
    order: 4.56,
  },
  {
    divider: true,
    text: "GRAM/LT6",
    value: "GRAM/LT6",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT6",
    order: 4.566,
  },
  {
    divider: true,
    text: "LT7_teuthonista",
    value: "LT7_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT7_teuthonista",
    order: 4.57,
  },
  {
    divider: true,
    text: "GRAM/LT7",
    value: "GRAM/LT7",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT7",
    order: 4.577,
  },
  {
    divider: true,
    text: "LT8_teuthonista",
    value: "LT8_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT8_teuthonista",
    order: 4.58,
  },
  {
    divider: true,
    text: "GRAM/LT8",
    value: "GRAM/LT8",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT8",
    order: 4.588,
  },
  {
    divider: true,
    text: "LT9_teuthonista",
    value: "LT9_teuthonista",
    sortable: true,
    show: true,
    pathInObject: "LT9_teuthonista",
    order: 4.59,
  },
  {
    divider: true,
    text: "GRAM/LT9",
    value: "GRAM/LT9",
    sortable: true,
    show: true,
    pathInObject: "GRAM/LT9",
    order: 4.599,
  },
  {
    divider: true,
    text: "ANM/LT*",
    value: "ANM/LT*",
    sortable: true,
    show: true,
    pathInObject: "ANM/LT*",
    order: 4.599,
  },
  {
    divider: true,
    text: "KT1",
    value: "KT1",
    sortable: true,
    show: true,
    pathInObject: "KT1",
    order: 4.61,
  },
  {
    divider: true,
    text: "KL/KT1",
    value: "KL/KT1",
    sortable: true,
    show: true,
    pathInObject: "KL/KT1",
    order: 4.611,
  },
  {
    divider: true,
    text: "ZL1/KT1",
    value: "ZL1/KT1",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT1",
    order: 4.6111,
  },
  {
    divider: true,
    text: "ZL2/KT1",
    value: "ZL2/KT1",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT1",
    order: 4.61111,
  },
  {
    divider: true,
    text: "KT2",
    value: "KT2",
    sortable: true,
    show: true,
    pathInObject: "KT2",
    order: 4.62,
  },
  {
    divider: true,
    text: "KL/KT2",
    value: "KL/KT2",
    sortable: true,
    show: true,
    pathInObject: "KL/KT2",
    order: 4.622,
  },
  {
    divider: true,
    text: "ZL1/KT2",
    value: "ZL1/KT2",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT2",
    order: 4.6222,
  },
  {
    divider: true,
    text: "ZL2/KT2",
    value: "ZL2/KT2",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT2",
    order: 4.62222,
  },
  {
    divider: true,
    text: "KT3",
    value: "KT3",
    sortable: true,
    show: true,
    pathInObject: "KT3",
    order: 4.63,
  },
  {
    divider: true,
    text: "KL/KT3",
    value: "KL/KT3",
    sortable: true,
    show: true,
    pathInObject: "KL/KT3",
    order: 4.633,
  },
  {
    divider: true,
    text: "ZL1/KT3",
    value: "ZL1/KT3",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT3",
    order: 4.6333,
  },
  {
    divider: true,
    text: "ZL2/KT3",
    value: "ZL2/KT3",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT3",
    order: 4.63333,
  },
  {
    divider: true,
    text: "KT4",
    value: "KT4",
    sortable: true,
    show: true,
    pathInObject: "KT4",
    order: 4.64,
  },
  {
    divider: true,
    text: "KL/KT4",
    value: "KL/KT4",
    sortable: true,
    show: true,
    pathInObject: "KL/KT4",
    order: 4.644,
  },
  {
    divider: true,
    text: "ZL1/KT4",
    value: "ZL1/KT4",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT4",
    order: 4.6444,
  },
  {
    divider: true,
    text: "ZL2/KT4",
    value: "ZL2/KT4",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT4",
    order: 4.64444,
  },
  {
    divider: true,
    text: "KT5",
    value: "KT5",
    sortable: true,
    show: true,
    pathInObject: "KT5",
    order: 4.65,
  },
  {
    divider: true,
    text: "KL/KT5",
    value: "KL/KT5",
    sortable: true,
    show: true,
    pathInObject: "KL/KT5",
    order: 4.655,
  },
  {
    divider: true,
    text: "ZL1/KT5",
    value: "ZL1/KT5",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT5",
    order: 4.6555,
  },
  {
    divider: true,
    text: "ZL2/KT5",
    value: "ZL2/KT5",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT5",
    order: 4.65555,
  },
  {
    divider: true,
    text: "KT6",
    value: "KT6",
    sortable: true,
    show: true,
    pathInObject: "KT6",
    order: 4.66,
  },
  {
    divider: true,
    text: "KL/KT6",
    value: "KL/KT6",
    sortable: true,
    show: true,
    pathInObject: "KL/KT6",
    order: 4.666,
  },
  {
    divider: true,
    text: "ZL1/KT6",
    value: "ZL1/KT6",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT6",
    order: 4.6666,
  },
  {
    divider: true,
    text: "ZL2/KT6",
    value: "ZL2/KT6",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT6",
    order: 4.66666,
  },
  {
    divider: true,
    text: "KT7",
    value: "KT7",
    sortable: true,
    show: true,
    pathInObject: "KT7",
    order: 4.67,
  },
  {
    divider: true,
    text: "KL/KT7",
    value: "KL/KT7",
    sortable: true,
    show: true,
    pathInObject: "KL/KT7",
    order: 4.677,
  },
  {
    divider: true,
    text: "ZL1/KT7",
    value: "ZL1/KT7",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT7",
    order: 4.6777,
  },
  {
    divider: true,
    text: "ZL2/KT7",
    value: "ZL2/KT7",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT7",
    order: 4.67777,
  },
  {
    divider: true,
    text: "KT8",
    value: "KT8",
    sortable: true,
    show: true,
    pathInObject: "KT8",
    order: 4.68,
  },
  {
    divider: true,
    text: "KL/KT8",
    value: "KL/KT8",
    sortable: true,
    show: true,
    pathInObject: "KL/KT8",
    order: 4.688,
  },
  {
    divider: true,
    text: "ZL1/KT8",
    value: "ZL1/KT8",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT8",
    order: 4.6888,
  },
  {
    divider: true,
    text: "ZL2/KT8",
    value: "ZL2/KT8",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT8",
    order: 4.68888,
  },
  {
    divider: true,
    text: "ANM/KT*",
    value: "ANM/KT*",
    sortable: true,
    show: true,
    pathInObject: "ANM/KT*",
    order: 5.52,
  },
  {
    divider: true,
    text: "DV/KT*",
    value: "DV/KT*",
    sortable: true,
    show: true,
    pathInObject: "DV/KT*",
    order: 5.53,
  },
  {
    divider: true,
    text: "KT/LT1",
    value: "KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "KT/LT1",
    order: 5.61,
  },
  {
    divider: true,
    text: "KT/LT2",
    value: "KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "KT/LT2",
    order: 5.62,
  },
  {
    divider: true,
    text: "Verweis",
    value: "Verweis",
    sortable: true,
    show: true,
    pathInObject: "Verweis",
    order: 5.7,
  },
  {
    divider: true,
    text: "ANM",
    value: "ANM",
    sortable: true,
    show: true,
    pathInObject: "ANM",
    order: 5.8,
  },
  {
    divider: true,
    text: "DIV",
    value: "DIV",
    sortable: true,
    show: true,
    pathInObject: "DIV",
    order: 5.9,
  },
  {
    divider: true,
    text: "ETYM",
    value: "ETYM",
    sortable: true,
    show: true,
    pathInObject: "ETYM",
    order: 10.1,
  },
  {
    divider: true,
    text: "NR",
    value: "NR",
    sortable: true,
    show: true,
    pathInObject: "NR",
    order: 1.5,
  },
  {
    divider: true,
    text: "WBD",
    value: "WBD",
    sortable: true,
    show: true,
    pathInObject: "WBD",
    order: 4.7,
  },
  {
    divider: true,
    text: "WBD/KT*",
    value: "WBD/KT*",
    sortable: true,
    show: true,
    pathInObject: "WBD/KT*",
    order: 4.8,
  },
  {
    divider: true,
    text: "LW1",
    value: "LW1",
    sortable: true,
    show: true,
    pathInObject: "LW1",
    order: 5.11,
  },
  {
    divider: true,
    text: "LW2",
    value: "LW2",
    sortable: true,
    show: true,
    pathInObject: "LW2",
    order: 5.12,
  },
  {
    divider: true,
    text: "LW3",
    value: "LW3",
    sortable: true,
    show: true,
    pathInObject: "LW3",
    order: 5.13,
  },
  {
    divider: true,
    text: "LW4",
    value: "LW4",
    sortable: true,
    show: true,
    pathInObject: "LW4",
    order: 5.14,
  },
  {
    divider: true,
    text: "LW5",
    value: "LW5",
    sortable: true,
    show: true,
    pathInObject: "LW5",
    order: 5.15,
  },
  {
    divider: true,
    text: "LW6",
    value: "LW6",
    sortable: true,
    show: true,
    pathInObject: "LW6",
    order: 5.16,
  },
  {
    divider: true,
    text: "LW7",
    value: "LW7",
    sortable: true,
    show: true,
    pathInObject: "LW7",
    order: 5.17,
  },
  {
    divider: true,
    text: "LW8",
    value: "LW8",
    sortable: true,
    show: true,
    pathInObject: "LW8",
    order: 5.18,
  },
  {
    divider: true,
    text: "BD/LW*",
    value: "BD/LW*",
    sortable: true,
    show: true,
    pathInObject: "BD/LW*",
    order: 5.2,
  },
  {
    divider: true,
    text: "ANM/LW*",
    value: "ANM/LW*",
    sortable: true,
    show: true,
    pathInObject: "ANM/LW*",
    order: 5.3,
  },
  {
    divider: true,
    text: "DV/LW*",
    value: "DV/LW*",
    sortable: true,
    show: true,
    pathInObject: "DV/LW*",
    order: 5.4,
  },
  {
    divider: true,
    text: "Ort/LW*",
    value: "Ort/LW*",
    sortable: true,
    show: true,
    pathInObject: "Ort/LW*",
    order: 5.5,
  },
  {
    divider: true,
    text: "Zusatzlemma",
    value: "Zusatzlemma",
    sortable: true,
    show: true,
    pathInObject: "zusatzlemma",
    order: 5.50001,
  },
  {
    divider: true,
    text: "KL/KT/LT1",
    value: "KL/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "KL/KT/LT1",
    order: 5.611,
  },
  {
    divider: true,
    text: "BD/KT/LT1",
    value: "BD/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "BD/KT/LT1",
    order: 5.612,
  },
  {
    divider: true,
    text: "WBD/KT/LT1",
    value: "WBD/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "WBD/KT/LT1",
    order: 5.613,
  },
  {
    divider: true,
    text: "ZL1/KT/LT1",
    value: "ZL1/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT/LT1",
    order: 5.614,
  },
  {
    divider: true,
    text: "ZL2/KT/LT1",
    value: "ZL2/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT/LT1",
    order: 5.615,
  },
  {
    divider: true,
    text: "Note/KT/LT1",
    value: "Note/KT/LT1",
    sortable: true,
    show: true,
    pathInObject: "Note/KT/LT1",
    order: 5.616,
  },
  {
    divider: true,
    text: "KL/KT/LT2",
    value: "KL/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "KL/KT/LT2",
    order: 5.621,
  },
  {
    divider: true,
    text: "BD/KT/LT2",
    value: "BD/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "BD/KT/LT2",
    order: 5.622,
  },
  {
    divider: true,
    text: "WBD/KT/LT2",
    value: "WBD/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "WBD/KT/LT2",
    order: 5.623,
  },
  {
    divider: true,
    text: "ZL1/KT/LT2",
    value: "ZL1/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT/LT2",
    order: 5.624,
  },
  {
    divider: true,
    text: "ZL2/KT/LT2",
    value: "ZL2/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT/LT2",
    order: 5.625,
  },
  {
    divider: true,
    text: "Note/KT/LT2",
    value: "Note/KT/LT2",
    sortable: true,
    show: true,
    pathInObject: "Note/KT/LT2",
    order: 5.626,
  },
  {
    text: "KT/LW1",
    value: "KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "KT/LW1",
    order: 5.63,
  },
  {
    divider: true,
    text: "KL/KT/LW1",
    value: "KL/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "KL/KT/LW1",
    order: 5.631,
  },
  {
    divider: true,
    text: "BD/KT/LW1",
    value: "BD/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "BD/KT/LW1",
    order: 5.632,
  },
  {
    divider: true,
    text: "WBD/KT/LW1",
    value: "WBD/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "WBD/KT/LW1",
    order: 5.633,
  },
  {
    divider: true,
    text: "ZL1/KT/LW1",
    value: "ZL1/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "ZL1/KT/LW1",
    order: 5.634,
  },
  {
    text: "ZL2/KT/LW1",
    value: "ZL2/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "ZL2/KT/LW1",
    order: 5.635,
  },
  {
    divider: true,
    text: "Note/KT/LW1",
    value: "Note/KT/LW1",
    sortable: true,
    show: true,
    pathInObject: "Note/KT/LW1",
    order: 5.636,
  },
  {
    divider: true,
    text: "VRW/KT*",
    value: "VRW/KT*",
    sortable: true,
    show: true,
    pathInObject: "VRW/KT*",
    order: 5.51,
  },
  {
    divider: true,
    text: "A",
    value: "A",
    sortable: true,
    show: true,
    pathInObject: "Archivzeile",
    order: 16.5,
  },
];
