import allHeaders from "./documentHeader_helper";

const presets = [
  { value: "Empty", rows: [] },
  {
    value: "Show Every Column",
    rows: allHeaders.map((h) => h.value),
  },
  {
    value: "Vorarbeiten",
    rows: [
      "tag",
      "es_id",
      "HL",
      "NL",
      "POS",
      "Ort/LT*",
      "J",
      "QU",
      "QDB",
      "BIBL",
      "ORT",
      "Sigle1",
      "Gemeinde1",
      "Kleinregion1",
      "Großregion1",
      "Bundesland1",
      "Sigle2",
      "Gemeinde2",
      "Kleinregion2",
      "Großregion2",
      "Bundesland2",
      "Sigle3",
      "Gemeinde3",
      "Kleinregion3",
      "Großregion3",
      "Bundesland3",
      "Sigle4",
      "Gemeinde4",
      "Kleinregion4",
      "Großregion4",
      "Bundesland4",
      "Sigle5",
      "Gemeinde5",
      "Kleinregion5",
      "Großregion5",
      "Bundesland5",
      "Sigle6",
      "Gemeinde6",
      "Kleinregion6",
      "Großregion6",
      "Bundesland6",
      "Sigle7",
      "Gemeinde7",
      "Kleinregion7",
      "Großregion7",
      "Bundesland7",
      "Sigle8",
      "Gemeinde8",
      "Kleinregion8",
      "Großregion8",
      "Bundesland8",
      "Sigle9",
      "Gemeinde9",
      "Kleinregion9",
      "Großregion9",
      "Bundesland9",
      "Sigle10",
      "Gemeinde10",
      "Kleinregion10",
      "Großregion10",
      "Bundesland10",
    ],
  },
  {
    value: "Lautung",
    rows: [
      "tag",
      "es_id",
      "HL",
      "NL",
      "POS",
      "LT1_teuthonista",
      "GRAM/LT1",
      "LT2_teuthonista",
      "GRAM/LT2",
      "LT3_teuthonista",
      "GRAM/LT3",
      "LT4_teuthonista",
      "GRAM/LT4",
      "LT5_teuthonista",
      "GRAM/LT5",
      "LT6_teuthonista",
      "GRAM/LT6",
      "LT7_teuthonista",
      "GRAM/LT7",
      "LT8_teuthonista",
      "GRAM/LT8",
      "LT9_teuthonista",
      "GRAM/LT9",
      "ANM/LT*",
      "KT1",
      "KL/KT1",
      "ZL1/KT1",
      "ZL2/KT1",
      "KT2",
      "KL/KT2",
      "ZL1/KT2",
      "ZL2/KT2",
      "KT3",
      "KL/KT3",
      "ZL1/KT3",
      "ZL2/KT3",
      "KT4",
      "KL/KT4",
      "ZL1/KT4",
      "ZL2/KT4",
      "KT5",
      "KL/KT5",
      "ZL1/KT5",
      "ZL2/KT5",
      "KT6",
      "KL/KT6",
      "ZL1/KT6",
      "ZL2/KT6",
      "KT7",
      "KL/KT7",
      "ZL1/KT7",
      "ZL2/KT7",
      "KT8",
      "KL/KT8",
      "ZL1/KT8",
      "ZL2/KT8",
      "Ort/LT*",
      "ANM/KT*",
      "DV/KT*",
      "KT/LT1",
      "KT/LT2",
      "Verweis",
      "ANM",
      "DIV",
      "ETYM",
      "QU",
      "BIBL",
      "Ort",
      "Sigle1",
      "Gemeinde1",
      "Kleinregion1",
      "Großregion1",
      "Bundesland1",
      "Sigle2",
    ],
  },
  {
    value: "Bedeutung",
    rows: [
      "tag",
      "es_id",
      "BD/LT*",
      "BD/KT*",
      "NR",
      "HL",
      "NL",
      "POS",
      "LT1_teuthonista",
      "GRAM/LT1",
      "LT2_teuthonista",
      "GRAM/LT2",
      "KT1",
      "KL/KT1",
      "ZL1/KT1",
      "ZL2/KT1",
      "KT2",
      "KL/KT2",
      "ZL1/KT2",
      "ZL2/KT2",
      "KT3",
      "KL/KT3",
      "ZL1/KT3",
      "ZL2/KT3",
      "KT4",
      "KL/KT4",
      "ZL1/KT4",
      "ZL2/KT4",
      "KT5",
      "KL/KT5",
      "ZL1/KT5",
      "ZL2/KT5",
      "KT6",
      "KL/KT6",
      "ZL1/KT6",
      "ZL2/KT6",
      "KT7",
      "KL/KT7",
      "ZL1/KT7",
      "ZL2/KT7",
      "KT8",
      "LW1",
      "LW2",
      "BD/LW*",
      "ANM/LW*",
      "DV/LW*",
      "Ort/LW*",
      "VRW/KT*",
      "ANM/KT*",
      "DV/KT*",
      "KT/LT1",
      "KT/LT2",
      "Verweis",
      "ANM",
      "DIV",
      "ETYM",
      "Sigle1",
      "Gemeinde1",
      "Kleinregion1",
      "Großregion1",
      "Bundesland1",
      "Sigle2",
    ],
  },
];

export default presets;
