<template>
  <v-dialog
    v-model="dialog"
    width="700"
    v-if="slotToEdit"
  >
    <v-card>
      <v-card-title>
        <h2>
          {{ $t("TagSingleSlotSelection.select_a_tag") + slotToEdit.key }}
        </h2>
      </v-card-title>
      <v-layout>
        <v-card-text>
          <v-text-field
            data-test="tag-single-slot-select-search"
            v-model="search"
            clearable
            :placeholder="$t('TagSingleSlotSelection.search')"
            @keyup="onSearchCommitted()"
          ></v-text-field>

          <v-data-table
            :headers="headers"
            :items="localTags"
            must-sort
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
            :options.sync="pagination"
            @click:row="handleRowClick($event, item)"
          >
            <!--
                        v-slot:item -> acces the slot prop "item" from the v-data-table component
                        item : tag -> rename property "item" to tag for usagae within the code. This is just for beauty reasons
                        -->
            <template v-slot:item="{ item: tag }">
              <tr
                :data-test="`tag-single-slot-select-row-${tag.name}`"
                @click="handleRowClick($event, tag)"
              >
                <td>{{ tag.name }}</td>
                <td>{{ tag.emoji }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import TagChip from "./TagChip.vue";
import TagList from "./TagList.vue";
import { ApiTag } from "../static/apiModels";
import { TagsModule } from "../store/modules/tags";

@Component({
  components: { TagChip, TagList },
  name: "TagSingleSlotSelection",
})
export default class TagSingleSlotSelection extends Vue {
  l = new Logger("TagSelect.vue");

  TM = TagsModule;

  localTags: ApiTag[] = this.TM.getTags;

  search: string = "";

  rowsPerPageItems = [5, 10, 25, 50];

  pagination = {
    rowsPerPage: 25,
  };

  headers = [
    { text: "Name", value: "name" },
    { text: "emoji", value: "emoji" },
  ];

  // --------------------------------------------------------------------------------------------------------------------------------------------------
  //   Utility:
  // --------------------------------------------------------------------------------------------------------------------------------------------------
  get tags() {
    return this.TM.getTags;
  }

  get dialog() {
    return this.TM.singleTagSlotSelectionDialog;
  }

  set dialog(val: boolean) {
    this.TM.setSingleTagSlotSelectionDialog(val);
  }

  get slotToEdit() {
    return this.TM.slotToEdit;
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------------
  //   Methods:
  // --------------------------------------------------------------------------------------------------------------------------------------------------

  @Watch("search")
  onSearchChange() {
    this.localTags = this.tags.filter((tag) => {
      return tag.name.includes(this.search);
    });
  }

  @Watch("tags")
  onTagsChange() {
    this.localTags = Object.assign([], this.tags);
  }

  handleRowClick(event: any, item: any) {
    if (this.slotToEdit && item) {
      this.TM.addTagToSlotToEdit(item);
    }
  }
}
</script>
