<template>
  <vContainer fluid>
    <h1>Scans</h1>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          >{{ $t("ScanView.scanladen-auswahl.select") }} ({{
            selectedDrawer || $t("ScanView.scanladen-auswahl.none")
          }})</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-autocomplete
            @change="changeDrawer"
            v-model="selectedDrawer"
            outlined
            :loading="loading"
            :disabled="!isEnabled"
            :items="drawers"
            :label="$t('ScanView.select-drawer')"
          ></v-autocomplete>
          <v-row>
            <v-btn
              text
              :key="step.step"
              :color="step.color"
              @click="
                begin = step.step;
                limit = step.step + pageSize;
              "
              v-for="step in steps"
              >{{ step.step }}-{{ step.step + pageSize }}</v-btn
            >
          </v-row>
          <v-row v-if="false">
            <v-text-field
              v-model="begin"
              outlined
              :label="$t('basics.from')"
              type="number"
            ></v-text-field>
            <v-text-field
              v-model="limit"
              outlined
              :label="$t('ScanView.to')"
              type="number"
            ></v-text-field>
          </v-row>

          <!-- Search Bar -->
          <v-row>
            <v-col class="pb-0">
              <h3>{{ $t("ScanView.search") }}</h3>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                hide-details
                single-line
                filled
                v-model="searchTerm"
                @keydown.enter.prevent="searchScans()"
                :disabled="!isEnabled"
                clearable
                :label="$t('ScanView.search-in-drawer-label')"
              ></v-text-field>
            </v-col>
            <v-col md="auto">
              <v-select
                v-model="selectedSearchSortMethod"
                :items="searchSortOptions"
                :hint="$t(selectedSearchSortMethod.hint)"
                item-value="sort"
                :label="$t('ScanView.sort-method-label')"
                return-object
                dense
                align
                class="pt-3"
                menu-props="auto"
              >
                <template v-slot:selection="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ $t(data.item.display) }}
                </template>

                <template v-slot:item="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ $t(data.item.display) }}
                </template>
              </v-select>
            </v-col>
            <v-col md="auto">
              <v-btn
                icon
                @click="searchScans()"
              >
                <v-icon>mdi-magnify</v-icon>
                <span class="d-sr-only">{{ $t("ScanView.commit-search") }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          >Scans ({{ $t("ScanView.doc-count.total") }}: {{ documents.length }};
          {{ $t("ScanView.doc-count.showing") }} {{ begin }} - {{ limit }})
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="3"> </v-col>
            <v-col
              class="pl-16"
              cols="6"
            >
              <v-btn-toggle
                v-model="displayFilter"
                mandatory
              >
                <v-btn> {{ $t("ScanView.view-mode.show-all") }} </v-btn>
                <v-btn>
                  {{ $t("ScanView.view-mode.documents-with-scans") }}
                </v-btn>
                <v-btn>
                  {{ $t("ScanView.view-mode.documents-without-scans") }}
                </v-btn>
                <!-- <v-btn>
            Documents without Scans + Last first with Scan
          </v-btn> -->
              </v-btn-toggle>
            </v-col>
            <v-col cols="1">
              <v-btn
                text
                v-if="picOffset !== 0 && proposedPictures"
                @click="picOffset = 0"
              >
                {{ $t("ScanView.view-mode.reset-proposed-pictures") }}
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template v-for="(item, i) of displayedDocs">
                <ScanConnectRow
                  :key="i"
                  :proposedPicture="proposedPictures[i]"
                  :connectDocument="item"
                  @showPic="
                    showImg = $event;
                    picDialog = true;
                  "
                  @shiftImage="picOffset += $event"
                />
              </template>
              <v-btn
                block
                v-if="documents.length > limit"
                @click="limit += 25"
                >{{ $t("ScanView.scan-list.load-more") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="picDialog">
      <span>src: {{ showImg }}</span>
      <v-img
        :src="showImg"
        @click="picDialog = false"
      />
    </v-dialog>
  </vContainer>
</template>

<script lang="ts">
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable no-alert */

import { Component, Vue } from "vue-property-decorator";

import ScanConnectRow from "@/components/ScanConnectRow.vue";
import ImageScroller from "@/components/ImageScroller.vue";
import { getDocumentsOfDrawer, getDjangoDocumentByEsID } from "@/api/django/documents";

import {
  EsApiDocument,
  EsApiDocumentSource,
  getDocumentsForScans,
  getDocumentsFromSearch,
} from "@/api/elastic/documents";

// @ts-ignore
import { getImagesOfDrawer, fetchLabelsOfDrawersWithScans } from "@/api/images/index";
import Logger from "@/services/LoggerService";

import { getXMLofDocument, getXMLofDocuments } from "@/api/xml/documents";
import { IFFFPicture, extractPathFromIFFUrl } from "@/utils/IFFFHandler";
import { NotificationsModule } from "@/store/modules/notifications";
import SimpleNotification from "@/models/SimpleNotification";
import i18n from "@/i18n";

export type ScanDocument = EsApiDocument & {
  A?: string;
  BDKTLT1?: string;
  BDKTLT2?: string;
  HL?: string | Array<string>;
  KT1?: string | Array<string>;
  KT2?: string | Array<string>;
  LT1?: string | Array<string>;
  LT2?: string | Array<string>;
  NR?: string | Array<string>;
  QDB?: string;
  QU?: string;
  bdktlts?: string | Array<string>;
  es_id?: string;
  url?: string;
  scans?: null | string;
  xml?: string | null;
  django_id?: string;
  source?: EsApiDocumentSource;
};

// eslint-disable-next-line no-shadow
export enum displayFilterOptions {
  "showAll",
  "onlyConnected",
  "onlyNotConnected",
  "onlyNotConnectedAndLastWith",
}

interface SortOption {
  sort: string;
  display: string;
  hint: string;
}

@Component({
  name: "ScanView",
  components: { ScanConnectRow, ImageScroller },
})
export default class ScanView extends Vue {
  l = new Logger("ScanView.vue");

  panel = [0, 1];

  selectedDrawer: string | null = null;

  get selecetedDrawerSanatizedForQueries() {
    if (!this.selectedDrawer) return "";
    return encodeURI(
      this.selectedDrawer.replace("Hauptkatalog Lade ", "").replace("_", "").replace(" ", ""),
    );
  }

  get selectedDrawerForImages() {
    if (!this.selectedDrawer) return "";
    return this.selectedDrawer.replace("Hauptkatalog Lade ", "").replace(" ", "_");
  }

  standardSort: SortOption = {
    sort: "std",
    display: "ScanView.sort-options.standard-sort.display", // Translation Key
    hint: "ScanView.sort-options.standard-sort.hint",
  };

  selectedSearchSortMethod = this.standardSort;

  searchSortOptions: SortOption[] = [
    this.standardSort,
    {
      sort: "lastDigitsAfterHashtagDigits",
      display: "ScanView.sort-options.exp-last-digit-sort.display",
      hint: "ScanView.sort-options.exp-last-digit-sort.hint",
    },
  ];

  onlyShowNotConnected = true;

  displayFilter: displayFilterOptions = displayFilterOptions.showAll;

  picDialog = false;

  showImg = "";

  searchTerm = "";

  // The inital array is just a list of drawers that are known to exist.
  // The actual list is fetched from the server on mount.
  drawers = [
    "F_231",
    "F_243",
    "F_250",
    "F_262",
    "F_267",
    "G_317",
    "G_319",
    "G_356",
    "K_505",
    "K_511",
    "K_508",
  ];

  async mounted() {
    this.loading = true;
    try {
      const drawerLabels = await fetchLabelsOfDrawersWithScans();

      this.drawers = drawerLabels.filter((a) => a.includes("Hauptkatalog"));
      // There are more drawers than the "Hauptkatalog" ones, but they need further consideration.
      // E.g. some don't have entries in the xml db
      this.loading = false;
    } catch (e: unknown) {
      if (e instanceof Error) {
        // catch 503
        if (e.message === "Network Error") {
          const notification: SimpleNotification = {
            icon: "mdi-server-network-off",
            message: i18n.t("ScanView.image-server-not-reachable").toString(),
          };
          NotificationsModule.setError(notification);
        }
      }
      this.error = true;
      this.loading = false;
    }
  }

  loading = false;

  error = false;

  get isEnabled() {
    return !this.loading && !this.error;
  }

  djangoDocuments: ScanDocument[] = [];

  elasticDocuments: EsApiDocument[] = [];

  xmlDocs: any[] = [];

  pictureResponse: any = null;

  picturesOfDrawer: IFFFPicture[] = [];

  documents: ScanDocument[] = [];

  begin = 0;

  limit = 100;

  pageSize = 100;

  picOffset = 0;

  get displayedDocs() {
    return this.filteredDocs.filter((_a, i) => i >= this.begin && i <= this.limit);
  }

  get filteredDocs() {
    let filteredDocs;
    switch (this.displayFilter) {
      case displayFilterOptions.onlyConnected:
        filteredDocs = this.documents.filter((a) => this.hasConnectedScanInXML(a));
        break;

      case displayFilterOptions.onlyNotConnected:
        filteredDocs = this.documents.filter((a) => !this.hasConnectedScanInXML(a));
        break;

      default:
        filteredDocs = this.documents;

        break;
    }
    return filteredDocs;
  }

  hasConnectedScanInXML(doc: any): boolean {
    if (!doc.xml) return false;

    if (doc.xml === "") return false;

    const xmlParser = new DOMParser();

    const xmlDoc = xmlParser.parseFromString(doc.xml, "text/xml");
    if (xmlDoc.querySelector("parsererror")) return false;

    if (!xmlDoc) return false;

    const xmlMainEntry = xmlDoc.getElementsByTagName("entry")[0];

    if (!xmlMainEntry) return false;

    const facs = xmlMainEntry.getAttributeNode("facs");

    if (!facs || !facs.nodeValue) return false;

    const scanArr: any[] = facs.nodeValue.split(" ");

    return scanArr && scanArr.length > 0;
  }

  get steps() {
    return "s"
      .repeat(Math.ceil(this.filteredDocs.length / this.pageSize))
      .split("")
      .map((_s, i) => ({
        step: i * this.pageSize,
        color: this.getStepColor(i),
      }));
  }

  getStepColor(i: number): string {
    let color = "";

    if (i * this.pageSize >= this.begin && i * this.pageSize < this.limit) color = "primary";

    return color;
  }

  rotateArray(array: any[], k: number): any[] {
    let i = k;
    let retArray = array;
    if (i > 0) {
      i %= array.length;
      retArray = array.slice(i).concat(array.slice(0, i));
    }
    if (i < 0) {
      i = (array.length + k) % array.length;
      retArray = array.slice(i).concat(array.slice(0, i));
    }
    return retArray;
  }

  get proposedPictures() {
    return this.rotateArray(this.picturesOfDrawer, this.begin + this.picOffset);
  }

  async changeDrawer() {
    if (!this.selectedDrawer) return;
    this.loading = true;
    this.searchTerm = "";
    await this.fetchDrawer();

    let idsToFetch = "";
    const xmls = [];
    const maxEntries = 80;
    for (let i = 0; i < this.elasticDocuments.length; i += 1) {
      const esId = this.elasticDocuments[i]._id;
      if (i !== 0 && i % maxEntries === 0) {
        xmls.push(
          getXMLofDocuments(idsToFetch).then((resp) =>
            this.xmlDocs.push(resp.data._embedded.entries),
          ),
        );
        idsToFetch = "";
        break;
      }
      if (i % maxEntries === 0) {
        idsToFetch = idsToFetch.concat(esId);
      } else {
        idsToFetch = idsToFetch.concat("%2C", esId);
      }
    }

    await Promise.all(xmls);

    const result = Object.values(this.createDocumentsForConnectRows());

    this.documents = result.sort((a: any, b: any) => (a.es_id > b.es_id ? 1 : -1));
    this.picturesOfDrawer = this.pictureResponse.sequences[0].canvases.map(
      (a: any): IFFFPicture => ({
        path: extractPathFromIFFUrl(a.images[0].resource["@id"]) ?? "",
      }),
    );
    this.loading = false;
  }

  exportToJsonFile(jsonData: any) {
    const dataStr = JSON.stringify(jsonData);

    const dataUri = "data:application/json;charset=utf-8,".concat(encodeURIComponent(dataStr));

    const exportFileDefaultName = "data.json";

    const linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  }

  fetchDrawer() {
    const djangoDocs = getDocumentsOfDrawer(this.selecetedDrawerSanatizedForQueries).then(
      (resp) => {
        this.djangoDocuments = resp.data.results.map((doc: any) => {
          return {
            ...doc,
            django_id: doc.id,
          };
        });
      },
    );
    const esDocuments = getDocumentsForScans(this.selecetedDrawerSanatizedForQueries).then(
      (resp) => {
        this.elasticDocuments = resp.data.hits.hits;
      },
    );
    const pics = getImagesOfDrawer(this.selectedDrawerForImages || "").then((resp) => {
      this.pictureResponse = resp.data;
    });

    return Promise.all([djangoDocs, esDocuments, pics]);
  }

  fetchXMLDocsfromDb(ids: string): Promise<any> {
    const xml = Promise.resolve(
      getXMLofDocument(ids).then((resp) => {
        this.xmlDocs = resp.data.entry;
      }),
    );
    return Promise.all([xml]);
  }

  createDocumentsForConnectRows(): Record<string, ScanDocument> {
    const result: Record<string, ScanDocument> = {};
    this.djangoDocuments.forEach((doc) => {
      if (!doc.es_id) return;
      result[doc.es_id] = doc;
    });
    this.elasticDocuments.forEach((esDoc) => {
      let a = result[esDoc._id];
      if (!a) {
        result[esDoc._id] = esDoc;
        a = result[esDoc._id];
        a.es_id = esDoc._id;
      }
      a.A = esDoc._source.Archivzeile;
      a.HL = esDoc._source.HL;
      a.QU = esDoc._source.QU;
      a.QDB = esDoc._source.QDB;
      a.NR = esDoc._source.NR;
      a.LT1 = esDoc._source.LT1_teuthonista;
      a.KT1 = esDoc._source.KT1;
      a.source = esDoc._source;
      a.es_id = esDoc._id;

      a.LT2 = esDoc._source.LT2_teuthonista;
      a.KT2 = esDoc._source.KT2;

      a.bdktlts = esDoc._source["BD/KT*"];

      let bdktlt: string | Array<string> | undefined = esDoc._source["BD/KT*"];
      if (bdktlt !== undefined && Array.isArray(bdktlt)) {
        // eslint-disable-next-line prefer-destructuring
        a.BDKTLT1 = bdktlt[0];

        if (bdktlt[1]) {
          // eslint-disable-next-line prefer-destructuring
          a.BDKTLT2 = bdktlt[1];
        }
      }
      if (bdktlt === undefined) {
        bdktlt = esDoc._source["BD/LT*"];
        a.bdktlts = bdktlt;
        if (bdktlt !== undefined && Array.isArray(bdktlt)) {
          // eslint-disable-next-line prefer-destructuring
          a.BDKTLT1 = bdktlt[0];

          if (bdktlt[1]) {
            // eslint-disable-next-line prefer-destructuring
            a.BDKTLT2 = bdktlt[1];
          }
        }
      }
    });
    return result;
  }

  async searchScans() {
    if (!this.searchTerm || this.searchTerm === "") {
      this.changeDrawer();
      return;
    }
    this.loading = true;

    await this.fetchEsDocumentsFromSearch();
    await this.getDjangoDocsFromESDocs(this.elasticDocuments);

    const result = Object.values(this.createDocumentsForConnectRows());

    this.sortAndAssignSearchResults(result);

    this.begin = 0;
    this.limit = this.begin + this.pageSize;

    this.loading = false;
  }

  sortAndAssignSearchResults(result?: any) {
    let docsToSort: Array<any>;

    if (result) {
      docsToSort = result;
    } else {
      docsToSort = this.documents;
    }
    if (docsToSort && this.searchTerm !== "") {
      switch (this.selectedSearchSortMethod.sort) {
        case "lastDigitsAfterHashtagDigits":
          this.documents = docsToSort.sort((a: any, b: any) => {
            return this.experimentalArchiveSort(a, b);
          });
          break;
        default:
          this.documents = docsToSort.sort((a: any, b: any) => (a.es_id > b.es_id ? 1 : -1));
          break;
      }
    }
  }

  fetchEsDocumentsFromSearch(): Promise<any> {
    const esDocuments = getDocumentsFromSearch(
      this.selecetedDrawerSanatizedForQueries,
      this.searchTerm,
    ).then((resp) => {
      this.elasticDocuments = resp.data.hits.hits;
    });

    return Promise.all([esDocuments]);
  }

  getDjangoDocsFromESDocs(esDocs: any[]): Promise<any> {
    let djangoDocs: any[] = [];

    const requests = esDocs.map((doc) => {
      // create a promise for each API call
      return new Promise((resolve) => {
        resolve(getDjangoDocumentByEsID(doc._id));
      });
    });

    return Promise.all(requests)
      .then((res) => {
        // this gets called when all the promises have resolved/rejected.
        res.forEach((doc: any) => {
          if (doc) {
            if (doc.data.count > 0) {
              const temp = {
                ...doc.data.results[0],
                django_id: doc.data.results[0].id,
              };

              djangoDocs = [...djangoDocs, ...[temp]];
            }
            if (doc.data.count > 1) {
              this.l.log("Es Id search returened more than one Document.");
            }
          }
        });
      })
      .then(() => {
        this.djangoDocuments = djangoDocs;
        return djangoDocs;
      });
  }

  experimentalArchiveSort(a: any, b: any): number {
    let aArchive = String(a.A);
    aArchive = aArchive.slice(aArchive.lastIndexOf("#") + 1);

    let bArchive = String(b.A);
    bArchive = bArchive.slice(bArchive.lastIndexOf("#") + 1);
    return parseFloat(aArchive.replace(",", ".").replace(" ", "")) >
      parseFloat(bArchive.replace(",", ".").replace(" ", ""))
      ? 1
      : -1;
  }
}
</script>

<style lang="scss">
.scroller {
  height: 100%;
}

.scrollchildren {
  height: 32%;
}
</style>
