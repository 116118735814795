import { AxiosResponse } from "axios";
import { CompleteDocument } from "../../static/apiModels";

import api from ".";
import Logger from "../../services/LoggerService";

const l = new Logger("DAPI_DOCUMENTS");

export const editDocumentTags = (document: CompleteDocument): Promise<AxiosResponse<any>> => {
  l.debug("in editDocs", document);
  const params: any = {};
  l.debug("Was fetz ma?", params);
  // @ts-ignore
  const { id } = document;
  params.es_id = document.es_id;
  params.tag = document.tag;
  return api.put(`/api/documents/${id}/`, params);
};

export const createDocument = (document: any): Promise<AxiosResponse<any>> => {
  return api.post("/api/documents/", document, {
    validateStatus(status) {
      return status === 201;
    },
  });
};

// Doesn't support creation with XML
export const createDocumentsForCollection = (documents: any[]): Promise<AxiosResponse<any>> => {
  return api.post("/api/documents/", documents, {
    validateStatus(status) {
      return status === 201;
    },
  });
};

export const getDocumentsOfDrawer = (drawer: string) => {
  return api.get("/api/documents/", {
    params: { es_id__startswith: drawer.toLowerCase(), page_size: 10000 },
  });
};

export const getDjangoDocumentByEsID = (esID: string) => {
  return api.get("/api/documents/", {
    params: {
      es_id: esID,
      page_size: 10000,
    },
  });
};

export const patchDocument = (id: string, body: any) => api.patch(`/api/documents/${id}/`, body);
