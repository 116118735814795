// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected[data-v-8e976006] {
  background-color: #42b983 !important;
}
.lefty[data-v-8e976006] {
  margin: 50px;
  margin-top: 60px;
  float: left;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
