import { AxiosResponse } from "axios";

import {
  SingleCollection,
  ApiCollectioRequest,
  ApiCollectionParameters,
  ApiPatchCollectionParameters,
  CollectionBaseInfo,
} from "@/static/apiModels";

import api from ".";

export const getCollections = (
  params: ApiCollectioRequest,
): Promise<AxiosResponse<CollectionBaseInfo>> => {
  const PARAMS = { ...params, deleted: false };
  return api.get("/api/collections/", { params: PARAMS });
};

export const getDeletedCollections = (params: ApiCollectioRequest): Promise<AxiosResponse<any>> => {
  const PARAMS = { ...params, deleted: true };
  return api.get("/api/collections", { params: PARAMS });
};

export const getOneCollection = (id: number): Promise<AxiosResponse<SingleCollection>> => {
  return api.get(`/api/collections/${id}`);
};

export const patchCollection = (
  collection: ApiPatchCollectionParameters,
  id: number,
): Promise<AxiosResponse<any>> => {
  return api.patch(`/api/collections/${id}/`, collection);
};

export const getDocsOfCollection = (id: number): Promise<AxiosResponse<any>> => {
  return api.get(`/api/documents/?in_collections=${id}&page_size=1000000`);
};

export const deleteCollection = (id: number): Promise<AxiosResponse<any>> => {
  return api.delete(`/api/documents/${id}`);
};

export const postCollection = (
  newCollection: ApiCollectionParameters,
): Promise<AxiosResponse<any>> => {
  return api.post("/api/collections/", newCollection);
};
