// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.darktag[data-v-a85eabf4] {
  color: white;
}
.brighttag[data-v-a85eabf4] {
  color: black;
}
.xcursor[data-v-a85eabf4] {
  cursor: crosshair;
}
.xcursor span[data-v-a85eabf4] {
  cursor: crosshair;
}
.xcursor > span[data-v-a85eabf4] {
  cursor: crosshair;
}
.xcursor + span[data-v-a85eabf4] {
  cursor: crosshair;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
