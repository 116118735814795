<template>
  <vContainer v-if="Collection && UM.users.length > 0">
    <h1>{{ $t("Collections.EditView.main-header") }}</h1>
    <p></p>
    <v-text-field
      data-test="collection-title"
      v-model="Collection.title"
      label="Title"
    ></v-text-field>
    <v-textarea
      data-test="collection-description"
      v-model="Collection.description"
      label="Description"
    ></v-textarea>
    <v-textarea
      data-test="collection-comment"
      v-model="Collection.comment"
      label="Comment"
    ></v-textarea>
    <ApiSelect
      itemText="username"
      label="user"
      type="users"
      @input="l.debug('as', $event)"
      :multiple="true"
      v-model="curators"
      :filterValue="curators"
    ></ApiSelect>
    <v-autocomplete
      v-model="selLemma"
      :items="lemmaItems"
      item-text="lemmatisierung"
      :loading="loading"
      :search-input.sync="search"
      clearable
      label="Artikel-Lemmata auswählen"
      dense
      return-object
      filled
      data-test="collection-lemma-select"
    ></v-autocomplete>
    <CollectionCategorySelection
      data-test="collection-category-select"
      :selectionLabel="'Collections.categories-selection-label'"
      v-model="selectedCollectionCategory"
    ></CollectionCategorySelection>
    <v-checkbox
      data-test="collection-public-checkbox"
      v-model="Collection.public"
      :label="'Public'"
    ></v-checkbox>
    <v-btn
      data-test="collection-save-changes-button"
      block
      style="float: left"
      @click="changeCollection()"
      >Edit</v-btn
    >
    <v-btn
      data-test="collection-cancel-changes-button"
      block
      style="float: left"
      @click="cancelChange()"
      >Cancel</v-btn
    >
  </vContainer>
</template>

<script lang="ts">
import { Component, Watch, Vue } from "vue-property-decorator";
import { CollectionCategory } from "@/static/collectionConstants";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";

import { LemmaModule } from "../store/modules/lemma";

import { CollectionsModule } from "../store/modules/collections";
import ApiSelect from "./ApiSelect.vue";
import { ApiUser, SingleLemma } from "../static/apiModels";

import { UsersModule } from "../store/modules/users";
import CollectionCategorySelection from "./CollectionCategorySelection.vue";

@Component({
  // if you use components add them here
  components: {
    SnackbarSwitch,
    DarkmodeSwitch,
    ApiSelect,
    CollectionCategorySelection,
  },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "CollectionEditView",
})
export default class CollectionEditView extends Vue {
  l = new Logger("CollectionsEditView.vue");

  curators: (ApiUser | undefined)[] = [];

  curatorURLS: string[] = [];

  search: string = "";

  _searchTimerId: any;

  selLemma: SingleLemma | null = null;

  selectedCollectionCategory: CollectionCategory = CollectionsModule.currentCollectionCategory;

  @Watch("curators")
  onCuratorChange() {
    // @ts-ignore
    this.curatorURLS = this.curators.map((c) => c.url);
  }

  @Watch("search")
  onSearchChange() {
    if (!this.search || this.search.length === 0 || this.search === "") return;
    if (this.lemma && this.search === this.lemma.org) return;
    // eslint-disable-next-line no-underscore-dangle
    clearTimeout(this._searchTimerId);
    // eslint-disable-next-line no-underscore-dangle
    this._searchTimerId = setTimeout(() => {
      LemmaModule.fetchSearchLemma({
        lemmatisierung: this.search,
        has__simplex: 2,
        page_size: 20,
      });
    }, 500);
  }

  get Collection() {
    return CollectionsModule.currentCollection;
  }

  get lemmaItems() {
    return LemmaModule.searchLemma;
  }

  get loading() {
    return LemmaModule.loading;
  }

  get lemma() {
    return LemmaModule.currentLemma;
  }

  changeCollection() {
    if (this.Collection !== null) {
      const collToUpdate = {
        title: this.Collection.title,
        description: this.Collection.description,
        comment: this.Collection.comment,
        public: this.Collection.public,
        curator: this.curatorURLS,
        lemma_id: this.selLemma ? this.selLemma.url : null,
        category: this.selectedCollectionCategory.id,
      };
      CollectionsModule.editCollection({
        collection: collToUpdate,
        id: +this.$route.params.id,
      });
    } else {
      this.l.error("ALARM: NULL");
    }
    this.$router.push(`/collections/${this.$route.params.id}`);
  }

  cancelChange() {
    this.$router.push(`/collections/${this.$route.params.id}`);
  }

  beforeCreate() {
    CollectionsModule.fetchCurrentCollection(+this.$route.params.id).then(() => {
      // eslint-disable-next-line camelcase
      const lemma = this.Collection ? this.Collection.lemma_id : null;
      if (lemma) {
        LemmaModule.fetchSingleLemmaByUrl(lemma).then(() => {
          this.selLemma = LemmaModule.currentLemma;
          if (LemmaModule.currentLemma) LemmaModule.appendLemma(LemmaModule.currentLemma);
        });
      }
      if (this.Collection) {
        this.selectedCollectionCategory = CollectionsModule.currentCollectionCategory;
      }
    });
  }

  UM = UsersModule;

  get users() {
    return this.UM.users;
  }

  @Watch("users")
  onch() {
    if (!this.Collection) return;
    this.curators = [];
    this.Collection.curator.forEach((curator: string) => {
      this.l.debug("in loop", curator, this.UM.byUrl(curator));
      this.curators.push(this.UM.byUrl(curator));
    });
  }

  mounted() {
    if (!this.Collection) return;
    this.curators = [];
    this.Collection.curator.forEach((curator: string) => {
      this.l.debug("in loop", curator, this.UM.byUrl(curator));
      this.curators.push(this.UM.byUrl(curator));
    });
  }
}
</script>
