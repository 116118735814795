import { AxiosResponse } from "axios";
import api from ".";

export type Bundesland =
  | "6.5 Wien"
  | "6 NÖ"
  | "2 Ktn."
  | "3 Stmk. ›LT1"
  | "3 Stmk."
  | "4 Sbg."
  | "1C NTir."
  | "5 OÖ"
  | "1B OTir."
  | "1A STir."
  | "1A STir. ›LT1"
  | "7 Bgl."
  | "1C NTir. ›LT1"
  | "5 OÖ ›LT1"
  | "1B OTir. ›LT1"
  | "4 Sbg. ›LT1"
  | "2 Ktn. ›KT2"
  | "6 NÖ ›LT1"
  | "1A STir. ›KT1"
  | "6.5 Wien ›LT1";

export type Entry = {
  "@source": string;
  "@xmlns": { $: string };
  "@id": string;
  "@n": string;
  "@facs"?: string;
};

export type Pos = "Verb" | "Subst" | "Num" | "Adj" | "Interj";

/**
 * This type is not the definitive list of all possible fields in the ES documents.
 * It should be extended when needed.
 */
export type EsApiDocumentSource = {
  "BD/KT*"?: string[];
  HL: string[] | string;
  NR?: string[] | string;
  QU?: string;
  KT2?: string[];
  KT1?: string[] | string;
  KT3?: string[] | string;
  QDB?: string;
  BIBL?: string;
  Ort?: string[];
  "ANM/KT*"?: string[];
  entry: Entry;
  Archivzeile: string;
  Verweis?: string[] | string;
  POS: Pos;
  "KL/KT1"?: string[] | string;
  ID: string;
  "KL/KT2"?: string;
  "KL/KT3"?: string;
  Sigle1?: string[];
  "DV/KT*"?: string[];
  "ZL1/KT1"?: string[] | string;
  ZW1?: string[] | string;
  Page?: string[] | string;
  LT1_teuthonista?: string[] | string;
  "GRAM/LT1"?: string[];
  "BD/LT*"?: string[] | string;
  "ANM/LT*"?: string[];
  Großregion1?: string[];
  Bundesland1?: Bundesland[];
  NL?: string[] | string;
  "ZL2/KT1"?: string[] | string;
  "ZL3/KT1"?: string[] | string;
  "GRAM/LT3"?: string[];
  "GRAM/LT2"?: string[];
  Kleinregion1?: string[];
  LT2_teuthonista?: string[] | string;
  LT3_teuthonista?: string[] | string;
  Gemeinde1?: string[];
  "Ort/LT*"?: string[];
  DIV?: string[];
  Bundesland2?: string[];
  Sigle2?: string[];
  Großregion2?: string[];
  ETYM?: string[] | string;
  "ZL1/KT2"?: string[] | string;
  "ZL4/KT1"?: string[] | string;
  "ZL5/KT1"?: string[];
  ANM?: string;
  "GRAM/LT4"?: string[];
  LT4_teuthonista?: string[] | string;
  "Ort/KT*"?: string[];
  Kleinregion2?: string[];
  Gemeinde2?: string[];
  "BD/LW*"?: string[] | string;
  LW1?: string[] | string;
  "ANM/LW*"?: string[];
  name?: string[] | string;
  "GRAM/LT5"?: string[];
  LT5_teuthonista?: string[];
  "ZL3/KT3"?: string[];
  "ZL2/KT3"?: string[];
  "ZL1/KT3"?: string;
  "ZL2/KT2"?: string[];
  "ZL3/KT2"?: string;
  Sigle3?: string[];
  Kleinregion3?: string[];
  Bundesland3?: Bundesland[];
  Großregion3?: string[];
  "GRAM/LT6"?: string[];
  LT6_teuthonista?: string[];
  "GRAM/LT7"?: string[];
  "GRAM/LT8"?: string[];
  LT8_teuthonista?: string[];
  LT7_teuthonista?: string[];
};

export type EsApiDocument = {
  _id: string;
  _index: string;
  _source: EsApiDocumentSource;
  _score: number;
};

export type EsApiDocumentsResponse = {
  took: number;
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    total: {
      value: number;
      relation: string;
    };
    max_score: number;
    hits: EsApiDocument[];
  };
};

export const getDocument = (id: string): Promise<AxiosResponse<any>> => {
  return api.get(`/_doc/${id}?_source_excludes=location*,entry`);
};

export const getDocuments = (ids: string[]): Promise<AxiosResponse<EsApiDocumentsResponse>> => {
  //  return api.get(`/_doc/${id}?_source_excludes=location*,entry`);
  return api.post(
    `/dboe/_search?size=10000&_source_excludes=location*,entry&version=true`,
    {
      query: {
        bool: {
          filter: {
            ids: {
              values: ids,
            },
          },
        },
      },
    },
    /* {
      params: {
        size: 10000,
        _source_excludes: 'location*,entry',
        source_content_type: 'application/json',
        version: true,
      },
    } */
  );
};

export const getDocumentsForScans = (
  drawer: string,
): Promise<AxiosResponse<EsApiDocumentsResponse>> => {
  //  return api.get(`/_doc/${id}?_source_excludes=location*,entry`);
  return api.post(
    `/dboe/_search?size=10000&_source_excludes=location*&q=${drawer.toLowerCase()}`,
    /*
    // This unfortunately does not work,since the results are ordered like:
    // 1. ".... f231#1.1 ....", 2. "... f231#10.1 ...", 3. "... f231#100.1 ..."
    {
      size: 10000,
      'sort' : [
        {
          'Archivzeile.keyword':
          {
            'order': 'asc'
          }
        }
      ],
      'query': {
        'term': {
          'Archivzeile': `${drawer.toLowerCase()}`
        }
      }
    },
    */
  );
};

export const getDocumentsFromSearch = (
  drawer: string,
  query: string,
): Promise<AxiosResponse<EsApiDocumentsResponse>> => {
  const url = "/dboe/_search?_source_excludes=location*";
  let finalQuery = "Archivzeile: ";

  if (drawer && drawer !== "") {
    finalQuery = finalQuery.concat(`(\"${drawer}\" AND `); // eslint-disable-line no-useless-escape
  }

  if (query && query !== "") {
    finalQuery = finalQuery.concat('"', query, '"'); // eslint-disable-line no-useless-escape
  } else {
    finalQuery = finalQuery.concat("");
  }

  if (drawer && drawer !== "") {
    finalQuery = finalQuery.concat(")");
  }

  /* eslint-disable */
  const body = {
    size: 1000,
    query: {
      bool: {
        must: [
          {
            query_string: {
              query: finalQuery,
              analyze_wildcard: true,
            },
          },
        ],
      },
    },
  };
  /* eslint-enable */

  return api.post(url, body);
};

/** for reference
 *
 *
 *
export const getCollections = (
  params: ApiCollectioRequest
): Promise<AxiosResponse<any>> => {
  return api.get('/api/collections', { params });
};

export const getOneCollection = (id: number): Promise<AxiosResponse<any>> => {
  return api.get(`/api/collections/${id}`);
};

 *
 */
