<template>
  <v-autocomplete
    v-model="syncedSelectedCollection"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :item-text="searchDisplay"
    item-value="id"
    return-object
    label="Target Collection (hit enter to search)"
    @input="$emit('input', syncedSelectedCollection)"
    @keypress.enter="startSearch"
    data-test="collection-select"
  >
    <template
      slot="item"
      slot-scope="{ item }"
    >
      <span :data-test="`collection-select-item-${item.id}`">
        {{ item.id }} - {{ item.title }}</span
      >
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> Suchen Sie nach einer Sammlung </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, Vue, PropSync } from "vue-property-decorator";
import { ApiCollectionResponse, CollectionBaseInfo } from "@/static/apiModels";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import { CollectionsModule } from "../store/modules/collections";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "CollectionSelect",
})
export default class CollectionSelect extends Vue {
  @Prop(String) readonly msg: String | undefined;

  l = new Logger("CollectionSelect.vue");

  @PropSync("selectedCollection")
  syncedSelectedCollection!: CollectionBaseInfo;

  search: string = "";

  get loading() {
    return CollectionsModule.loading;
  }

  searchDisplay(object: ApiCollectionResponse) {
    return `${object.id} - ${object.title}`;
  }

  extractTitleAndIdFromQuery(query: string): { id: number; title: string } | undefined {
    const regex = /^(\d+)(.*)$/;
    const match = regex.exec(query);
    if (match === null) {
      return undefined;
    }
    return {
      id: +match[1],
      title: match[2].trim(),
    };
  }

  startSearch() {
    const searchTerm = this.search.trim();

    if (searchTerm === null || searchTerm.length < 1) {
      return;
    }

    const extractedSearch = this.extractTitleAndIdFromQuery(searchTerm);

    if (extractedSearch === undefined) {
      CollectionsModule.fetchCollections({ title: this.search });
      return;
    }

    CollectionsModule.fetchCollections({
      id__contains: extractedSearch.id,
      title: extractedSearch.title,
    });
  }

  get items() {
    // TODO: fetch all collections
    if (!CollectionsModule.collections) {
      return this.syncedSelectedCollection ? [this.syncedSelectedCollection] : [];
    }
    return [
      [this.syncedSelectedCollection],
      ...CollectionsModule.collections.results.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      }),
    ];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
