// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3[data-v-52f535ac] {
  margin: 40px 0 0;
}
ul[data-v-52f535ac] {
  list-style-type: none;
  padding: 0;
}
li[data-v-52f535ac] {
  display: inline-block;
  margin: 0 10px;
}
a[data-v-52f535ac] {
  color: #42b983;
}
.login[data-v-52f535ac] {
  margin: 10px;
}
#xml[data-v-52f535ac] {
  width: 100%;
  height: 90vh;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
