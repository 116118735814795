export interface IFFFPicture {
  path: string;
}

export function getSrcFromIFFPicture(
  pic: IFFFPicture,
  /**
   * See: https://iiif.io/api/image/2.1/#size
   * full 	The image or region is not scaled, and is returned at its full size. Note: deprecation warning.
   * max 	The image or region is returned at the maximum size available, as indicated by maxWidth, maxHeight, maxArea in the profile description. This is the same as full if none of these properties are provided.
   * w, 	The image or region should be scaled so that its width is exactly equal to w, and the height will be a calculated value that maintains the aspect ratio of the extracted region.
   * ,h 	The image or region should be scaled so that its height is exactly equal to h, and the width will be a calculated value that maintains the aspect ratio of the extracted region.
   * pct:n 	The width and height of the returned image is scaled to n% of the width and height of the extracted region. The aspect ratio of the returned image is the same as that of the extracted region.
   * w,h 	The width and height of the returned image are exactly w and h. The aspect ratio of the returned image may be different than the extracted region, resulting in a distorted image.
   * !w,h 	The image content is scaled for the best fit such that the resulting width and height are less than or equal to the requested width and height.
   */
  size?: string,
): string {
  return `${
    process.env.VUE_APP_IMAGEDATASERVER ?? "https://walk-want-grew-imgs.acdh-dev.oeaw.ac.at"
  }/iiif/images/${pic.path}/full/${size ?? "!750, 450"}/0/default.jpg`;
}

export function extractPathFromIFFUrl(str: string): string | null {
  const match = str.match(/\/images\/(.+?\.jp2)/);
  if (match) {
    return match[1];
  }
  return null;
}
