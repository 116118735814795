<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        <h2>{{ $t("CreateTags.create") }}</h2>
      </v-card-title>
      <v-layout>
        <v-card-text>
          <v-text-field
            data-test="creat-tag-name"
            type="text"
            label="Name"
            v-model="tag.name"
          >
          </v-text-field>
          <v-text-field
            data-test="creat-tag-color"
            :color="tag.color"
            :label="$t('CreateTags.colour')"
            v-model="tag.color"
          >
          </v-text-field>
          <v-btn
            data-test="creat-tag-submit"
            block
            @click="sendTag()"
            >Submit</v-btn
          >
        </v-card-text>
      </v-layout>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { ApiTagPost } from "@/static/apiModels";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import { TagsModule } from "../store/modules/tags";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import "emoji.json";

@Component({
  components: { SnackbarSwitch, DarkmodeSwitch },
  name: "createTags",
})
export default class CreateTags extends Vue {
  l = new Logger("createTags.vue");

  /* eslint-disable global-require */
  emoji = require("emoji.json");
  /* eslint-enable global-require */

  tag: ApiTagPost = { name: "" };

  TM = TagsModule;

  sendTag() {
    this.tag.emoji = this.emoji[Math.floor(Math.random() * this.emoji.length) + 1].char;
    this.TM.postTheDamnTag(this.tag);
    this.TM.setCreationDialog(false);
    this.randomColor();
  }

  get dialog() {
    return this.TM.creationDialog;
  }

  set dialog(val: boolean) {
    this.TM.setCreationDialog(false);
    this.randomColor();
  }

  randomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    this.tag.color = color;
  }

  created() {
    this.randomColor();
  }
}
</script>
