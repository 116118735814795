<!-- meant to be copied to be a new component-->
<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        data-test="language-select-open-menu-btn"
        text
        v-on="on"
      >
        {{ selectedLang }}
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="text-xs-center">
      <v-list-item
        :data-test="`language-select-${item.value}`"
        v-for="(item, index) in langs"
        :key="index"
        :color="item.value === selectedLang ? 'success' : ''"
        @click="setLang(item.value)"
      >
        <v-list-item-title>
          {{ item.text.toUpperCase() }}
          <v-icon
            v-if="item.value === selectedLang"
            color="success"
            >mdi-check</v-icon
          >
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import { SettingsModule } from "../store/modules/settings";

@Component({
  name: "LanguageSelect",
})
export default class LanguageSelect extends Vue {
  l = new Logger("LanguageSelect.vue");

  langs = [
    {
      value: "de",
      text: "de",
    },
    {
      value: "en",
      text: "en",
    },
  ];

  get selectedLang() {
    return SettingsModule.getLanguage;
  }

  setLang = SettingsModule.setLanguage;

  created() {}
}
</script>

<style scoped lang="scss"></style>
