<template>
  <vContainer v-if="lemma && UM.users.length > 0">
    <h1>Edit Lemma</h1>
    <p></p>
    <v-text-field
      data-test="lemma-edit-norm"
      v-model="lemma.norm"
      label="norm"
    ></v-text-field>
    <v-text-field
      data-test="lemma-edit-org"
      v-model="lemma.org"
      label="org"
    ></v-text-field>
    <v-text-field
      data-test="lemma-edit-lemmatisierung"
      v-model="lemma.lemmatisierung"
      label="Lemmatisierung"
    ></v-text-field>
    <v-text-field
      data-test="lemma-edit-filename"
      v-model="lemma.filename"
      label="filename"
    ></v-text-field>
    <v-text-field
      data-test="lemma-edit-pos"
      v-model="lemma.pos"
      label="pos"
    ></v-text-field>
    <v-text-field
      data-test="lemma-edit-suggestion"
      v-model="lemma.suggestion"
      label="suggestion"
    ></v-text-field>
    <template v-if="change">
      {{ $t("LemmaEditView.currentLemmata") }}: {{ lemma.art_lemmatisierung }}
    </template>
    <v-autocomplete
      data-test="lemma-edit-simplex-autocomplete"
      v-model="lemma.simplex"
      :items="lemmata"
      item-text="lemmatisierung"
      item-value="url"
      :loading="loading"
      :search-input.sync="search"
      clearable
      :label="$t('LemmaEditView.chooseSimplex')"
      dense
      filled
    ></v-autocomplete>
    <v-textarea
      v-model="lemma.comment"
      label="comment"
    ></v-textarea>
    <ApiSelect
      data-test="lemma-edit-assigned-user-api-select"
      itemText="username"
      label="user"
      type="users"
      @input="l.debug('as', $event)"
      :multiple="false"
      v-model="assignedUser"
      :filterValue="assignedUser"
    ></ApiSelect>

    <!-- Advanced Edit Mode -->
    <div
      v-if="isLoggedIn"
      class="my-2"
    >
      <v-card>
        <v-toolbar
          color="secondary"
          elevation-0
        >
          <v-card-title>
            {{ $t("LemmaEditView.aeMode") }}
          </v-card-title>
        </v-toolbar>

        <div v-if="advancedEditMode">
          <v-card-text v-bind:class="{ 'success--text': aeDocumentCountChanged }">
            {{ $t("LemmaEditView.documentCount") }}
            <v-text-field
              data-test="lemma-edit-advanced-edit-document-count"
              v-model="aeDocumentCount"
              hide-details
              single-line
              type="number"
              label="Belegzahlen"
              v-bind:class="{ 'success--text': aeDocumentCountChanged }"
            />
          </v-card-text>
          <!-- <v-card-actions >
            <v-btn outlined  color="danger" class="mb-1" @click="startLemmaDeletion()">
              {{$t('LemmaEditView.deleteLemma')}}
            </v-btn>
          </v-card-actions> -->
        </div>

        <!-- Deletion Dialog -->
        <!-- TODO: Replace with ConfirmationModal -->
        <div>
          <v-dialog
            v-model="aeDeletionDialog"
            max-width="400"
          >
            <v-card>
              <v-card-title class="headline">
                {{ $t("LemmaEditView.deleteLemma") }}
              </v-card-title>
              <v-card-text>
                {{ $t("LemmaEditView.lemmaDeletionDialog") }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  data-test="lemma-edit-advanced-edit-delete-cancle"
                  color="primary"
                  @click="aeDeletionDialog = false"
                >
                  {{ $t("LemmaEditView.cancle") }}
                </v-btn>
                <v-btn
                  data-test="lemma-edit-advanced-edit-delete-confirm"
                  color="danger"
                  @click="deleteLemma()"
                >
                  {{ $t("LemmaEditView.delete") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <!-- Show/Hide Advanced edit Mode -->
        <v-card-actions v-if="!advancedEditMode">
          <v-btn
            data-test="lemma-edit-advanced-edit-show"
            block
            color="secondary"
            class="mb-1"
            style="float: left"
            @click="toggleAdvancedEditMode()"
          >
            {{ $t("LemmaEditView.show") }}</v-btn
          >
        </v-card-actions>
        <v-card-actions v-if="advancedEditMode">
          <v-btn
            data-test="lemma-edit-advanced-edit-hide"
            block
            color="secondary"
            class="mb-1"
            style="float: left"
            @click="toggleAdvancedEditMode()"
            >{{ $t("LemmaEditView.hide") }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <!-- TODO: Replace with ConfirmationModal -->
      <v-dialog
        v-model="aeWarningDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $t("LemmaEditView.submitAe") }}
          </v-card-title>
          <v-card-text>{{ $t("LemmaEditView.submitAeDialog") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              data-test="lemma-edit-advanced-edit-dialog-submit-changes-cancle"
              color="error"
              text
              @click="aeWarningDialog = false"
            >
              {{ $t("LemmaEditView.cancle") }}
            </v-btn>
            <v-btn
              data-test="lemma-edit-advanced-edit-dialog-submit-changes-confirm"
              color="primary"
              text
              @click="submitChanges()"
            >
              {{ $t("LemmaEditView.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="my-6">
      <v-btn
        data-test="lemma-edit-submit-changes"
        block
        color="primary"
        class="mb-1 mt-2"
        style="float: left"
        @click="startSubmitChanges()"
        >{{ $t("LemmaEditView.edit") }}</v-btn
      >
      <v-btn
        data-test="lemma-edit-cancle-changes"
        block
        color="error"
        class="mb-1"
        style="float: right"
        @click="cancelChange()"
        >{{ $t("LemmaEditView.cancle") }}</v-btn
      >
    </div>
  </vContainer>
</template>

<script lang="ts">
import { Component, Watch, Vue } from "vue-property-decorator";
import { ApiTask, ApiUser, SingleLemma } from "@/static/apiModels";

import { LemmaModule } from "@/store/modules/lemma";

import { TaskModule } from "@/store/modules/task";

import { AuthModule } from "@/store/modules/auth";

import { UsersModule } from "../store/modules/users";
import Logger from "../services/LoggerService";
import CatSpinner from "./CatSpinner.vue";
import ApiSelect from "./ApiSelect.vue";

@Component({
  // if you use components add them here
  components: {
    CatSpinner,
    ApiSelect,
  },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "LemmaEditView",
})
export default class CollectionEditView extends Vue {
  l = new Logger("LemmaEditView.vue");

  UM = UsersModule;

  TM = TaskModule;

  LM = LemmaModule;

  AM = AuthModule;

  simplex = [];

  aeDocumentCount = 0;

  aeDocumentCountChanged: boolean = false;

  aeSimplex: string = "";

  aeWarningDialog: boolean = false;

  aeDeletionDialog: boolean = false;

  change: boolean = true;

  search: string = "";

  assignedUser: ApiUser | null = null;

  _searchTimerId: any;

  isSimplexNull: boolean = true;

  alreadyAssigned: boolean = false;

  advancedEditMode: boolean = false;

  get lemma() {
    return LemmaModule.currentLemma as SingleLemma;
  }

  get tasks() {
    return TaskModule.tasks;
  }

  get lemmata() {
    return LemmaModule.searchLemma;
  }

  get lemmaList() {
    return LemmaModule.lemmata;
  }

  get id() {
    return +this.$route.params.id;
  }

  get loading() {
    return LemmaModule.singleLoading;
  }

  get users() {
    return this.UM.users;
  }

  get isLoggedIn() {
    return this.AM.loggedIn;
  }

  get aeChanges() {
    return this.aeDocumentCountChanged;
  }

  // Watcher to check the search string for the autocomplete
  @Watch("search")
  onSearchChange() {
    // return if the search string is empty or length zero
    if (!this.search || this.search.length === 0 || this.search === "") return;
    // Check if the Artikel Lemma has changed at all
    if (
      this.lemma &&
      (this.search === this.lemma.org || this.search === this.lemma.art_lemmatisierung)
    )
      return;

    // Timeout for the autocompelete
    // eslint-disable-next-line no-underscore-dangle
    clearTimeout(this._searchTimerId);
    // eslint-disable-next-line no-underscore-dangle
    this._searchTimerId = setTimeout(() => {
      // Only search for the Lemmata which have simplex: null and get the the first twenty from the DB
      LemmaModule.fetchSearchLemma({
        lemmatisierung: this.search,
        has__simplex: 2,
        page_size: 20,
      });
    }, 500);
  }

  // Watcher for the change Variable
  // Checks if the simplex Lemmata has been loaded to display it with the Lemma
  @Watch("lemma.simplex")
  onSimplexChange() {
    if (this.lemma.simplex === null) return;
    if (this.lemma && !this.lemma.simplex.match(/api\/\d*/g)) return;
    this.change = false;
  }

  @Watch("aeDocumentCount")
  onAeDocumentCountChange() {
    // eslint-disable-next-line
    if (this.aeDocumentCount != this.lemma.count) {
      this.aeDocumentCountChanged = true;
    } else {
      this.aeDocumentCountChanged = false;
    }
  }

  startSubmitChanges() {
    if (this.aeChanges) {
      this.aeWarningDialog = true;
    } else {
      this.changeLemma();
    }
  }

  submitChanges() {
    this.aeWarningDialog = false;
    this.submitAdvancedEditChanges();
    this.changeLemma();
  }

  // Function which will be executed when a Lemma is submitted to the DB
  changeLemma() {
    if (this.lemma != null) {
      // Create the object for the edited Lemma
      const lemmaUp = {
        norm: this.lemma.norm ? this.lemma.norm : "",
        org: this.lemma.org,
        lemmatisierung: this.lemma.lemmatisierung,
        filename: this.lemma.filename,
        comment: this.lemma.comment,
        simplex: this.lemma.simplex,
        count: this.lemma.count,
        suggestion: this.lemma.suggestion,
        pos: this.lemma.pos,
      };

      // Check if Simplex of the current Lemma was null and has been now changed to a Lemma
      // Edits the assigned lemmata from the current one to assign it to the higher Lemma
      if (this.isSimplexNull && this.lemma && this.lemma.simplex !== null) {
        LemmaModule.fetchLemmata({ simplex: this.lemma.id }).then(() => {
          if (this.lemmaList) {
            if (this.lemmaList.results.length > 0) {
              this.lemmaList.results.forEach((element) => {
                if (this.lemma) {
                  const newLemma = {
                    norm: element.norm,
                    org: element.org,
                    lemmatisierung: element.lemmatisierung,
                    filename: element.filename,
                    comment: element.comment,
                    simplex: this.lemma.simplex,
                    count: element.count,
                    suggestion: this.lemma.suggestion,
                    pos: this.lemma.pos,
                  };
                  this.LM.editLemma({ id: element.id, lemma: newLemma });
                }
              });
            }
          }
        });
      }

      // Check if a User (and a corresponding Task) has been assigned to the Lemma
      if (this.assignedUser && this.assignedUser.url !== this.lemma.org && !this.alreadyAssigned) {
        // No User has been assigned and a new Task will be created
        const newTask = {
          user: this.assignedUser.url,
          step: "ZUGEWIESEN",
          status: "DRAFT",
          description: "",
          deadline: null,
          current: true,
          lemma: this.lemma.url,
          finished_date: null,
        };
        TaskModule.createTask(newTask);
      } else if (this.assignedUser && this.alreadyAssigned) {
        // A User has already been assigned
        // Check if this Task exists
        const oT = this.tasks ? this.tasks.results[0] : ({} as ApiTask);
        if (!(oT.user_name === this.assignedUser.username && oT.user === this.assignedUser.url)) {
          // Update the old Task and set current false
          const oldTask = {
            user: oT.user,
            step: oT.step,
            status: oT.status,
            description: oT.description,
            deadline: oT.deadline,
            current: false,
            lemma: this.lemma.url,
            finished_date: oT.finished_date,
          };

          // Create the new Task with current true
          const nTask = {
            user: this.assignedUser.url,
            step: "ZUGEWIESEN",
            status: "DRAFT",
            description: "",
            deadline: null,
            current: true,
            lemma: this.lemma.url,
            finished_date: null,
          };
          this.TM.editTask({
            id: oT.id,
            prevTask: oldTask,
            newTask: nTask,
          });
        }
      }
      // cast the id of the Lemma to a number
      const num = Number(this.$route.params.id);
      LemmaModule.editLemma({ id: num, lemma: lemmaUp });
      this.$router.push(`/lemma/${this.$route.params.id}`);
    }
  }

  cancelChange() {
    this.cancleAdvancedEditChanges();
    this.$router.push(`/lemma/${this.$route.params.id}`);
  }

  // Advanced Editing:
  submitAdvancedEditChanges() {
    this.lemma.count = this.aeDocumentCount;
  }

  cancleAdvancedEditChanges() {
    this.aeDocumentCount = this.lemma.count;
    this.aeSimplex = this.lemma.simplex;
  }

  startLemmaDeletion() {
    this.aeDeletionDialog = true;
  }

  deleteLemma() {
    this.l.info("This functionality has yet to be implemented by the developer.");
    // Rerout to lemma over view
    // Delete Lemma
  }

  toggleAdvancedEditMode() {
    this.advancedEditMode = !this.advancedEditMode;
  }

  // Lifecycle hook executed before the creation of the component
  // Fetches the required data from the DB
  beforeCreate() {
    // Fetch the current Lemma by ID
    LemmaModule.fetchCurrentLemmata(+this.$route.params.id).then(() => {
      // Check if the current lemma exists and save the simplex Object to a variable
      if (LemmaModule.currentLemma) {
        const simplex = LemmaModule.currentLemma.simplex
          ? LemmaModule.currentLemma.simplex
          : LemmaModule.currentLemma;

        // If this Lemma has a another Artikel Lemma => set isSimplexNull to false
        // This is used to determine when a changed to the Artikel Lemma has happened
        if (LemmaModule.currentLemma.simplex) this.isSimplexNull = false;
        // Add the Lemma to the search List of the autocomplete
        if (typeof simplex === "string") {
          this.search = LemmaModule.currentLemma.art_lemmatisierung
            ? LemmaModule.currentLemma.art_lemmatisierung
            : "";
        } else {
          LemmaModule.appendLemma(simplex);
        }

        // For Advanced Editing
        if (!this.isSimplexNull) {
          this.aeDocumentCount = LemmaModule.currentLemma.count;
          this.aeSimplex = LemmaModule.currentLemma.simplex;
        }
      }

      // This if block is used to fetch the assigned User to the task
      if (this.lemma) {
        let lemmaID: string = "";
        if (this.lemma) {
          // Get the ID of the Lemma or the higher assigned Artikel Lemma
          lemmaID =
            this.lemma.simplex === null
              ? String(this.lemma.id)
              : this.LM.byUniqueURL(this.lemma.simplex);
        }
        TaskModule.fetchTasks({
          lemma__id: lemmaID,
          current: true,
        }).then(() => {
          let user: string = "";
          // Get the assigned User
          if (this.tasks) {
            user = this.tasks.results.length > 0 ? this.tasks.results[0].user : "";
          }
          if (this.lemma && this.lemma.assigned_task) {
            user = this.lemma.assigned_task.user;
          }
          this.assignedUser = this.UM.byUrl(user) || null;
          if (this.assignedUser) this.alreadyAssigned = true;
        });
      }
    });
  }
}
</script>
