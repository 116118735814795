// currently not in use

const menu: Menu = {
  items: [
    {
      name: "home",
      path: "/",
      meta: {
        requireLogin: false,
      },
    },
    {
      name: "create",
      path: "/create",
      meta: {
        requireLogin: true,
      },
    },
    {
      name: "settings",
      path: "/settings",
      meta: {
        requireLogin: false,
      },
    },
    {
      name: "collections",
      path: "/collections",
      meta: {
        requireLogin: false,
      },
    },
    {
      name: "log",
      path: "/log",
      meta: {
        requireLogin: false,
      },
    },
    {
      name: "login",
      path: "/login",
      meta: {
        requireLogin: false,
      },
    },
  ],
};

export default menu;
