<template lang="html">
  <v-app>
    <TopNavigationBar></TopNavigationBar>
    <v-navigation-drawer
      :value="sideBar"
      clipped
      right
      app
      permanent
      v-if="sideBar"
    >
      <HeaderSelect></HeaderSelect>
    </v-navigation-drawer>

    <Snackbars></Snackbars>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
    <CCFooter />
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import HeaderSelect from "@/components/HeaderSelect.vue";
import TopNavigationBar from "./components/Navigation.vue";
import Snackbars from "./components/Snackbars.vue";
import { SettingsModule } from "./store/modules/settings";
import { UsersModule } from "./store/modules/users";
import { IRootState } from "./store";
import { AuthModule } from "./store/modules/auth";
import Logger from "./services/LoggerService";
import { NotificationsModule } from "./store/modules/notifications";
import { TagsModule } from "./store/modules/tags";
import CCFooter from "./components/CCFooter.vue";

const l = new Logger("App.vue");

@Component({
  components: {
    TopNavigationBar,
    Snackbars,
    HeaderSelect,
    CCFooter,
  },
  name: "App",
})
export default class App extends Vue {
  SM = SettingsModule;

  l = l;

  get darkmode() {
    return SettingsModule.darkmode;
  }

  get route() {
    return this.$route;
  }

  get sideBar() {
    return this.SM.sidebar && this.route.params.id;
  }

  set sideBar(val) {
    l.debug("sidebar closes");
  }

  get vuetifyDark() {
    return this.$vuetify.theme.dark;
  }

  beforeCreate() {
    const storage: IRootState = JSON.parse(window.localStorage.getItem("vuex") || "{}");
    if (storage) {
      if (storage.auth && storage.auth.token && storage.auth.user) {
        AuthModule.setState(storage.auth);
        NotificationsModule.setNeutral({
          message: `Welcome Back ${storage.auth.user.username}!`,
          icon: "mdi-island",
        });
      }
      if (storage.settings) {
        SettingsModule.setFromStorage(storage.settings);
      }
      // if (storage.localScan) LocalScanModule.setState(storage.localScan);
    }
  }

  created() {
    UsersModule.fetchUsers();
    TagsModule.fetchTags();
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "WOFF Fiduz-Regular";
  src: url("../public/fonts/fiduz/woff/Fiduz-Regular.woff");
}

@font-face {
  font-family: "WOFF Fiduz-Bold";
  src: url("../public/fonts/fiduz/woff/Fiduz-Bold.woff");
}

@font-face {
  font-family: "WOFF2 Fiduz-Regular";
  src: url("../public/fonts/fiduz/woff/Fiduz-Regular.woff2");
}

@font-face {
  font-family: "WOFF2 Fiduz-Bold";
  src: url("../public/fonts/fiduz/woff/Fiduz-Bold.woff2");
}

@font-face {
  font-family: "WOFF Fiduz-Italic";
  src: url("../public/fonts/fiduz/woff/Fiduz-Italic.woff");
}

@font-face {
  font-family: "WOFF Fiduz-Bold Italic";
  src: url("../public/fonts/fiduz/woff/Fiduz-BoldItalic.woff");
}

@font-face {
  font-family: "WOFF2 Fiduz-Italic";
  src: url("../public/fonts/fiduz/woff/Fiduz-Italic.woff2");
}

@font-face {
  font-family: "WOFF2 Fiduz-Bold Italic";
  src: url("../public/fonts/fiduz/woff/Fiduz-BoldItalic.woff2");
}
</style>
