// not yet in use
import { Module, VuexModule, MutationAction, Mutation, getModule } from "vuex-module-decorators";

import store from "@/store";
import { ApiUserLogin } from "@/static/apiModels";

import { login } from "@/api/django/auth";

import { NotificationsModule } from "./notifications";
import User from "../../models/User";

interface IAuthState {
  token: String | null;
  user: User | null;
  loading: boolean;
}

@Module({ dynamic: true, store, name: "auth" })
class Auth extends VuexModule implements IAuthState {
  token: String | null = null;

  user: User | null = null;

  loading: boolean = false;

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setState(state: IAuthState) {
    this.loading = state.loading;
    this.token = state.token;
    this.user = state.user;
  }

  get loggedIn(): boolean {
    return !!this.token;
  }

  get getUser(): User | null {
    return this.user;
  }

  @Mutation
  logOff() {
    this.token = null;
  }

  @MutationAction({ mutate: ["user", "token", "loading"] })
  async login(params: ApiUserLogin) {
    // @ts-ignore
    this.commit("setLoading", true);
    let response;
    try {
      response = await login(params);
    } catch (e: any) {
      NotificationsModule.setError({
        fullData: e.response.data,
        message: `could not log in: ${e.message}`,
        icon: "mdi-emoticon-sad",
      });
      return { user: this.user, token: this.token, loading: false };
    }
    if (response) {
      NotificationsModule.setNeutral({
        message: "successfully logged in",
        icon: "mdi-login",
      });
    }
    return {
      user: { username: params.username },
      token: response.data.token,
      loading: false,
    };
  }
}

const AuthModule = getModule(Auth);

export { AuthModule, IAuthState };
