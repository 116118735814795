/* eslint-disable no-console */
class Logger implements LoggerInterface {
  private pre: string = "";

  constructor(pre: string) {
    if (pre) {
      this.pre = `${pre} | `;
    }
  }

  private LOG(
    method: "debug" | "info" | "log" | "error" | "warn",
    message: string,
    stuff: any[],
  ): void {
    let args: any[] = [`${this.pre}${message}`];
    if (stuff.length > 0) {
      args = args.concat(stuff);
    }
    if (process.env.NODE_ENV === "development") {
      // @ts-ignore
      console[method].apply(null, args);
    }
  }

  log(message: string, ...stuff: any[]): void {
    this.LOG("log", message, stuff);
  }

  public info(message: string, ...stuff: any[]): void {
    this.LOG("info", message, stuff);
  }

  public debug(message: string, ...stuff: any[]): void {
    this.LOG("debug", message, stuff);
  }

  public error(message: string, ...stuff: any[]): void {
    this.LOG("error", message, stuff);
  }

  public warn(message: string, ...stuff: any[]): void {
    this.LOG("warn", message, stuff);
  }
}

export default Logger;
