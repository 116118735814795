/** using https://codepen.io/nourabusoud/pen/zpMVXB MIT - license */
<template>
  <VSwitch
    :data-test="`${componentName}-switch`"
    id="switchCheckbox"
    :checked="notifications"
    :value="notifications"
    @change="settingsModule.switchNotifications"
    type="checkbox"
  ></VSwitch>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SettingsModule } from "../store/modules/settings";
import Logger from "../services/LoggerService";

@Component({
  components: {},
  props: {
    label: String,
  },
  name: "SnackbarSwitch",
})
export default class SnackbarSwitch extends Vue {
  componentName = "SnackbarSwitch";

  l = new Logger("SnackBarSwitch.vue");

  get notifications() {
    return SettingsModule.notifications;
  }

  settingsModule = SettingsModule;
}
</script>
<style lang="scss" scoped></style>
