// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/fonts/fiduz/woff/Fiduz-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/fonts/fiduz/woff/Fiduz-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/fonts/fiduz/woff/Fiduz-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../public/fonts/fiduz/woff/Fiduz-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../public/fonts/fiduz/woff/Fiduz-Italic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../public/fonts/fiduz/woff/Fiduz-BoldItalic.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../public/fonts/fiduz/woff/Fiduz-Italic.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../public/fonts/fiduz/woff/Fiduz-BoldItalic.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "WOFF Fiduz-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "WOFF Fiduz-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: "WOFF2 Fiduz-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: "WOFF2 Fiduz-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
  font-family: "WOFF Fiduz-Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
  font-family: "WOFF Fiduz-Bold Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
  font-family: "WOFF2 Fiduz-Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
@font-face {
  font-family: "WOFF2 Fiduz-Bold Italic";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
