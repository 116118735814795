import { AxiosResponse } from "axios";
import api from ".";

export const getXMLofDocument = (id: string): Promise<AxiosResponse<any>> => {
  return api.get(`/restvle/dicts/_qdb-TEI-02/entries/${id}`);
};

export const getXMLofDocuments = (ids: string): Promise<AxiosResponse<any>> => {
  return api.get(`/restvle/dicts/_qdb-TEI-02/entries?page=1&pageSize=80&ids=${ids}`);
};
