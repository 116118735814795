<template>
  <div>
    <v-list
      v-if="plain === undefined"
      two-line
      dense
    >
      <v-list-item
        v-for="(item, index) of snackbars"
        :key="index"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.message }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ $t(new Date(item.time).toDateString()) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :data-test="`log-entry-${index}-open-in-new`"
            icon
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <div v-else>
      <div
        v-for="(item, index) of snackbars"
        :key="index"
      >
        <h3 class="ma-2">
          <v-layout justify-space-between>
            <v-icon class="mr-1">{{ item.icon }}</v-icon>
            <span>
              {{ item.message }}
            </span>
          </v-layout>
        </h3>
        <p>{{ $d(new Date(item.time), "long") }}</p>
      </div>
    </div>
    <v-btn
      data-test="log-download-button"
      block
      style="margin-top: 30px"
      @click="downloadLog()"
      >{{ $t("Log.download") }}</v-btn
    >
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { SNACK_BAR_TIME } from "../constants/index";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import NotificationLog from "../models/Notification";
import { NotificationsModule } from "../store/modules/notifications";
import { SettingsModule } from "../store/modules/settings";
import Logger from "../services/LoggerService";

@Component({
  components: { SnackbarSwitch },
  name: "Log",
})
export default class Snackbars extends Vue {
  @Prop(String) readonly limit: number | undefined;

  @Prop(String) readonly plain: boolean | undefined;

  l = new Logger("Log.vue");

  // for list grouping
  item: Number = 1;

  icons = {
    info: "mdi-information-variant",
    success: "check-bold",
    error: "danger",
  };

  get snackbars() {
    return Object.assign([], NotificationsModule.notificationsLimited(this.limit)).reverse();
  }

  get userSnackbar() {
    return SettingsModule.notifications;
  }

  get time() {
    return NotificationsModule.timeOfLastActivation;
  }

  get showSnackbar() {
    return NotificationsModule.showSnackbar;
  }

  downloadLog() {
    const element = document.createElement("a");
    // eslint-disable-next-line prefer-template
    element.setAttribute(
      "href",
      `data:text/plain;charset=utf-8,${encodeURIComponent(this.createLogOutput(this.snackbars))}`,
    );
    element.setAttribute("download", "log.txt");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  createLogOutput(log: any) {
    let output: string = "";
    log.forEach((entry: { message: string; time: string; type: string; fullData?: any }) => {
      output = `${output + entry.message}\n${entry.time}\n${entry.type}`;
      if (entry.fullData) {
        output = `${output}: ${entry.fullData.title}\nOutput: ${entry.fullData.detail}\nTrace: ${entry.fullData.trace}\n\n--------\n\n`;
      } else {
        output += "\n\n--------\n\n";
      }
    });
    return output;
  }

  static get darkmode() {
    return SettingsModule.darkmode;
  }

  timeSinceOpened = 0;

  calculateTimeSinceSnackbarUpdate() {
    setInterval(() => {
      if (this.time) {
        this.timeSinceOpened = Math.floor((Date.now() - this.time.getTime()) / 1000);
      }
    });
  }

  get values() {
    const millis = new Date().getTime();
    return this.snackbars.map(
      (s: NotificationLog) => millis - new Date(s.time).getTime() < SNACK_BAR_TIME,
    );
  }

  static showOneSnackbar(snackbar: NotificationLog): Boolean {
    const millis = Date.now();
    return millis - new Date(snackbar.time).getTime() < SNACK_BAR_TIME;
  }

  created() {
    this.calculateTimeSinceSnackbarUpdate();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
