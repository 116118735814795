import { render, staticRenderFns } from "./SnackbarSwitch.vue?vue&type=template&id=2c76e36e&scoped=true"
import script from "./SnackbarSwitch.vue?vue&type=script&lang=ts"
export * from "./SnackbarSwitch.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../layers/heroku_nodejs-pnpm-install/virtual/store/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.2.5_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c76e36e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/layers/heroku_nodejs-pnpm-install/virtual/store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c76e36e')) {
      api.createRecord('2c76e36e', component.options)
    } else {
      api.reload('2c76e36e', component.options)
    }
    module.hot.accept("./SnackbarSwitch.vue?vue&type=template&id=2c76e36e&scoped=true", function () {
      api.rerender('2c76e36e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SnackbarSwitch.vue"
export default component.exports