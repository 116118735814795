<template>
  <v-container
    fluid
    class="px-16"
  >
    <v-row
      justify="center"
      align="center"
    >
      <h2
        class="my-6"
        justify
      >
        Neue Aufgabe erstellen
      </h2>
    </v-row>
    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Step</h4>
      </v-col>
      <v-col cols="6">
        <v-select
          :data-test="`${componentName}-step-select`"
          v-model="step"
          :items="stepItems"
          item-text="name"
          item-value="value"
          label="Step auswählen"
          single-line
        ></v-select>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Status</h4>
      </v-col>
      <v-col cols="6">
        <v-select
          :data-test="`${componentName}-status-select`"
          v-model="status"
          :items="statusItems"
          item-text="name"
          item-value="value"
          label="Status auswählen"
          single-line
        ></v-select>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Zugeordnetes Lemma</h4>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          :data-test="`${componentName}-lemma-select`"
          v-model="selLemma"
          :items="lemmata"
          item-text="lemmatisierung"
          :loading="lemmaLoading"
          :search-input.sync="search"
          clearable
          label="Lemmatisierung auswählen"
          dense
          return-object
          filled
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Zugeordneter User</h4>
      </v-col>

      <v-col cols="6">
        <ApiSelect
          :data-test="`${componentName}-user-select`"
          itemText="username"
          label="user"
          type="users"
          @input="l.debug('as', $event)"
          :multiple="false"
          v-model="assignedUser"
          :filterValue="assignedUser"
        ></ApiSelect>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Fälligkeitsdatum</h4>
      </v-col>
      <v-col cols="6">
        <v-menu
          :data-test="`${componentName}-date-end`"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="deadline"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :data-test="`${componentName}-date-end-input`"
              v-model="deadline"
              label="Picker in menu"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="deadline"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              :data-test="`${componentName}-date-end-cancel`"
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :data-test="`${componentName}-date-end-save`"
              text
              color="primary"
              @click="$refs.menu.save(deadline)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="2">
        <h4>Kommentar</h4>
      </v-col>

      <v-col cols="6">
        <v-textarea
          :data-test="`${componentName}-comment-input`"
          name="input-7-1"
          filled
          label="Kommentar"
          v-model="comment"
          auto-grow
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="4">
        <v-btn
          :data-test="`${componentName}-cancel-create-task`"
          color="error"
          style="float: left"
          class="mr-4"
          @click="cancelCreate()"
        >
          <v-icon>mdi-cancel</v-icon>
          Abbrechen
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          :data-test="`${componentName}-create-task`"
          color="primary"
          style="float: right"
          @click="createTask()"
        >
          <v-icon>mdi-check</v-icon>
          Aufgabe anlegen
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { TaskModule } from "@/store/modules/task";

import { NotificationsModule } from "@/store/modules/notifications";
import { ApiTaskPost, ApiUser, SingleLemma } from "@/static/apiModels";

import { LemmaModule } from "@/store/modules/lemma";
import ApiSelect from "./ApiSelect.vue";

import { UsersModule } from "../store/modules/users";
import Logger from "../services/LoggerService";

@Component({
  components: {
    ApiSelect,
  },
  name: "TaskCreate",
})
export default class TaskCreate extends Vue {
  componentName = "TaskCreate";

  l = new Logger("TaskCreate.vue");

  step: string = "";

  status: string = "";

  selLemma: SingleLemma | null = null;

  menu = null;

  user: string = "";

  comment: string = "";

  deadline: string | null = null;

  search: string = "";

  assignedUser: ApiUser | null = null;

  _searchTimerId: any;

  TM = TaskModule;

  UM = UsersModule;

  LM = LemmaModule;

  get stepItems() {
    return this.TM.stepItems;
  }

  get statusItems() {
    return this.TM.statusItems;
  }

  get users() {
    return this.UM.users;
  }

  get lemmaLoading() {
    return this.LM.loading;
  }

  get lemmata() {
    return this.LM.searchLemma;
  }

  get currentTask() {
    return this.TM.currentTask;
  }

  @Watch("search")
  onSearchChange() {
    if (!this.search || this.search.length === 0 || this.search === "") return;
    // eslint-disable-next-line no-underscore-dangle
    clearTimeout(this._searchTimerId);
    // eslint-disable-next-line no-underscore-dangle
    this._searchTimerId = setTimeout(() => {
      LemmaModule.fetchSearchLemma({
        lemmatisierung: this.search,
        has__simplex: 2,
        page_size: 20,
      });
    }, 500);
  }

  createTask() {
    let missingFields: string[] = [];
    if (!this.status) missingFields = [...missingFields, ...["Status"]];
    if (!this.step) missingFields = [...missingFields, ...["Step"]];
    if (!this.assignedUser) missingFields = [...missingFields, ...["User"]];
    if (!this.selLemma) missingFields = [...missingFields, ...["Lemma"]];

    if (missingFields.length === 0) {
      const newTask: ApiTaskPost = {
        status: this.status,
        step: this.step,
        deadline: this.deadline? new Date(this.deadline) : null,
        user: this.assignedUser ? this.assignedUser.url : "",
        description: this.comment,
        finished_date: null,
        current: true,
        lemma: this.selLemma ? this.selLemma.url : "",
      };
      TaskModule.createTask(newTask).then(() => {
        if (this.currentTask) {
          this.$router.push(`/task/${this.currentTask.id}`);
        } else {
          this.$router.push(`/task`);
        }
      });
    } else {
      NotificationsModule.setError({
        message: `${missingFields.join(" | ")}, are required.`,
        icon: "mdi-alert-circle",
      });
    }
  }

  cancelCreate() {
    this.$router.push(`/task/`);
  }
}
</script>
