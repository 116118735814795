<template>
  <v-container
    fluid
    justify-center
  >
    <template v-if="!loading">
      <v-row>
        <v-col cols="12">
          <v-flex xs-12>
            <h1 style="float: left">{{ $route.params.id }} - {{ lemma.org }}</h1>
            <v-btn
              data-test="lemma-single-goto-edit-button"
              fab
              small
              style="float: left; margin-left: 20px; margin-top: 4px"
              @click="goToEdit($route.params.id)"
              color="primary"
            >
              <v-icon>mdi-border-color</v-icon>
            </v-btn>
            <v-dialog
              v-model="loadingDialog"
              v-if="!loadingDialog"
              hide-overlay
              persistent
              width="300"
            >
              <v-card
                color="primary"
                dark
              >
                <v-card-text>
                  Bitte warten
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialog"
              persistent
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  data-test="lemma-single-start-split-lemma-button"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="float: left; margin-left: 20px; margin-top: 4px"
                  @click="loadSplitLemma()"
                >
                  Lemma aufteilen
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Lemma aufteilen</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-form v-model="valid">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            data-test="lemma-single-split-lemma-org-input"
                            label="Original"
                            v-model="splitLemma.org"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            data-test="lemma-single-split-lemma-norm-input"
                            v-model="splitLemma.norm"
                            label="Normalisierung"
                            hint="example of helper text only on focus"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-text-field
                            data-test="lemma-single-split-lemma-lemmatisierung-input"
                            v-model="splitLemma.lemmatisierung"
                            label="Lemmatisierung"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-text-field
                            data-test="lemma-single-split-lemma-count-input"
                            v-model="splitLemma.count"
                            type="number"
                            label="Belegzahl"
                            :rules="countRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          Aktuelle Belegzahl von Lemma
                          {{ lemma.lemmatisierung }}:
                          {{ lemma.count - splitLemma.count }}
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            data-test="lemma-single-split-lemma-filename-input"
                            v-model="splitLemma.filename"
                            label="Filename"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <ApiSelect
                            data-test="lemma-single-split-lemma-assigned-user-select"
                            itemText="username"
                            label="user"
                            type="users"
                            @input="l.debug('as', $event)"
                            :multiple="false"
                            v-model="assignedUser"
                            :filterValue="assignedUser"
                          ></ApiSelect>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            data-test="lemma-single-split-lemma-comment-input"
                            v-model="splitLemma.comment"
                            label="Kommentar"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    data-test="lemma-single-split-lemma-cancel-btn"
                    color="blue darken-1"
                    text
                    @click="
                      dialog = false;
                      valid = true;
                    "
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    data-test="lemma-single-split-lemma-submit-btn"
                    color="blue darken-1"
                    text
                    @click="createSplitLemma()"
                  >
                    Lemma anlegen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-flex
            xs-12
            md-6
          >
            <v-flex xs-12> Lemma Normierung: {{ lemma.norm }} </v-flex>
            <v-flex xs-12> Lemma Org: {{ lemma.org }} </v-flex>
            <v-flex xs-12> Lemmatisierung: {{ lemma.lemmatisierung }} </v-flex>
            <v-flex xs-12> Lemma filename: {{ lemma.filename }} </v-flex>
            <v-flex xs-12> Lemma Kommentar: {{ lemma.comment }} </v-flex>
            <v-flex xs-12> Lemma POS: {{ lemma.pos }} </v-flex>
            <v-flex xs-12> Lemma Vorschlag: {{ lemma.suggestion }} </v-flex>
            <v-flex xs-12>
              Zugewiesener User:
              {{ lemma.assigned_task ? lemma.assigned_task.user_name : "Keinem User zugeordnet" }}
            </v-flex>
            <v-flex xs-12>
              Artikel Lemma:
              {{ lemma.simplex ? lemma.art_lemmatisierung : "oberstes Lemma" }}
              <template v-if="lemma.simplex">
                <v-icon
                  data-test="lemma-single-goto-composita-button"
                  small
                  class="mr-2"
                  @click="goToKomp(lemma.simplex)"
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-flex>
            <v-flex xs-12> Beleg Anzahl: {{ lemma.count }} </v-flex>
          </v-flex>
        </v-col>
      </v-row>
      <v-row>
        <v-flex
          xs-12
          md-12
        >
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-flex xs-9>
                <h2 style="float: left">Zugewiesene Collections</h2>
              </v-flex></v-col
            ></v-row
          >
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-flex xs-9>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-left">Beschreibung</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :data-test="`lemma-single-collection-${item.id}-row`"
                        v-for="item in collections.results"
                        :key="item.title"
                        @click="goToCollection(item.id)"
                      >
                        <td>{{ item.title }}</td>
                        <td>{{ item.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-flex>
            </v-col>
          </v-row>
        </v-flex>
      </v-row>
      <v-spacer></v-spacer>
      <v-row no-gutters>
        <v-flex
          xs-12
          md-12
        >
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-flex xs-9>
                <h2 style="float: left">Bearbeitet in</h2>
              </v-flex>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-spacer></v-spacer>
              <v-flex xs-9>
                <v-data-table
                  data-test="lemma-single-task-table"
                  :loading="tmLoading"
                  :headers="headers"
                  :items="tasks ? tasks.results : []"
                  @click:row="goToTask($event.id)"
                  :sort-by.sync="paginationTask.sortBy"
                  :sort-desc.sync="paginationTask.desc"
                  :footer-props="{
                    'items-per-page-options': itemsPerPageItems,
                  }"
                  :server-items-length="tasks ? tasks.count : 0"
                  :options.sync="paginationTask"
                  fixed-header
                >
                  <template v-slot:[`item.deadline`]="{ item }">
                    {{
                      item.deadline
                        ? new Date(item.deadline).toDateString()
                        : "Derzeit keine Deadline vorhanden"
                    }}
                  </template>
                  <template v-slot:[`item.last_edited`]="{ item }">
                    {{ new Date(item.last_edited).toDateString() }}
                    {{ new Date(item.last_edited).getHours() }}:{{
                      new Date(item.last_edited).getMinutes()
                    }}
                  </template>
                </v-data-table>
              </v-flex>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-flex>
      </v-row>
      <v-row
        no-gutters
        v-if="lemma.simplex === null"
      >
        <v-flex
          xs-12
          md-12
        >
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-flex xs-9>
                <h2 style="float: left">Zugeordnete Komposita</h2>
              </v-flex>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="9"
              offset-md="1"
            >
              <v-spacer></v-spacer>
              <v-flex xs-9>
                <v-data-table
                  data-test="lemma-single-lemma-table"
                  :loading="lemmataLoading"
                  :headers="headersLemma"
                  :items="lemmata.results"
                  @click:row="goToKomp($event.id)"
                  fixed-header
                  :sort-by.sync="pagination.sortBy"
                  :sort-desc.sync="pagination.desc"
                  :footer-props="{
                    'items-per-page-options': itemsPerPageItems,
                  }"
                  :server-items-length="lemmata ? lemmata.count : 0"
                  :options.sync="pagination"
                >
                  <template v-slot:[`item.deadline`]="{ item }">
                    {{
                      item.deadline
                        ? new Date(item.deadline).toDateString()
                        : "Derzeit keine Deadline vorhanden"
                    }}
                  </template>
                  <template v-slot:[`item.last_edited`]="{ item }">
                    {{ new Date(item.last_edited).toDateString() }}
                    {{ new Date(item.last_edited).getHours() }}:{{
                      new Date(item.last_edited).getMinutes()
                    }}
                  </template>
                </v-data-table>
              </v-flex>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-flex>
      </v-row>
    </template>
    <template v-else>
      <CatSpinner></CatSpinner>
    </template>
  </v-container>
</template>

<script lang="ts">
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */

import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { LemmaModule } from "@/store/modules/lemma";
import { CollectionsModule } from "@/store/modules/collections";
import { NotificationsModule } from "@/store/modules/notifications";
import { TaskModule } from "@/store/modules/task";
import { AuthModule } from "@/store/modules/auth";
import { UsersModule } from "@/store/modules/users";
import { ApiLemmaPage, ApiPostLemma, ApiUser } from "@/static/apiModels";
import Logger from "../services/LoggerService";
import CatSpinner from "./CatSpinner.vue";
import ApiSelect from "./ApiSelect.vue";

@Component({
  components: {
    CatSpinner,
    ApiSelect,
  },
  name: "LemmaSingleView",
})
export default class LemmaSingleView extends Vue {
  l = new Logger("LemmaView.vue");

  TM = TaskModule;

  LM = LemmaModule;

  AM = AuthModule;

  UM = UsersModule;

  itemsPerPageItems = [5, 10, 25, 50, 100, 500, 1000, 2000];

  dialog: boolean = false;

  loadingDialog: boolean = false;

  assignedUser: ApiUser | null = null;

  pagination: any = {
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [false],
  };

  paginationTask: any = {
    itemsPerPage: 25,
    sortBy: ["finished_date"],
    sortDesc: [true],
  };

  splitLemma: ApiPostLemma = {
    norm: "",
    org: "",
    count: 0,
    filename: "",
    comment: "",
    simplex: "",
    lemmatisierung: "",
    pos: "",
    suggestion: "",
  };

  valid: boolean = true;

  countRules = [
    (v: any) => !!v || "Belegzahl zu groß!",
    (v: any) => (this.lemma ? this.lemma.count : 0) - v >= 0 || "Belegzahl zu groß!",
  ];

  headers = [
    { text: "ID", value: "id" },
    { text: "Lemma", value: "lemma_name", sortable: true },
    { text: "Fällig", value: "deadline", sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Step", value: "step", sortable: true },
    { text: "Zuletzt bearbeitet", value: "last_edited", sortable: true },
    { text: "Assigned User", value: "user_name", sortable: true },
  ];

  headersLemma = [
    { text: "Original", value: "org", sortable: true },
    { text: "Norm", value: "norm", sortable: true },
    { text: "Lemmatisierung", value: "lemmatisierung", sortable: true },
    { text: "Count", value: "count", sortable: true },
    { text: "Description", value: "comment", sortable: false },
    // { text: 'Assigned User', value: 'bearbeiter', sortable: true },
  ];

  currentPage: number = 1;

  currentPageTask: number = 1;

  goToEdit(id: number) {
    this.$router.push(`/lemma/${id.toString()}/edit`);
  }

  goToCollection(id: number) {
    this.$router.push(`/collections/${id.toString()}`);
  }

  get tmLoading() {
    return TaskModule.loading;
  }

  get tasks() {
    return TaskModule.tasks ? TaskModule.tasks : [];
  }

  get users() {
    return this.UM.users;
  }

  get lemmata() {
    return LemmaModule.lemmata ? LemmaModule.lemmata : ({} as ApiLemmaPage);
  }

  get lemmataLoading() {
    return LemmaModule.loading;
  }

  get lemma() {
    return LemmaModule.currentLemma;
  }

  get filters() {
    return CollectionsModule.filters;
  }

  get collections() {
    return CollectionsModule.collections;
  }

  get id() {
    return +this.$route.params.id;
  }

  get loading() {
    return LemmaModule.singleLoading || CollectionsModule.loading;
  }

  goToTask(id: number) {
    this.$router.push(`/task/${id.toString()}`);
  }

  goToLemma(id: number) {
    this.l.log("goto Lemma", id);
    this.fetchData(id);
  }

  goToKomp(url: string) {
    // go to a another Lemma based on its URL
    // Extract the ID from the URL
    let idLemma = this.LM.byUniqueURL(url);
    if (!Number.isNaN(+url)) {
      idLemma = url;
    }
    if (idLemma === "") {
      return;
    }
    this.l.log("gotolemma", idLemma);
    this.$router.replace({
      path: `/lemma/${idLemma}`,
    });
  }

  // Used when the user wants to split a Lemma in two parts
  // This function only preloads the Data into an Object
  loadSplitLemma() {
    if (this.lemma) {
      this.splitLemma.norm = this.lemma.norm;
      this.splitLemma.org = this.lemma.org;
      this.splitLemma.count = 0;
      this.splitLemma.filename = this.lemma.filename;
      this.splitLemma.lemmatisierung = this.lemma.lemmatisierung;
      if (this.lemma.assigned_task) {
        const { user } = this.lemma.assigned_task;
        const curr = this.users.find(
          // eslint-disable-next-line func-names
          function (value) {
            return value.url === user;
          },
        );
        this.assignedUser = curr || null;
      }
    }
  }

  // Called when the User submits the SplitLemma Form
  createSplitLemma() {
    if (this.lemma && this.lemma.count - this.splitLemma.count < 0) {
      NotificationsModule.setError({
        // @ts-ignore
        message: "Belegzahl zu groß!",
        icon: "mdi-medical-bag",
      });
      return;
    }
    this.dialog = false;
    this.loadingDialog = true;
    // Edit the old Lemma
    if (this.lemma) {
      const lemmaUp = {
        norm: this.lemma.norm,
        org: this.lemma.org,
        lemmatisierung: this.lemma.lemmatisierung,
        filename: this.lemma.filename,
        comment: this.lemma.comment,
        simplex: this.lemma.simplex,
        count: this.lemma.count - this.splitLemma.count,
      };
      const num = Number(this.$route.params.id);
      LemmaModule.editLemma({ id: num, lemma: lemmaUp });
    }

    // Create the new Lemma
    // Create a new task and assign the selected User to the new Lemma
    LemmaModule.createLemma(this.splitLemma).then(() => {
      if (this.assignedUser) {
        const newTask = {
          user: this.assignedUser.url,
          step: "ZUGEWIESEN",
          status: "DRAFT",
          description: "",
          deadline: null,
          current: true,
          lemma: this.lemma ? this.lemma.url : "",
          finished_date: null,
        };
        TaskModule.createTask(newTask).then(() => {
          this.loadingDialog = false;
          if (this.lemma) {
            const num = Number(this.lemma.id);
            this.$router.push(`/lemma/${num}`);
          }
        });
      }
    });
  }

  @Watch("$route", { immediate: true, deep: true })
  onIdChange(val: any) {
    this.l.log("ID updated", val);
    this.fetchData(+this.$route.params.id);
  }

  @Watch("pagination", { deep: true })
  onPaginationChange(val: any) {
    if (!this.tasks || val.page === undefined) return;
    this.l.log("Switching page to val", val.page);
    if (val.page > this.currentPage) {
      this.LM.fetchNextLemmaPage();
      this.currentPage += 1;
    } else if (val.page < this.currentPage) {
      this.LM.fetchPreviousLemmaPage();
      this.currentPage -= 1;
    } else {
      this.LM.fetchLemmata({
        page_size: this.pagination.itemsPerPage,
        page: this.pagination.page,
        ordering: `${this.pagination.sortDesc[0] ? "-" : ""}${this.pagination.sortBy}`,
        simplex: this.lemma ? this.lemma.id : +this.$route.params.id,
      });
    }
  }

  @Watch("paginationTask", { deep: true })
  onPaginationTaskChange(val: any) {
    if (!this.tasks || val.page === undefined) return;
    this.l.log("Switching page to val", val.page);
    if (val.page > this.currentPageTask) {
      this.TM.fetchNextTaskPage();
      this.currentPageTask += 1;
    } else if (val.page < this.currentPageTask) {
      this.TM.fetchPreviousTaskPage();
      this.currentPageTask -= 1;
    } else {
      let lemmaID: string = "";
      if (this.lemma) {
        lemmaID =
          this.lemma.simplex === null
            ? String(this.lemma.id)
            : this.LM.byUniqueURL(this.lemma.simplex);
      }
      this.TM.fetchTasks({
        page_size: this.paginationTask.itemsPerPage,
        page: this.paginationTask.page,
        ordering: `${this.paginationTask.sortDesc[0] ? "-" : ""}${this.paginationTask.sortBy}`,
        lemma__id: lemmaID,
      });
    }
  }

  // Fetches Data from the DB
  fetchData(id: number) {
    CollectionsModule.setFilters({});
    CollectionsModule.fetchCollections({ lemma_id: id });
    LemmaModule.fetchCurrentLemmata(id).then(() => {
      let lemmaID: string = "";
      if (this.lemma) {
        lemmaID =
          this.lemma.simplex === null
            ? String(this.lemma.id)
            : this.LM.byUniqueURL(this.lemma.simplex);
      }
      TaskModule.fetchTasks({
        lemma__id: lemmaID,
      });
      if (this.lemma && this.lemma.simplex === null) {
        LemmaModule.fetchLemmata({ simplex: this.lemma.id });
      }
    });
  }

  created() {
    this.fetchData(+this.$route.params.id);
  }
}
</script>
