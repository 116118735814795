<template>
  <tr v-if="checkedValue !== 'Nicht vorhanden'">
    <td v-if="important">
      <b>{{ checkedLabel }} </b>
    </td>
    <td v-else>{{ checkedLabel }}</td>
    <v-tooltip
      v-if="useCleanedValue"
      top
    >
      <template v-slot:activator="{ on }">
        <td
          v-on="on"
          :style="[largeFont ? { 'font-size': ' var(--medium-info-font-size)' } : '']"
        >
          {{ cleanedValue }}
        </td>
      </template>
      <span>{{ checkedValue }}</span>
    </v-tooltip>
    <td
      v-else
      :style="[largeFont ? { 'font-size': ' var(--medium-info-font-size)' } : '']"
    >
      {{ checkedValue }}
    </td>
  </tr>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "XMLInfoRow",
})
export default class XMLInfoRow extends Vue {
  @Prop() value: string | number | Array<string> | Array<number> | undefined;

  @Prop() cleanValue: string | undefined;

  @Prop() label: string | undefined;

  @Prop(Boolean) important!: Boolean;

  @Prop(Boolean) useCleanedValue!: Boolean;

  @Prop(Boolean) largeFont!: Boolean;

  get checkedLabel() {
    return this.label ? this.label : "Label Not set";
  }

  get checkedValue() {
    return this.checkUndefined(this.value);
  }

  get cleanedValue() {
    return this.cleanValue || this.removeNormalisedSpelling(String(this.checkedValue));
  }

  checkUndefined(val: any) {
    return val || "Nicht vorhanden";
  }

  removeNormalisedSpelling(input: String): String {
    let cleanedData = input;
    if (input.includes(",")) {
      cleanedData = input.substring(0, input.indexOf(","));
    }
    return cleanedData;
  }
}
</script>
