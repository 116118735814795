<template>
  <div class="mb-3 mt-1 mx-auto text-center">
    <div
      class="mt-3 mb-1 mx-2"
      style="font-size: 0.75em"
    >
      <b>Versions:</b><br />
      <p class="my-1 mr-2 d-inline">Frontend: {{ frontendVersion }} - {{ branch }}</p>
      <p class="my-1 mr-2 d-inline">Documents DB: {{ djangoVersion }}</p>
      <p class="my-1 mr-2 d-inline">Elastic Search: {{ esVersion }}</p>
    </div>
    <v-btn
      data-test="gitlab-link"
      text
      href="https://gitlab.oeaw.ac.at/acdh-ch/wboe/collectioncat"
      target="_blank"
    >
      <span class="mr-2">gitlab</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Logger from "@/services/LoggerService";
import { getDjangoVersion } from "../api/django/version";
import { getESVersion } from "../api/elastic/version";

@Component({
  // if you use components add them here
  components: {},
  name: "CCFooter",
})
export default class CCFooter extends Vue {
  logger = new Logger("CCFooter");

  get frontendVersion() {
    return process.env.VUE_APP_VERSION || "version";
  }

  get commitHash() {
    return process.env.VUE_APP_COMMIT_HASH || "commit hash";
  }

  get branch() {
    return process.env.VUE_APP_BRANCH || "branch";
  }

  get ElasticSearchVersion() {
    return "ESVersion";
  }

  get djangoVersion() {
    return this.documentDBVersion;
  }

  mounted() {
    this.loadDatabaseVersions();
  }

  async loadDatabaseVersions() {
    this.documentDBVersion = (await getDjangoVersion()).data.version.sw;
    this.esVersion = (await getESVersion()).data.version.number;
  }

  documentDBVersion: String = "DocumentDB Version";

  esVersion: String = "ElasticSearch Version";
}
</script>
