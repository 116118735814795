<template>
  <v-dialog
    v-model="dialog"
    width="1000"
  >
    <v-card>
      <v-card-title>
        <h2>Tags</h2>
      </v-card-title>
      <v-layout>
        <v-card-text>
          <v-text-field
            data-test="tag-select-search"
            v-model="search"
            clearable
            :placeholder="$t('TagSelect.search')"
          ></v-text-field>
          <p>
            {{
              $tc("TagSelect.only_select_n_items", slotLength, {
                tags: slotLength,
              })
            }}
          </p>
          <v-layout row>
            <ul>
              <div
                v-for="tag in selection"
                :key="`add_to_sel_${tag.url}`"
              >
                <v-chip
                  :data-test="`tag-select-deselect-${tag.url}`"
                  @click="deselectTag(tag)"
                  small
                  dense
                >
                  <v-icon>mdi-close</v-icon>
                </v-chip>
              </div>
            </ul>

            <ul>
              <div
                v-for="tag in selection"
                :key="tag.url"
              >
                <v-chip
                  small
                  dense
                  >{{ getSlotKeyForTag(tag) }}
                </v-chip>
              </div>
            </ul>
            <ul>
              <div
                v-for="tag in selection"
                :key="tag.url"
              >
                <TagChip :url="tag.url"></TagChip>
              </div>
            </ul>
            <ul>
              <div
                v-for="tag in selection"
                :key="`add_to_sel_${tag.url}`"
              >
                <v-chip
                  :data-test="`tag-select-add-${tag.url}`"
                  @click="addToSelection(tag)"
                  small
                  dense
                >
                  <v-icon>mdi-checkbox-marked-outline</v-icon><v-icon>mdi-tag-plus</v-icon>
                  {{ $t("TagSelect.add_to_selection") }}
                </v-chip>
              </div>
            </ul>

            <ul>
              <div
                v-for="tag in selection"
                :key="`add_to_sel_${tag.url}`"
              >
                <v-chip
                  :data-test="`tag-select-remove-${tag.url}`"
                  @click="removeFromSelection(tag)"
                  small
                  dense
                >
                  <v-icon>mdi-checkbox-marked-outline</v-icon><v-icon>mdi-tag-minus</v-icon>
                  {{ $t("TagSelect.remove_from_selection") }}
                </v-chip>
              </div>
            </ul>
          </v-layout>
        </v-card-text>
      </v-layout>
      <v-data-table
        :headers="headers"
        :items="localTags"
        must-sort
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems,
        }"
        :options.sync="pagination"
        @click:row="onSelectionRowClick(item)"
      >
        <!--
            v-slot:item -> acces the slot prop "item" from the v-data-table component
            item : tag -> rename property "item" to tag for usagae within the code. This is just for beauty reasons
        -->
        <template v-slot:item="{ item: tag }">
          <tr
            :data-test="`tag-select-row-${tag.name}`"
            @click="onSelectionRowClick(tag)"
          >
            <td>
              <v-checkbox :input-value="isTagSelected(tag)"></v-checkbox>
            </td>
            <td>{{ tag.name }}</td>
            <td>{{ tag.emoji }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Logger from "../services/LoggerService";
import TagChip from "./TagChip.vue";
import TagList from "./TagList.vue";
import { ApiTag } from "../static/apiModels";
import { TagsModule } from "../store/modules/tags";
import { NotificationsModule } from "../store/modules/notifications";
import { CollectionsModule } from "../store/modules/collections";

@Component({
  components: { TagChip, TagList, draggable },
  name: "TagSelect",
})
export default class TagSelect extends Vue {
  l = new Logger("TagSelect.vue");

  localTags: ApiTag[] | null = null;

  TM = TagsModule;

  rowsPerPageItems = [5, 10, 25, 50];

  pagination = {
    rowsPerPage: 25,
  };

  headers = [
    { text: "", value: "checkmark" },
    { text: "Name", value: "name" },
    { text: "emoji", value: "emoji" },
  ];

  selection: ApiTag[] = [];

  model = null;

  search: string = "";

  // --------------------------------------------------------------------------------------------------------------------------------------------------
  //   Utility:
  // --------------------------------------------------------------------------------------------------------------------------------------------------

  get slots() {
    return this.TM.getSlots;
  }

  set slots(newSlots) {
    // #SlotOrdering
    const SLOTS = Object.assign([], this.slots);
    SLOTS.forEach((slot, index) => {
      // @ts-ignore
      SLOTS[index].tag = this.selection[index];
    });
    this.TM.setSlots(SLOTS);
  }

  isTagSelected(tag: any): boolean {
    if (this.selection.includes(tag)) return true;
    return false;
  }

  get slotLength() {
    return this.TM.getSlots.length;
  }

  get dialog() {
    return this.TM.selectionDialog;
  }

  set dialog(val: boolean) {
    this.TM.setDialog(false);
  }

  get selectionUrls() {
    return this.selection.map((tag) => tag.url);
  }

  get tags() {
    return this.TM.getTags;
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------------
  //   Methods:
  // --------------------------------------------------------------------------------------------------------------------------------------------------

  @Watch("slots", { deep: true })
  onSlotChange() {
    this.syncSelectionWithTagSlots();
  }

  mounted() {
    this.syncSelectionWithTagSlots();
  }

  onSelectionRowClick(item: ApiTag) {
    if (this.selection.includes(item)) {
      this.deselectTag(item);
    } else {
      // #SlotOrdering Temp
      this.selectTag(item);
    }
  }

  selectTag(tag: ApiTag) {
    if (this.selection.length >= this.slotLength) {
      NotificationsModule.setError({
        message: `please select only ${this.TM.getSlots.length} tags`,
        icon: "mdi-ambulance",
      });
      return;
    }

    // Check that tag hasn't already been selected

    const tempSlots = this.slots;
    for (let index = 0; index < tempSlots.length; index += 1) {
      const tagSlot = tempSlots[index];
      if (tagSlot.tag === null) {
        tempSlots[index].tag = tag;
        break;
      }
    }

    this.TM.setSlots(tempSlots);
  }

  deselectTag(tag: ApiTag) {
    this.TM.removeTagFromSlots(tag);
  }

  getSlotKeyForTag(tag: ApiTag): string {
    for (let index = 0; index < this.slots.length; index += 1) {
      const tagSlot = this.slots[index];
      if (tagSlot.tag !== null) {
        if (tagSlot.tag === tag) return tagSlot.key;
      }
    }
    return "Missing Key for this Tag.";
  }

  @Watch("search")
  onSearchChange() {
    this.localTags = this.tags.filter((tag) => {
      return tag.name.includes(this.search);
    });
  }

  addToSelection(tag: ApiTag) {
    CollectionsModule.addTagToSelection({ tag });
  }

  removeFromSelection(tag: ApiTag) {
    CollectionsModule.removeTagFromSelection({ tag });
  }

  @Watch("tags")
  onTagsChange() {
    this.localTags = Object.assign([], this.tags);
  }

  created() {
    this.localTags = Object.assign([], this.tags);
  }

  /**
   * Fetches the currently occupied slots and update the selection accordingly
   */
  syncSelectionWithTagSlots(): void {
    const tagSlots = this.TM.getSlots;
    const currSelectedTags: ApiTag[] = [];
    tagSlots.forEach((tagSlot) => {
      if (tagSlot.tag) currSelectedTags.push(tagSlot.tag);
    });
    this.selection = currSelectedTags;
  }
}
</script>

<style scoped lang="scss"></style>
