/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import store from "@/store";

import { editLemma, getLemma, getSingleLemma, postLemma } from "@/api/django/lemma";
import Logger from "@/services/LoggerService";

import api from "@/api/django";

import { NotificationsModule } from "./notifications";
import {
  ApiLemmaPage,
  ApiLemmaResponse,
  ApiLemmaRequest,
  SingleLemma,
  ApiLemma,
} from "../../static/apiModels";

export interface ILemmaState {
  lemmata: ApiLemmaPage | null;
  currentLemma: ApiLemmaResponse | null;
  loading: boolean;
  singleLoading: boolean;
  filters: ApiLemmaRequest;
}

const l = new Logger("lemmaModule.vue");

// This is a Module Class for the Lemma
// Contains all the required functions to perform actions with the Lemma on the DB
// Also acts a store to save the Data of the Lemma
@Module({
  dynamic: true,
  store,
  name: "lemma",
  namespaced: true,
})
class Lemma extends VuexModule implements ILemmaState {
  // Current Lemma, only a single one
  currentLemma: SingleLemma | null = null;

  // Lemma in a list organized in a paged manner
  // Data is in results
  lemmata: ApiLemmaPage | null = null;

  // Array of single Lemma
  // Used for the serach Terms of the autocompletes
  searchLemma: SingleLemma[] = [];

  // Boolean Variable for the loading
  loading: boolean = false;

  filters: ApiLemmaRequest = {};

  // Single loading var if currentLemma is loading
  singleLoading: boolean = false;

  get getSingleLoading(): boolean {
    return this.singleLoading;
  }

  get getCurrentLemma() {
    return this.currentLemma;
  }

  get getLemmata() {
    return this.lemmata;
  }

  // extracts the ID fron a given URL
  // assumes that the ID is at the end of the string
  // /<id>/
  get byUniqueURL() {
    return (url: string) => {
      const regexp = /\/(\d+)\/$/g;
      const id = regexp.exec(url);
      if (id === null) {
        return "";
      }
      return id[1];
    };
  }

  @Mutation
  setserachLemma(val: SingleLemma[]) {
    this.searchLemma = val;
  }

  @Mutation
  setCurrentLemma(lemma: SingleLemma | null) {
    this.currentLemma = lemma;
  }

  @Mutation
  setFilters(filters: ApiLemmaRequest) {
    this.filters = filters;
  }

  @Mutation
  setSingleLoading(loading: boolean) {
    this.singleLoading = loading;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  appendLemma(lemma: SingleLemma) {
    this.searchLemma.push(lemma);
  }

  @MutationAction({ mutate: ["lemmata", "loading"] })
  async fetchLemmata(params?: ApiLemmaRequest) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiLemmaRequest = params ? { ...params } : {};
    const response = await getLemma(PARAMS);
    l.log("Get lemmata from DB");
    l.log(response.data);
    // TODO set simplex from URL to norm
    return { lemmata: response.data, loading: false };
  }

  @MutationAction({ mutate: ["searchLemma", "loading"] })
  async fetchSearchLemma(params?: ApiLemmaRequest) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiLemmaRequest = params ? { ...params } : {};
    const response = await getLemma(PARAMS);
    if (this.currentLemma) response.data.push(this.currentLemma);

    return { searchLemma: response.data.results, loading: false };
  }

  @MutationAction({ mutate: ["currentLemma", "singleLoading"] })
  async fetchCurrentLemmata(id: number) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const response = await getSingleLemma(id);
    // TODO get also collections for this lemma
    return { currentLemma: response.data, singleLoading: false };
  }

  @MutationAction({ mutate: ["lemmata", "loading"] })
  async fetchLemmaByUrl(url: string) {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await api.get(url);
    return { lemmata: response.data, loading: false };
  }

  @MutationAction({ mutate: ["currentLemma", "loading"] })
  async fetchSingleLemmaByUrl(url: string) {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await api.get(url);
    return { currentLemma: response.data, loading: false };
  }

  @Action
  async fetchNextLemmaPage() {
    if (!this.lemmata) {
      NotificationsModule.setError({
        message: "no lemmata fetched so no next lemmata fetchable",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.lemmata.next) {
      // @ts-ignore
      this.context.dispatch("fetchLemmaByUrl", this.lemmata.next);
    }
  }

  @Action
  async fetchPreviousLemmaPage() {
    if (!this.lemmata) {
      NotificationsModule.setError({
        message: "There is not a previous page",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.lemmata.previous) {
      // @ts-ignore
      this.context.dispatch("fetchLemmaByUrl", this.lemmata.previous);
    }
  }

  @MutationAction({ mutate: ["currentLemma", "singleLoading"] })
  async createLemma(newLemma: any) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const response = await postLemma(newLemma);
    if (response.data) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully created Lemma ${response.data.lemmatisierung}`,
        icon: "mdi-check-bold",
      });
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be signed in in order to split the lemma",
        icon: "mdi-medical-bag",
      });
    }

    return { currentLemma: response.data, singleLoading: false };
  }

  @Action
  async editLemma(putLemma: any) {
    // this.context.commit('setLoading', true);
    const response = await editLemma(putLemma.lemma, putLemma.id);
    if (response.data) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully changed ${putLemma.lemma.org}`,
        icon: "mdi-check-bold",
      });
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be signed in in order to edit it",
        icon: "mdi-medical-bag",
      });
    }
    this.context.commit("setLoading", false);
  }

  @Action
  async fetchSimplexLemma(lemma: ApiLemma[]) {
    const simplex: string[] = [];
    lemma.forEach((element) => {
      simplex.push(element.simplex);
    });

    // filter the array for unique entries of simplex
    simplex.filter((v, i, a) => a.indexOf(v) === i);

    const simplexLemma: string[] = [];

    simplex.forEach((element) => {
      this.context.dispatch("fetchSingleLemmaByUrl", element);
      const currentSimplex = this.currentLemma ? this.currentLemma.norm : "null";
      simplexLemma.push(currentSimplex);
    });
  }
}

export const LemmaModule = getModule(Lemma);
