<template>
  <v-container fluid>
    <v-layout>
      <v-row>
        <v-col cols="12">
          <h1 style="float: left; margin-top: 50px">Aufgabe</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-flex xs-12>
            <v-btn
              :data-test="`${componentName}-goto-create-task`"
              style="float: left; margin-left: 20px; margin-top: 55px"
              @click="goToCreate()"
              color="primary"
            >
              Erstelle neue Aufgabe
            </v-btn>
            <v-btn
              :data-test="`${componentName}-goto-create-report`"
              style="float: left; margin-left: 20px; margin-top: 55px"
              @click="goToCreateReport()"
              color="primary"
            >
              Erstelle neuen Report
            </v-btn>
            <v-btn
              :data-test="`${componentName}-show-filters`"
              style="float: left; margin-left: 20px; margin-top: 55px"
              @click="showFilters()"
              color="primary"
            >
              Filteroptionen <span v-if="!boolFilters">einblenden</span
              ><span v-if="boolFilters"> ausblenden</span>
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout v-if="boolFilters">
      <v-container class="pt-8">
        <v-row>
          <v-col>
            <h4 class="mb-3">User auswählen</h4>
            <ApiSelect
              :data-test="`${componentName}-filters-assigned-user`"
              itemText="username"
              label="user"
              type="users"
              @input="l.debug('as', $event)"
              v-on:keyup.enter="onSearchSubmitted()"
              @click:append-outer="onSearchSubmitted()"
              :multiple="false"
              v-model="assignedUser"
              :filterValue="assignedUser"
            ></ApiSelect>
          </v-col>
          <v-col>
            <h4 class="mb-0 pb-0">Lemma auswählen</h4>
            <v-autocomplete
              :data-test="`${componentName}-filters-lemma`"
              class="mt-0 pt-0"
              :items="searchLemma"
              item-text="lemmatisierung"
              item-value="lemmatisierung"
              full-width
              :search-input.sync="search"
              @update:search-input="debouncedOnSearchSubmitted()"
              outline
              hide-no-data
              hide-selected
              placeholder="Nach Artikel Lemma suchen"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col>
            <h4 class="mb-3">Nach Step filtern</h4>
            <v-select
              :data-test="`${componentName}-filters-step`"
              v-model="step"
              :items="stepItems"
              item-text="name"
              item-value="value"
              label="Select Step"
              v-on:change="onSearchSubmitted"
              single-line
              clearable
            ></v-select>
          </v-col>
          <v-col>
            <h4 class="mb-3">Nach Status filtern</h4>
            <v-select
              :data-test="`${componentName}-filters-status`"
              v-model="status"
              :items="statusItems"
              item-text="name"
              item-value="value"
              label="Select Status"
              v-on:change="onSearchSubmitted"
              single-line
              clearable
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
    <v-container fluid>
      <template v-if="AM.loggedIn">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
            v-for="item in tabItems"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
      <template v-if="!AM.loggedIn">
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab
            v-for="item in tabItemsOff"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
      <v-card flat>
        <v-card-text>
          <v-data-table
            :data-test="`${componentName}-task-table`"
            :loading="loading"
            :headers="headers"
            :items="tasks.results"
            :search="search"
            :footer-props="{
              'items-per-page-options': itemsPerPageItems,
            }"
             @click:row="goToTask($event.id)"
            :server-items-length="tasks ? tasks.count : 0"
            :options.sync="pagination"
            fixed-header
          >
            <template v-slot:[`item.status`]="{ item }">
              {{ statusItems.find((o) => o.value === item.status)?.name ?? "Missing Status" }}
            </template>
            <template v-slot:[`item.step`]="{ item }">
              {{ stepItems.find((o) => o.value === item.step)?.name ?? "Missing Step" }}
            </template>
            <template v-slot:[`item.lemma`]="{ item }">
              <v-btn
                :disabled="!item.lemma"
                text
                @click="goToLemma(item.lemma)">
                  <v-icon small class="mr-2">mdi-eye</v-icon>
                  <span class="d-sr-only">{{ $t("TaskView.go-to-lemma") }}</span>
                  {{ item.lemma ? item.lemma_name : "Keinem Lemma zugeordnet" }}
              </v-btn>
            </template>
            <template v-slot:[`item.deadline`]="{ item }">
              {{
                item.deadline
                  ? new Date(item.deadline).toDateString()
                  : "Derzeit keine Deadline vorhanden"
              }}
            </template>
            <template v-slot:[`item.last_edited`]="{ item }">
              {{ new Date(item.last_edited).toDateString() }}
              {{ new Date(item.last_edited).toLocaleTimeString() }}
            </template>
            <template
              v-slot:[`item.actions`]="{ item }"
              v-if="AM.loggedIn"
            >
              <v-btn icon @click="goToEdit(item.id)">
                <v-icon
                  :data-test="`${componentName}-edit-task-${item.id}`"
                  small
                  class="mr-2"
                >
                  mdi-pencil
                </v-icon>
                <span class="d-sr-only">{{ $t("TaskView.edit-task", {id: item.id, lemma: item.lemma_name}) }}</span>
              </v-btn>
              <v-btn icon @click.stop.capture="deleteTask(item.id)">
                <v-icon
                  :data-test="`${componentName}-delete-task-${item.id}`"
                  small
                  class="mr-2"
                >
                  mdi-delete
                </v-icon>
                <span class="d-sr-only">{{ $t("TaskView.delete-task", {id: item.id, lemma: item.lemma_name}) }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>
<script lang="ts">
import debounce from "debounce";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { AuthModule } from "@/store/modules/auth";

import { TaskModule } from "@/store/modules/task";

import { UsersModule } from "@/store/modules/users";
import { ApiTaskRequest, ApiUser } from "@/static/apiModels";

import { VuetifyDatatTablePagination } from "@/static/types";
import { LemmaModule } from "@/store/modules/lemma";
import Logger from "../services/LoggerService";
import ApiSelect from "./ApiSelect.vue";

@Component({
  components: {
    ApiSelect,
  },
  name: "TaskView",
})
export default class TaskView extends Vue {
  componentName = "TaskView";

  l = new Logger("TaskView.vue");

  tab = null;

  TM = TaskModule;

  AM = AuthModule;

  UM = UsersModule;

  LM = LemmaModule;

  assignedUser: ApiUser | null = null;

  search: string = "";

  boolFilters: boolean = false;

  showSearch: boolean = false;

  confirmIt: boolean = false;

  triggerSearch: boolean = false;

  step: string = "";

  status: string = "";

  itemsPerPageItems = [10, 25, 50, 100, 500, 1000, 2000];

  pagination: VuetifyDatatTablePagination = {
    itemsPerPage: 25,
    sortBy: ["last_edited"],
    sortDesc: [true],
    page: 1,
  };

  defaultPagination: VuetifyDatatTablePagination = {
    page: 1,
    itemsPerPage: 25,
    sortBy: ["last_edited"],
  };

  tabItems = [
    "Meine offenen Aufgaben",
    "Meine abgeschlossenen Aufgaben",
    "Zuletzt bearbeitete Aufgaben",
  ];

  tabItemsOff = ["Zuletzt bearbeitete Aufgaben"];

  headers = [
    { text: "ID", value: "id" },
    { text: "Lemma", value: "lemma", sortable: true },
    { text: "Fällig", value: "deadline", sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Step", value: "step", sortable: true },
    { text: "Zuletzt bearbeitet", value: "last_edited", sortable: true },
    { text: "Description", value: "description", sortable: false },
    { text: "Assigned User", value: "user_name", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ];

  get stepItems() {
    return TaskModule.stepItems;
  }

  get statusItems() {
    return TaskModule.status;
  }

  get filters() {
    return TaskModule.filters;
  }

  get loading() {
    return TaskModule.loading;
  }

  get tasks() {
    return TaskModule.tasks ? TaskModule.tasks : [];
  }

  get searchLemma() {
    return LemmaModule.searchLemma;
  }

  showFilters() {
    this.boolFilters = !this.boolFilters;
  }

  @Watch("tab")
  onTabChange(val: any) {
    this.l.log("Tab has been switched to", val);
    if (!(this.status && this.step && this.search)) delete this.filters.user;
    delete this.filters.currentstatus;
    delete this.filters.mytasks;
    this.filters.current = true;

    if (AuthModule.loggedIn) {
      if (val === 0) {
        const username = AuthModule.getUser ? AuthModule.getUser.username : "";
        this.filters.user = username;
      } else if (val === 1) {
        const username = AuthModule.getUser ? AuthModule.getUser.username : "";
        this.filters.user = username;
        this.filters.mytasks = 0;
      } else {
        this.filters.currentstatus = 2;
      }
    }
    this.pagination = this.defaultPagination;

    this.TM.fetchTasks(this.filters);
  }

  setOrder() {
    this.filters.ordering = `${this.pagination.sortDesc?.[0] ? "-" : ""}${this.pagination.sortBy}`;
  }

  resetPage() {
    this.filters.page = 1;
  }

  @Watch("search")
  onSearchCleared() {
    // if search got cleared
    if (!this.search && !this.triggerSearch) {
      TaskModule.setFilters({ current: true });
      this.onTabChange(this.tab);
    }

    if (this.search && this.search.length === 0) return;

    LemmaModule.fetchSearchLemma({
      lemmatisierung: this.search,
      page_size: 50,
    });
  }

  @Watch("assignedUser")
  onAssignedUser() {
    this.onSearchSubmitted();
  }

  @Watch("$route", { immediate: true })
  onRouteChange() {
    this.setDefaultFilters();
  }

  setDefaultFilters() {
    TaskModule.resetFilters();
    if (AuthModule.loggedIn) {
      this.filters.user = AuthModule.getUser ? AuthModule.getUser.username : "";
    }

    this.pagination = this.defaultPagination;
  }

  @Watch("pagination", { deep: true })
  onPaginationChange() {
    const { page, itemsPerPage } = this.pagination;
    let ordering: string | undefined;

    if (this.pagination.sortBy?.length === 1) {
      ordering = (this.pagination.sortDesc?.[0] ? "-" : "") + this.pagination.sortBy;
    } else {
      ordering = undefined;
    }

    if (
      this.TM.filters.page === page &&
      this.TM.filters.page_size === itemsPerPage &&
      this.TM.filters.ordering === ordering
    ) {
      return;
    }

    if (this.TM.filters.ordering !== ordering) {
      this.resetPage();
    }

    this.setPaginationToFilters({ page, itemsPerPage, ordering });

    this.TM.fetchTasks(this.filters);
  }

  setPaginationToFilters(pagination: { page: number; itemsPerPage: number; ordering?: string }) {
    this.filters.page = pagination.page;
    this.filters.page_size = pagination.itemsPerPage;
    this.filters.ordering = pagination.ordering;
  }

  deleteTask(id: number) {
    TaskModule.deleteTask(id);
  }

  goToTask(id: number) {
    this.l.log("Go to single View");
    this.$router.push(`/task/${id.toString()}`);
  }

  goToEdit(id: number) {
    this.l.log("Go to edit view");
    this.$router.push(`/task/${id.toString()}/edit`);
  }

  goToCreate() {
    this.l.log("Go to create view");
    this.$router.push(`/task/create`);
  }

  goToCreateReport() {
    this.l.log("Go to create view");
    this.$router.push(`/report`);
  }

  goToLemma(url: string) {
    const regexp = /\/(\d+)\/$/g;
    const id = regexp.exec(url);
    if (id === null) {
      return;
    }
    this.l.log("gotolemma", id[1]);
    this.$router.push(`/lemma/${id[1].toString()}`);
  }

  @Watch("status")
  onStatusChange() {
    this.onSearchSubmitted();
  }

  debouncedOnSearchSubmitted = debounce(() => this.onSearchSubmitted(), 300);

  onSearchSubmitted() {
    const newFilters: ApiTaskRequest = {};
    if (this.assignedUser) {
      newFilters.user = this.assignedUser.username;
    }
    newFilters.status = this.status;
    newFilters.step = this.step;
    newFilters.lemma = this.search;
    newFilters.current = true;
    if (!this.search && !this.status && !this.step && !this.assignedUser) {
      TaskModule.setFilters({});
      this.onTabChange(this.tab);
      return;
    }
    TaskModule.setFilters(newFilters);
    TaskModule.fetchTasks(this.filters);
  }

  // lifecycle hook

  created() {
    this.setDefaultFilters();
  }
}
</script>
