import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";

import store from "@/store";
import DocumentHeader from "@/models/DocumentHeader";
import documentheaders from "./documentHeader_helper";
import Preset from "../../models/Preset";
import presets from "./presets_helper";

export interface IHeaderState {
  headers: DocumentHeader[];
  filters: any[];
  loading: boolean;
  presets: Preset[];
}

@Module({
  dynamic: true,
  store,
  name: "headers",
  namespaced: true,
})
class Headers extends VuexModule implements IHeaderState {
  headers: DocumentHeader[] = documentheaders.sort(this.compare);

  loading: boolean = false;

  presets: Preset[] = presets;

  filters: any = {};

  get getFilters() {
    return this.filters;
  }

  @Mutation
  setFilters(filters: any) {
    this.filters = filters;
  }

  get getAllHeader() {
    return documentheaders;
  }

  compare(a: any, b: any) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }

  get getPresets() {
    return presets;
  }

  get getShownHeaders() {
    return this.headers.filter((header) => header.show);
  }

  get show() {
    return this.headers.map((h) => h.show);
  }

  @Mutation
  swapShow(value: string) {
    this.headers = this.headers.map((header) => {
      if (header.value !== value) {
        return header;
      }
      // eslint-disable-next-line no-param-reassign
      header.show = !header.show;
      return header;
    });
  }

  @Mutation
  doPresets(preset: Preset) {
    this.headers.forEach((header) => {
      // @ts-ignore
      if (preset.rows.includes(header.value)) {
        // eslint-disable-next-line no-param-reassign
        header.show = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        header.show = false;
      }
    });
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }
}

export const HeadersModule = getModule(Headers);
