<template @keydown="handleKeydown($event)">
  <v-layout justify-center>
    <template v-if="!loading">
      <v-flex
        xs12
        sm12
        md12
        lg12
      >
        <vContainer
          fluid
          @keydown="handleKeydown($event)"
        >
          <v-card elevation="2">
            <v-card-title>
              {{ $route.params.id }} - {{ collection.title }}

              <!-- Swap to edit view -->
              <v-btn
                icon
                small
                style="float: left; margin-left: 20px; margin-top: 2px"
                @click="goToEdit($route.params.id)"
                color="primary"
                data-test="collection-edit-button"
              >
                <v-icon>mdi-border-color</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="mb-1"
                    size="18px"
                    >mdi-archive</v-icon
                  >
                  <b
                    v-on="on"
                    class="pr-2 ml-1"
                    >{{ $t(category()) }}</b
                  >
                </template>
                <span>{{ $t("Collections.category") }}</span>
              </v-tooltip>
              <!-- Open Detail Modal -->
              <DetailedProperties CollId="$route.params.id"></DetailedProperties>
            </v-card-subtitle>

            <v-card-actions>
              <!-- Export Collection -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    data-test="collection-export-button"
                    color="primary"
                    @click="exportCollectionAsCSV()"
                    v-on="on"
                  >
                    {{ $t("CollectionSingleView.export") }}
                  </v-btn>
                </template>
                <span> {{ $t("CollectionSingleView.export_tooltip") }} </span>
              </v-tooltip>

              <!-- Add Documents/Dokumente hinzufügen -->
              <v-btn
                data-test="collection-add-documents-button"
                :disabled="!AM.loggedIn"
                color="primary"
                class="ml-2"
                @click="addDocs"
                >{{ $t("CollectionSingleView.add_documents") }}</v-btn
              >

              <!-- Spalten/Collumn Selection -->
              <v-btn
                data-test="collection-open-header-selection-button"
                color="primary"
                @click="SM.switchHeaderDialog"
                class="ml-auto"
                >{{ $t("CollectionSingleView.headers") }} ->
              </v-btn>
            </v-card-actions>
          </v-card>

          <hr />

          <div class="d-flex justify-space-between flex-wrap mx-2">
            <div class="my-auto">
              <!-- Auswahl/Selection -->
              <v-btn
                data-test="collection-open-selection-dialog-button"
                color="primary"
                :disabled="selection.length > 0"
                @click="showSelection = !showSelection"
                >{{ $t("CollectionSingleView.selection") }}
              </v-btn>
            </div>
            <div
              class="d-flex justify-space-around flex-wrap mx- flex-grow-1 flex-shrink-1"
              style="max-width: 400px; min-width=300px"
            >
              <v-checkbox
                data-test="all-collumn-colors-checkbox"
                v-model="colorAll"
                @change="toggleAll"
                label="Alle"
              />
              <v-checkbox
                data-test="red-collumn-color-checkbox"
                v-model="colorRed"
                @change="toggleOneColor"
                label="Rot"
              />
              <v-checkbox
                data-test="yellow-collumn-color-checkbox"
                v-model="colorYellow"
                @change="toggleOneColor"
                label="Gelb"
              />
              <v-checkbox
                data-test="green-collumn-color-checkbox"
                v-model="colorGreen"
                @change="toggleOneColor"
                label="Grün"
              />
              <v-checkbox
                data-test="blue-collumn-color-checkbox"
                v-model="colorBlue"
                @change="toggleOneColor"
                label="Blau"
              />
            </div>
          </div>

          <DocumentSelect :show="showSelection" />
          <v-data-table
            data-test="collection-documents-table"
            height="80vh"
            class="mb-6"
            fixed-header
            @keydown="handleKeydown($event)"
            :headers="DocumentsHeader"
            :items="filteredDocs"
            v-if="!loading"
            must-sort
            :custom-sort="sortItems"
            @click:row="handleLeftClick($event, $event)"
            show-select
            @toggle-select-all="selectAll($event)"
            :class="{ crossfade: tagShootingMode && TM.selectionModeIsGun }"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
            :options.sync="pagination"
          >
            <!-- :item-class="redCols" -->
            <template v-slot:body.prepend>
              <tr>
                <td>
                  <v-btn
                    data-test="collection-filter-toggle"
                    small
                    text
                    @click="filterNot = !filterNot"
                  >
                    {{ filterNot ? "ohne" : "inklusiv" }}</v-btn
                  >
                  <v-icon>mdi-filter</v-icon>
                </td>
                <template v-for="(header, index) of DocumentsHeader">
                  <td
                    v-if="header.show"
                    :key="index"
                  >
                    <v-text-field
                      data-test="collection-header-filter-input"
                      @change="updateFiltersToLocal"
                      v-model.lazy="localFiltersInput[header.value]"
                      :placeholder="header.text"
                    ></v-text-field>
                  </td>
                </template>
              </tr>
            </template>
            <template v-slot:item="{ item, index }">
              <tr
                @contextmenu.prevent.stop="handleClick($event, item)"
                :class="{
                  current: index === currentRow && !TM.selectionModeIsGun && SM.getTaggingMode,
                }"
                @click="handleLeftClick(item, $event)"
              >
                <td class="stickysidecol">
                  <v-checkbox
                    :data-test="`collection-select-document-${index}-checkbox`"
                    :input-value="selection.includes(item.url)"
                    primary
                    hide-details
                    :label="`${index + 1}`"
                    @change="selectOne($event, item)"
                  ></v-checkbox>
                </td>
                <td v-if="showTags">
                  <TagChip
                    :data-test="`collection-tag-chip-${index}-doc-${item.es_id}`"
                    v-for="(url, index) of item.tag"
                    :url="url"
                    :key="index"
                    :shootable="true"
                    @tagClicked.stop="
                      l.debug('tag was clicked', $event);
                      handleTagClick({ url }, item);
                    "
                    class="delete"
                  ></TagChip>
                </td>
                <td v-if="showId && item.elastic_content">
                  <!-- es_id -->
                  {{ item.elastic_content ? item.elastic_content._id : "lel" }}
                </td>

                <template v-for="(header, index) of DocumentsHeader">
                  <td
                    v-if="item.elastic_content && header.pathInObject && header.show"
                    :key="index"
                    :class="tableStyle(header.value)"
                  >
                    <!-- {{ item[header.pathInObject] }} -->
                    <template
                      v-if="
                        (header.value === 'LT1_teuthonista' ||
                          header.value === 'KT1' ||
                          header.value === 'KT2' ||
                          header.value === 'KT3' ||
                          header.value === 'KT4' ||
                          header.value === 'KT5' ||
                          header.value === 'KT6' ||
                          header.value === 'KT7' ||
                          header.value === 'KT8' ||
                          header.value === 'KT9') &&
                        item.elastic_content &&
                        item.elastic_content._source[header.pathInObject]
                      "
                      >{{ renderPronounciation(item, header) }}
                    </template>
                    <template v-else>
                      {{ item.elastic_content._source[header.pathInObject] }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>

          <!-- tagging sheet -->
          <TaggingNav></TaggingNav>

          <!-- menu -->
          <VueSimpleContextMenu
            data-test="collection-context-menu"
            :elementId="'ContextMenuSingle'"
            :options="optionsArray"
            :ref="'ContextMenuDocument'"
            @option-clicked="optionClicked"
          ></VueSimpleContextMenu>
          <TagSelect></TagSelect>
          <TagSingleSlotSelection></TagSingleSlotSelection>
          <createTags></createTags>
          <TeiEditDialog></TeiEditDialog>
          <editTags></editTags>
        </vContainer>
      </v-flex>
    </template>
    <template v-else>
      <CatSpinner></CatSpinner>
    </template>
  </v-layout>
</template>

<script lang="ts">
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */

// @ts-ignore
import { Component, Vue, Watch } from "vue-property-decorator";
import { ExportToCsv } from "export-to-csv";

import { DataTableCompareFunction } from "vuetify";
import { AuthModule } from "@/store/modules/auth";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";

import { CollectionsModule } from "../store/modules/collections";
import DetailedProperties from "./DetailedProperties.vue";
import TagList from "./TagList.vue";
import Logger from "../services/LoggerService";
import VueSimpleContextMenu from "./simple-context-menu.vue";

import { NotificationsModule } from "../store/modules/notifications";
import TagSelect from "./TagSelect.vue";
import TagSingleSlotSelection from "./TagSingleSlotSelection.vue";
import createTags from "./createTags.vue";

import { SettingsModule } from "../store/modules/settings";
import TaggingNav from "./TaggingNav.vue";

import { TagsModule } from "../store/modules/tags";
import { CompleteDocument, ApiTag } from "../static/apiModels";
import TagChip from "./TagChip.vue";
import DocHeaderFilter from "./DocHeaderFilter.vue";

import { HeadersModule } from "../store/modules/headers";
import HeaderSelect from "./HeaderSelect.vue";
import CatSpinner from "./CatSpinner.vue";
import DocumentSelect from "./DocumentSelect.vue";
import editTags from "./editTags.vue";
import TeiEditDialog from "./TeiEditDialog.vue";
import { DocumentsModule } from "../store/modules/documents";

@Component({
  // if you use components add them here
  components: {
    SnackbarSwitch,
    DarkmodeSwitch,
    DetailedProperties,
    TagList,
    VueSimpleContextMenu,
    TaggingNav,
    TagSelect,
    TagSingleSlotSelection,
    createTags,
    editTags,
    TagChip,
    TeiEditDialog,
    DocHeaderFilter,
    DocumentSelect,
    HeaderSelect,
    CatSpinner,
  },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "CollectionSingleView",
})
export default class CollectionSingleView extends Vue {
  l = new Logger("CollectionSingleView.vue");

  SM = SettingsModule;

  AM = AuthModule;

  TM = TagsModule;

  HM = HeadersModule;

  tagging = false;

  currentRow: number = 0;

  showSelection = false;

  localFilters: any = {};

  localFiltersInput: any = {};

  filterNot: boolean = false;

  colorAll: boolean = false;

  colorRed: boolean = false;

  colorYellow: boolean = false;

  colorGreen: boolean = false;

  colorBlue: boolean = false;

  sortItems(
    items: any[],
    sortBy: string[],
    sortDesc: boolean[],
    // The unused parameters are needed in for the vuetify sort function
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    locale: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customSorters?: Record<string, DataTableCompareFunction>,
  ): any[] {
    if (sortBy.length === 0) {
      return this.filteredDocs || [];
    }

    const header = this.DocumentsHeader.find((h) => h.value === sortBy[0]);
    if (!header) {
      NotificationsModule.setError({
        message: "Header has wrong sorting -> complain to dev guys",
        icon: "mdi-emoticon-sad",
      });
      return this.filteredDocs || [];
    }
    const desc = sortDesc[0] ? -1 : 1;

    const sortfunction = (a: any, b: any) => {
      let aVal: any;
      let bVal: any;
      if (["es_id", "id"].includes(sortBy[0])) {
        aVal = a[sortBy[0]];
        bVal = b[sortBy[0]];
      } else if (sortBy[0] === "tag") {
        aVal = JSON.stringify(
          a.tags
            .map((t: ApiTag) => t.name)
            .sort((aT: string, bT: string) => (aT < bT ? -1 : aT === bT ? 0 : 1)),
        );
        bVal = JSON.stringify(
          b.tags
            .map((t: ApiTag) => t.name)
            .sort((aT: string, bT: string) => (aT < bT ? -1 : aT === bT ? 0 : 1)),
        );
      } else {
        aVal = a.elastic_content ? a.elastic_content._source[header.pathInObject || ""] || "" : "";
        if (Array.isArray(aVal)) {
          [aVal] = aVal;
        }
        bVal = b.elastic_content ? b.elastic_content._source[header.pathInObject || ""] || "" : "";
        if (Array.isArray(bVal)) {
          [bVal] = bVal;
        }
      }
      if (aVal < bVal) {
        return -1 * desc;
      }
      if (aVal > bVal) {
        return 1 * desc;
      }
      return 0;
    };

    return (this.filteredDocs || []).sort(sortfunction);
  }

  category() {
    return CollectionsModule.currentCollectionCategory.categoryNameLocalizationKey;
  }

  updateFiltersToLocal() {
    this.localFilters = { ...this.localFiltersInput };
  }

  optionsArray = [
    {
      name: "Show in Database",
      slug: "show",
    },
    {
      name: "Edit",
      slug: "edit",
    },
    {
      name: "Delete from collection",
      slug: "delete",
    },
  ];

  selectAll() {
    if (!this.filteredDocs) return;
    if (this.selection.length === this.filteredDocs.length) {
      this.selection = [];
    } else {
      this.selection = this.filteredDocs.map((d) => d.url);
    }
  }

  get filteredDocs() {
    if (this.collection) {
      let tempDocs = this.collection.documents;
      const mainDocs = this.collection.documents;

      if (!tempDocs || tempDocs === null || tempDocs.length < 1) return [];
      const initialLen = tempDocs.length;
      if (this.localFilters) {
        // special cases:
        if (this.localFilters.es_id) {
          tempDocs = tempDocs.filter(
            (doc) =>
              doc.es_id && doc.es_id.toLowerCase().includes(this.localFilters.es_id.toLowerCase()),
          );
        }
        // tags
        if (this.localFilters.tag) {
          // tagfield be like 'hasTag -doesNotHaveTag'
          const tags = this.localFilters.tag.split(" ");
          this.l.debug("tags be like", tags);
          tags.forEach((paramtag: string) => {
            const maybeNegate = paramtag.startsWith("-") ? (res: any) => !res : (res: any) => res;
            const currenttag = paramtag.startsWith("-") ? paramtag.substr(1) : paramtag;

            // @ts-ignore
            tempDocs = tempDocs.filter((doc) => {
              return maybeNegate(
                doc.tags &&
                  doc.tags.find((tag) => tag.name.toLowerCase().includes(currenttag.toLowerCase())),
              );
            });
          });
        }

        Object.keys(this.localFilters).forEach((filter) => {
          const header = this.DocumentsHeader.find((h) => h.value === filter);
          if (!header || filter === "es_id" || filter === "tag" || this.localFilters[filter] === "")
            return;
          // @ts-ignore
          tempDocs = tempDocs.filter((doc) => {
            return (
              // @ts-ignore
              doc.elastic_content._source[header.pathInObject] &&
              // @ts-ignore
              JSON.stringify(doc.elastic_content._source[header.pathInObject])
                .toLowerCase()
                .includes(this.localFilters[filter].toLowerCase())
            );
          });
        });
      }
      if (tempDocs.length === 0) {
        NotificationsModule.setNeutral({
          message: "No Documents match this filter. Please change your searching subject",
          icon: "mdi-filter",
        });
        return []; // this.collection.documents; -> created an endless loop!
      }
      // exclusive filter: if the search results are equal to the whole documents list - return all instead of an empty list
      return !this.filterNot
        ? tempDocs
        : mainDocs
          ? initialLen === tempDocs.length
            ? tempDocs
            : mainDocs.filter((item) => (tempDocs ? !tempDocs.includes(item) : mainDocs))
          : [];
    }
    return [];
  }

  renderArchive(item: any) {
    if (item.elastic_content._source.entry) {
      const a = item.elastic_content._source.entry.ref.find(
        (ent: any) => ent["@type"] === "archiv",
      );
      if (a) return a.$;
    }
    return "";
  }

  get headerFilters() {
    return HeadersModule.getFilters;
  }

  filtersChanged() {
    this.filtersChanged();
    HeadersModule.setFilters(this.localFilters);
  }

  @Watch("headerFilters")
  onHeaderFilterChange() {
    if (this.headerFilters === this.localFilters) return;
    this.localFilters = this.headerFilters;
    this.localFiltersInput = this.headerFilters;
  }

  // array of url of selected rows
  get selection() {
    return CollectionsModule.getSelection;
  }

  set selection(val: string[]) {
    CollectionsModule.setSelection(val);
  }

  select(documents: CompleteDocument[]) {
    this.showSelection = true;
    this.l.debug("was selectieren wir?", documents);
    CollectionsModule.setSelection(documents.map((doc) => doc.url));
  }

  addDocs() {
    if (!this.collection) return;
    this.$router.push(`/create/${this.collection.id}`);
  }

  selectOne(value: boolean, document: CompleteDocument) {
    this.showSelection = true;
    if (value) {
      const temp = Object.assign([], this.selection);
      temp.push(document.url);
      this.selection = temp;
    } else {
      this.selection = this.selection.filter((doc) => doc !== document.url);
    }
  }

  renderPronounciation(item: any, header: any) {
    if (item.elastic_content._source[header.pathInObject]) {
      if (Array.isArray(item.elastic_content._source[header.pathInObject]))
        return [item.elastic_content._source[header.pathInObject][0]];
      return item.elastic_content._source[header.pathInObject].replace("­", "`");
    }
    return "";
  }

  get showId() {
    const h = this.HM.getShownHeaders.find((header) => header.value === "es_id");
    return h ? h.show : false;
  }

  get showTags() {
    const h = this.HM.getShownHeaders.find((header) => header.value === "tag");
    return h ? h.show : false;
  }

  handleKeydown(event: any) {
    if (
      this.TM.creationDialog ||
      this.TM.getEditDialog ||
      this.TM.selectionDialog ||
      this.TM.singleTagSlotSelectionDialog
    )
      return;

    if (event.key === "Escape") {
      this.SM.leaveTaggingMode();
      this.TM.setEditDialog(false);
      this.TM.setDialog(false);
      this.TM.setCreationDialog(false);
    } else if (event.key === "Tab" && this.SM.getTaggingMode) {
      this.TM.switchTagSelectionMode();
    }
    if (this.SM.getTaggingMode) {
      if (this.TM.selectionModeIsGun) {
        const foundSlot = this.TM.getSlots.find((slot) => slot.key === event.key);
        if (foundSlot) {
          if (foundSlot.tag) {
            this.l.debug(
              "a right key was pressed. tag be:",
              foundSlot.tag ? foundSlot.tag.name : null,
            );
            this.TM.setSelectedTag(foundSlot.tag);
          } else {
            NotificationsModule.setError({
              message: "No tag selected for this slot!",
              icon: "mdi-tag-remove",
            });
            this.TM.setDialog(true);
          }
        }
      } else {
        // keyboardmode
        if (!this.collection) {
          NotificationsModule.setError({
            message: "weird, ask a dev guy :/",
            icon: "",
          });
          return;
        }
        if (!this.TM.selectionDialog && !this.TM.singleTagSlotSelectionDialog) {
          if (
            event.key === "ArrowDown" &&
            // @ts-ignore
            this.collection.documents.length - 1 > this.currentRow
          ) {
            this.currentRow += 1;
            return;
          }
          if (event.key === "ArrowUp" && this.currentRow > 0) {
            this.currentRow -= 1;
            return;
          }
        }

        const foundSlot = this.TM.getSlots.find((slot) => slot.key === event.key);
        if (foundSlot) {
          // @ts-ignore
          const doc = this.collection.documents[this.currentRow];

          if (foundSlot.tag) {
            if (this.TM.docHasTag({ document: doc, tag: foundSlot.tag })) {
              this.TM.removeTagfromDocument({
                document: doc,
                tag: foundSlot.tag,
              });
            } else {
              this.TM.addTagToDocument({ document: doc, tag: foundSlot.tag });
            }
          } else {
            NotificationsModule.setError({
              message: "No tag selected for this slot!",
              icon: "mdi-tag-remove",
            });
            this.TM.setDialog(true);
          }
        }
      }
    }
  }

  NM = NotificationsModule;

  tagDelete: boolean = false;

  handleLeftClick(item: any, event: any) {
    if (this.TM.tagShootingMode && !this.tagDelete && this.TM.selectionModeIsGun) {
      if (event.ctrlKey) {
        this.selection.forEach(() => {
          // this.TM.addTagToDocument({ tag: this.TM.getSelectedTag });
        });
      }
      // @ts-ignore
      this.TM.addTagToDocument({ tag: this.TM.getSelectedTag, document: item });
    }
  }

  get tagShootingMode() {
    return this.SM.getTaggingMode && this.TM.getSelectedTag;
  }

  optionClicked(event: any) {
    switch (event.option.slug) {
      case "delete":
        break;
      case "edit":
        DocumentsModule.setEditDocId(event.item.es_id);
        this.SM.setTeiEditDialog(true);
        break;
      case "show":
        window.open(event.item.url, "_blank");
        break;
      default:
        this.l.error("@dev did not implement this feature yet!!");
    }
  }

  handleClick(event: any, item: any) {
    this.l.debug("rightlick", item, event);
    // @ts-ignore
    this.$refs.ContextMenuDocument.showMenu(event, item);
  }

  toggleAll() {
    if (this.colorAll === false) {
      this.colorRed = false;
      this.colorYellow = false;
      this.colorGreen = false;
      this.colorBlue = false;
    } else {
      this.colorRed = true;
      this.colorYellow = true;
      this.colorGreen = true;
      this.colorBlue = true;
    }
  }

  toggleOneColor() {
    if (
      this.colorAll &&
      (!this.colorRed || !this.colorYellow || !this.colorGreen || !this.colorBlue)
    ) {
      this.colorAll = false;
    }
  }

  tableStyle(val: string) {
    if (val.startsWith("KT") || val.startsWith("LT")) {
      if (this.colorAll || this.colorGreen) {
        if (CollectionSingleView.darkmode) return "greenColsDark";
        return "greenColsLight";
      }
      return "noColorItalico";
    }
    // general case and the rest shouldn't be colored
    if (!this.colorRed && !this.colorYellow && !this.colorBlue) {
      return "noColorRegular";
    }

    // per colomn
    if ((val.startsWith("HL") || val.startsWith("NL")) && this.colorRed) {
      if (CollectionSingleView.darkmode) return "redColsDark";
      return "redColsLight";
    }

    if ((val.startsWith("NR") || val.match(/^BD\/[K|L]T/)) && this.colorYellow) {
      if (CollectionSingleView.darkmode) return "yellowColsDark";
      return "yellowColsLight";
    }

    if (
      (val.match(/Sigle([1-9])|(10)/) ||
        val.match(/Gemeinde([1-9])|(10)/) ||
        val.match(/Kleinregion([1-9])|(10)/) ||
        val.match(/Großregion([1-9])|(10)/) ||
        val.match(/Bundesland([1-9])|(10)/)) &&
      this.colorBlue
    ) {
      if (CollectionSingleView.darkmode) return "blueColsDark";
      return "blueColsLight";
    }

    return "noColorRegular";
  }

  get collection() {
    return CollectionsModule.currentCollection;
  }

  boolCat: boolean = false;

  boolDet: boolean = false;

  rowsPerPageItems = [10, 25, 50, 100, 500, 1000, 10000];

  get pagination() {
    return this.SM.getPagination;
  }

  set pagination(val: object) {
    if (this.SM.getPagination !== val) this.SM.setDocPagination(val);
  }

  get DocumentsHeader() {
    return this.HM.getShownHeaders;
  }

  goToEdit(id: number) {
    this.$router.push(`/collections/${id.toString()}/edit`);
  }

  get currentDocuments() {
    if (CollectionsModule.currentCollection) {
      return CollectionsModule.currentCollection.documents;
    }
    return [];
  }

  showCategoryTable() {
    this.boolCat = !this.boolCat;
  }

  showDetailedProperties() {
    this.boolDet = !this.boolDet;
  }

  get loading() {
    return CollectionsModule.singleLoading;
  }

  @Watch("loading")
  onLoadingChange() {
    this.$forceUpdate();
  }

  get AddToCollLoading() {
    return CollectionsModule.addToCollectionIsLoading;
  }

  @Watch("AddToCollLoading")
  onAddToCollLoadingChange() {
    if (this.AddToCollLoading) this.clearFilters();
  }

  clearFilters() {
    this.localFiltersInput = {};
  }

  handleTagClick(tag: ApiTag, document: CompleteDocument) {
    this.tagDelete = true;
    if (this.TM.selectionModeIsGun && this.SM.getTaggingMode) {
      this.TM.removeTagfromDocument({ tag, document });
    }
    setTimeout(() => {
      this.tagDelete = false;
    }, 500);
  }

  addClickHandler(event: any) {
    this.handleKeydown(event);
  }

  created() {
    document.addEventListener("keydown", this.addClickHandler);
  }

  destroyed() {
    document.removeEventListener("keydown", this.addClickHandler);
  }

  get id() {
    return +this.$route.params.id;
  }

  static get darkmode() {
    return SettingsModule.darkmode;
  }

  @Watch("$route")
  onRouteChange(before: any, after: any) {
    this.l.debug("routing", before, after);
    this.SM.leaveTaggingMode();
  }

  @Watch("id")
  onIdChange() {
    CollectionsModule.fetchCurrentCollection(this.id);
    this.showSelection = false;
  }

  beforeCreate() {
    CollectionsModule.fetchCurrentCollection(+this.$route.params.id);
  }

  exportCollectionAsCSV() {
    const tempDocs = this.filteredDocs;

    const objCollection: Object[] = [];

    let rowIndex = 0;

    let row: Object;

    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const csvOptions = {
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Collection_${this.collection?.id}_${this.collection?.title}_${day}_${month}_${year}`,
    };

    tempDocs.forEach((doc) => {
      row = {};

      if (this.showId) {
        row = {
          ID: doc.elastic_content ? (doc.elastic_content as any)._id : "ID missing",
        };
      }

      HeadersModule.headers.forEach((header) => {
        if (doc.elastic_content && header.pathInObject && header.show) {
          let val = (doc.elastic_content as any)._source[header.pathInObject];
          if (typeof val === "string") this.sanatizeForCSV(val);
          if (Array.isArray(val)) {
            val = val.join(" ");
            val = this.sanatizeForCSV(val);
          }
          if (val === undefined) val = "";
          Object.assign(row, { [header.text]: val });
        }
      });
      objCollection[rowIndex] = row;
      rowIndex += 1;
    });

    const csvExporter = new ExportToCsv(csvOptions);

    csvExporter.generateCsv(objCollection);
  }

  sanatizeForCSV(st: String): String {
    return st.replace("'", "'").replace('"', "'").replace(";", '";"'); // eslint-disable-line
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.finduz-italico {
  font-family: "WOFF Fiduz-Italic";
}

.finduz-regular {
  font-family: "WOFF Fiduz-Regular";
}

/*
table * {
  font-family: "WOFF Fiduz-Regular";
  font-feature-settings: "kern" on, "liga" on, "calt" on;
			-moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-o-font-feature-settings: "kern" on, "liga" on, "calt" on;
}
*/
ul {
  list-style-type: none;
  padding: 0;
}

h2 {
  float: left;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

hr {
  clear: both;
  margin-bottom: 10px;
}

.current {
  background-color: #42b983 !important;
}

.delete {
  cursor: pointer;
}

.crossfade {
  cursor: crosshair;
}

td,
th {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.noColorItalico {
  font-family: "WOFF Fiduz-Italic";
}

.noColorRegular {
  font-family: "WOFF Fiduz-Regular";
}

.redColsDark {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(85, 0, 0);
}

.greenColsDark {
  font-family: "WOFF Fiduz-Italic";
  background-color: rgb(0, 85, 0);
}

.yellowColsDark {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(85, 85, 0);
}

.blueColsDark {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(0, 0, 85);
}

.redColsLight {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(255, 170, 170);
}

.greenColsLight {
  font-family: "WOFF Fiduz-Italic";
  background-color: rgb(170, 255, 170);
}

.yellowColsLight {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(255, 255, 170);
}

.blueColsLight {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(170, 170, 255);
}

.stickysidecol {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: rgba(255, 255, 255, 0.12);
}
</style>
