import { render, staticRenderFns } from "./Create.vue?vue&type=template&id=3d9b692c&scoped=true"
import script from "./Create.vue?vue&type=script&lang=ts"
export * from "./Create.vue?vue&type=script&lang=ts"
import style0 from "./Create.vue?vue&type=style&index=0&id=3d9b692c&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../layers/heroku_nodejs-pnpm-install/virtual/store/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.2.5_vue-template-compiler@2.7.16_webpack@5.90.3/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d9b692c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/layers/heroku_nodejs-pnpm-install/virtual/store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d9b692c')) {
      api.createRecord('3d9b692c', component.options)
    } else {
      api.reload('3d9b692c', component.options)
    }
    module.hot.accept("./Create.vue?vue&type=template&id=3d9b692c&scoped=true", function () {
      api.rerender('3d9b692c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Create.vue"
export default component.exports