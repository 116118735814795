import { AxiosResponse } from "axios";
import api from ".";

export const getSearchedDocument = (q: any): Promise<AxiosResponse<any>> => {
  return api.get(`/dboe/_search`, {
    params: {
      source: JSON.stringify(q),
      source_content_type: "application/json",
      size: "10000",
      _source_excludes: "location*,entry",
    },
  });
};

export const getSearchedDocumentFullTextSearch = (q: any): Promise<AxiosResponse<any>> => {
  return api.get(`/dboe/_search?q=${q}&size=10000&_source_excludes=location*,entry`);
};
