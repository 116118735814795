<template>
  <vContainer>
    <v-form>
      <v-layout
        row
        justify-space-around
      >
        <v-flex md2>
          <ApiSelect
            data-test="user-filter"
            @input="updateUser"
            :filterValue="selectedUser"
            itemText="username"
            label="user"
            v-model="selectedUser"
            type="users"
          ></ApiSelect>
        </v-flex>

        <v-flex md2>
          <v-select
            data-test="visibility-filter"
            v-model="visibility"
            clearable
            :label="$t('Collections.filters.visibility')"
            :items="visibilityOptions"
            item-text="label"
            item-value="value"
            variant="outlined"
          />
        </v-flex>
      </v-layout>
    </v-form>
  </vContainer>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { UsersModule } from "@/store/modules/users";
import { ApiCollectioRequest, ApiUser } from "../static/apiModels";
import ApiSelect from "./ApiSelect.vue";
import { CollectionsModule } from "../store/modules/collections";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: { ApiSelect },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "CollectionFilters",
})
export default class CollectionFilters extends Vue {
  l = new Logger("CollectionFilters.vue");

  visibility: boolean | null = null;

  get visibilityOptions() {
    return [
      {
        label: this.$t("CollectionFilters.only_public"),
        value: true,
      },
      {
        label: this.$t("CollectionFilters.only_private"),
        value: false,
      },
    ];
  }

  beforeMount() {
    this.syncFieldsWithStore();
  }

  get storeFilters(): ApiCollectioRequest {
    return CollectionsModule.filters;
  }

  @Watch("storeFilters", { deep: true })
  onFiltersChange() {
    this.syncFieldsWithStore();
  }

  syncFieldsWithStore() {
    this.visibility = this.storeFilters.public ?? null;
    if (this.storeFilters.created_by) {
      this.selectedUser = UsersModule.byId(this.storeFilters.created_by) ?? null;
    } else {
      this.selectedUser = null;
    }
  }

  @Watch("visibility")
  onVisibilityChange() {
    if ((this.visibility ?? undefined) === this.storeFilters.public) return;
    CollectionsModule.updateFilters({
      public: this.visibility ?? undefined,
    });
  }

  selectedUser: ApiUser | null = null;

  updateUser() {
    if (this.selectedUser?.id === this.storeFilters.created_by) return;
    CollectionsModule.updateFilters({
      created_by: this.selectedUser?.id,
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
