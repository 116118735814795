<template>
  <v-container>
    <h1>{{ $t("basics.create") }}:</h1>
    <v-text-field
      :data-test="`${componentName}-search`"
      v-model="search"
      full-width
      append-icon="mdi-filter"
      append-outer-icon="mdi-magnify"
      @click:append-outer="onSearch()"
      @click:append="showFilters()"
      :label="$t('searchView.search')"
      v-on:keyup.enter="onSearch()"
      outline
      clearable
    ></v-text-field>
    <DocFilters
      :data-test="`${componentName}-doc-filters`"
      v-if="boolFilters"
    ></DocFilters>

    <v-layout
      row
      justify-space-between
    >
      <v-flex>
        <v-layout
          column
          justify-start
          class="px-4"
        >
          <CollectionCreation
            :data-test="`${componentName}-collection-creation`"
            :selection="selectionDoc"
            :loading="addLoading"
            :curators="filteredCuratorsUrls"
          />
        </v-layout>
      </v-flex>
      <v-flex>
        <v-layout
          column
          justify-start
          class="px-4"
        >
          <h3>{{ $t("searchView.add_to_coll_title") }}</h3>
          <CollectionSelect
            :data-test="`${componentName}-collection-select`"
            :selectedCollection.sync="selectedCollection"
          ></CollectionSelect>
          <v-checkbox
            :data-test="`${componentName}-jumptocoll`"
            label="springe zu collection"
            v-model="jumptocoll"
          ></v-checkbox>
          <v-btn
            :data-test="`${componentName}-add-to-collection`"
            :disabled="selectionDoc.length < 1 || !selectedCollection || addLoading"
            @click="addToCollection()"
          >
            {{ $t("searchView.add_text") }}
            <v-icon
              class="mdi-spin"
              v-if="addLoading"
              >mdi-loading</v-icon
            >
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-content>
      <v-btn
        :data-test="`${componentName}-select-all`"
        @click="selectAll()"
        >Select All Documents</v-btn
      >
      {{ selectionDoc.length }} / {{ docs.length || 0 }}
      <v-data-table
        :data-test="`${componentName}-data-table`"
        v-if="docs"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems,
        }"
        :headers="DocumentsHeader"
        :items="docs"
        :custom-sort="sortItems"
        :loading="searchLoading"
        class="elevation-1 mt-1"
        must-sort
        :options.sync="pagination"
      >
        <template v-slot:item="{ item, index }">
          <tr
            :data-test="`${componentName}-data-table-row-${index}`"
            data-v-8e976006
            :class="{ selected: selection.includes(index) }"
            @click="handleItemClick({ item, index })"
          >
            <td>{{ item._id }}</td>
            <td>{{ item._source.HL }}</td>

            <td>{{ item._source.POS }}</td>
            <td>{{ item._source["BD/LT*"] }}</td>
            <td>{{ item._source["BD/KT*"] }}</td>
            <td>{{ item._source.Sigle1 }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-content>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { getOneCollection } from "@/api/django/collections";
import { singleCollectionToCollectionBaseInfo } from "@/utils/Converters";
import { UsersModule } from "@/store/modules/users";
import { SearchModule } from "../store/modules/search";
import Logger from "../services/LoggerService";
import { DocumentsModule } from "../store/modules/documents";
import DocFilters from "./DocFilters.vue";
import { ApiDocumentsFilter, CollectionBaseInfo } from "../static/apiModels";
import { CollectionsModule } from "../store/modules/collections";
import CollectionSelect from "./CollectionSelect.vue";
import CollectionCategorySelection from "./CollectionCategorySelection.vue";
import { CollectionCategory, noCollectionCategory } from "../static/collectionConstants";
import CollectionCreation from "./CollectionCreation.vue";
import { esDocument } from "../api/elastic/elasticTypes";

@Component({
  components: {
    DocFilters,
    CollectionSelect,
    CollectionCategorySelection,
    CollectionCreation,
  },
  name: "SearchView",
})
export default class SearchView extends Vue {
  componentName = "SearchView";

  filteredCuratorsUrls = UsersModule.filteredCuratorsUrls;

  /* eslint no-underscore-dangle: 0 */
  public l = new Logger(this.componentName);

  boolFilters: boolean = false;

  selection: number[] = [];

  selectionDoc: esDocument[] = [];

  search: string = "";

  collName: string = "";

  selectedCollection: CollectionBaseInfo | null = null;

  output = null;

  rowsPerPageItems = [25, 50, 100, 500, 1000, 5000, 10000];

  jumptocoll = true;

  selectedCollectionCategory: CollectionCategory = noCollectionCategory;

  doNotTriggerSearchChange: boolean = false;

  pagination = {
    rowsPerPage: 100,
  };

  get routeId() {
    return +this.$route.params.id;
  }

  async handleItemClick(item: any) {
    if (this.selection.includes(item.index) === false) {
      this.selection.push(item.index);
      this.selectionDoc.push(item.item);
    } else {
      this.selection.splice(this.selection.indexOf(item.index), 1);
      this.selectionDoc.splice(this.selectionDoc.indexOf(item.item), 1);
    }
  }

  sortItems(items: any[], sortBy: string[], sortDesc: boolean[]): any[] {
    const desc = sortDesc[0] ? -1 : 1;
    const sortfunction = (a: any, b: any) => {
      let aVal: any;
      let bVal: any;
      aVal = a._source[sortBy[0]] ? a._source[sortBy[0]] || "" : "";
      if (Array.isArray(aVal)) {
        [aVal] = aVal;
      }
      bVal = b._source[sortBy[0]] ? b._source[sortBy[0]] || "" : "";
      if (Array.isArray(bVal)) {
        [bVal] = bVal;
      }
      if (aVal < bVal) {
        return -1 * desc;
      }
      if (aVal > bVal) {
        return 1 * desc;
      }
      return 0;
    };

    return (items || []).sort(sortfunction);
  }

  showFilters() {
    this.boolFilters = !this.boolFilters;
  }

  selectAll() {
    // @ts-ignore
    if (this.selection.length < this.docs.length) {
      // @ts-ignore
      for (let i = 0; i < this.docs.length; i += 1) {
        if (this.selection.includes(i) === false) {
          this.selection.push(i);
          // @ts-ignore
          this.selectionDoc.push(this.docs[i]);
        }
      }
    } else {
      this.selection = [];
      this.selectionDoc = [];
    }
  }

  get searchLoading() {
    return SearchModule.loading || DocumentsModule.loading;
  }

  DocumentsHeader = [
    { text: "ID", value: "ID" },
    { text: "Hauptlemma", value: "HL" },
    { text: "POS", value: "POS" },
    { text: "BD/LT", value: "BD/LT*" },
    { text: "BD/KT", value: "BD/KT*" },
    { text: "Sigle 1", value: "Sigle1" },
  ];

  get addLoading() {
    return DocumentsModule.loading;
  }

  get filters() {
    return DocumentsModule.getFilters;
  }

  @Watch("filters", { deep: true })
  onFilterChange() {
    this.doNotTriggerSearchChange = true;
    let tempSearch: string = "";
    if (this.filters.HL) {
      tempSearch += `-HL ${this.filters.HL} `;
    }
    if (this.filters.NL) {
      tempSearch += `-NL ${this.filters.NL} `;
    }
    if (this.filters.ID) {
      tempSearch += `-ID ${this.filters.ID} `;
    }
    if (this.filters.NR) {
      tempSearch += `-NR ${this.filters.NR} `;
    }
    if (this.filters.BD) {
      tempSearch += `-BD* ${this.filters.BD} `;
    }
    if (this.filters.KT) {
      tempSearch += `-KT* ${this.filters.KT} `;
    }
    this.search = tempSearch;
    this.doNotTriggerSearchChange = false;
  }

  addToCollection() {
    if (!this.selectedCollection) {
      return;
    }
    DocumentsModule.addESDocumentsToCollection({
      documents: this.selectionDoc,
      goToColl: this.jumptocoll,
      collectionId: this.selectedCollection?.id,
    });
  }

  onSearch() {
    const splitArray: string[] = this.search.split(" ");
    const tempFilter: ApiDocumentsFilter = { exist: false };
    if (splitArray.includes("-HL")) {
      tempFilter.HL = splitArray[splitArray.indexOf("-HL") + 1];
      tempFilter.exist = true;
    }
    if (splitArray.includes("-NL")) {
      tempFilter.NL = splitArray[splitArray.indexOf("-NL") + 1];
      tempFilter.exist = true;
    }
    if (splitArray.includes("-ID")) {
      tempFilter.ID = splitArray[splitArray.indexOf("-ID") + 1];
      tempFilter.exist = true;
    }
    if (splitArray.includes("-NR")) {
      tempFilter.NR = splitArray[splitArray.indexOf("-NR") + 1];
      tempFilter.exist = true;
    }
    if (splitArray.includes("-BD*")) {
      tempFilter.BD = splitArray[splitArray.indexOf("-BD*") + 1];
      tempFilter.exist = true;
    }
    if (splitArray.includes("-KT*")) {
      tempFilter.KT = splitArray[splitArray.indexOf("-KT*") + 1];
      tempFilter.exist = true;
    }
    DocumentsModule.setFilters(tempFilter);
    SearchModule.fetchSearchedDocuments(this.search);
    this.selection = [];
    this.selectionDoc = [];
  }

  get docs(): esDocument[] | null {
    return SearchModule.getSearch ? SearchModule.getSearch : null;
  }

  mounted() {
    this.initExistinCollection();
  }

  async initExistinCollection() {
    if (this.routeId) {
      if (CollectionsModule.currentCollection?.id === this.routeId) {
        this.selectedCollection = singleCollectionToCollectionBaseInfo(
          CollectionsModule.currentCollection,
        );
        return;
      }
      this.selectedCollection = singleCollectionToCollectionBaseInfo(
        (await getOneCollection(this.routeId)).data,
      );
    }
  }
}
</script>

<style scoped lang="scss">
.selected {
  background-color: #42b983 !important;
}

.lefty {
  margin: 50px;
  margin-top: 60px;
  float: left;
}
</style>
