import Vue from "vue";
import VueI18n from "vue-i18n";
import { dateTimeFormats } from "@/constants";
import { messages } from "./i18nMessages";

// @ts-ignore
window.Vue = Vue;

export default new VueI18n({
  dateTimeFormats,
  locale: "de", // set locale
  messages,
});
