/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import store from "@/store";

import {
  getOneTask,
  getTasks,
  putTask,
  deleteTask,
  postTask,
  fetchReport,
} from "@/api/django/task";

import api from "@/api/django";

import { NotificationsModule } from "./notifications";
import {
  ApiTask,
  ApiTaskRequest,
  ApiTaskPage,
  ApiTaskPost,
  ApiReportTypeLemma,
  ApiReportTypeStepStatus,
  ApiReportTypeUser,
  ApiReportRequest,
  ApiReportPage,
} from "../../static/apiModels";

export interface ITaskState {
  tasks: ApiTaskPage | null;
  currentTask: ApiTask | null;
  loading: boolean;
  singleLoading: boolean;
  filters: ApiTaskRequest;
}

// This is a Module Class for the Tasks
// Contains all the required functions to perform actions with the Tasks on the DB
// Also acts a store to save the Data of the Tasks
// Also contains the functions to create reports on the Database
@Module({
  dynamic: true,
  store,
  name: "task",
  namespaced: true,
})
class Tasks extends VuexModule implements ITaskState {
  currentReport: ApiReportPage | null = null;

  completeReport: ApiReportPage | null = null;

  tasks: ApiTaskPage | null = null;

  currentTask: ApiTask | null = null;

  loading: boolean = false;

  singleLoading: boolean = false;

  filters: ApiTaskRequest = {};

  stepItems = [
    { name: "Artikel in Arbeit", value: "ARTIKEL_IN_ARBEIT" },
    { name: "Artikel erstellt", value: "ARTIKEL_ERSTELLT" },
    { name: "Freigegeben für LK", value: "FREIGEGEBEN_FUER_LK" },
    { name: "Lautkommentar erstellt", value: "LAUTKOMMENTAR_ERSTELLT" },
    { name: "Lautkommentar hinzugefügt", value: "LAUTKOMMENTAR_HINZUGEFÜGT" },
    {
      name: "Freigegeben für Vorarbeiten",
      value: "FREIGEGEBEN_FUER_VORARBEITEN",
    },
    {
      name: "Verbreitungs Collection erstellt",
      value: "VERBREITUGS_COLLECTION_ERSTELLT",
    },
    { name: "Irrelevant", value: "IRRELEVANT" },
    { name: "Zugewiesen", value: "ZUGEWIESEN" },
  ];

  statusItems = [
    { name: "Draft", value: "DRAFT" },
    { name: "Peer Correction", value: "PEER_CORRECTION" },
    { name: "Internal Correction", value: "INTERNAL_CORRECTION" },
    { name: "External Correction", value: "EXTERNAL_CORRECTION" },
    { name: "Online", value: "ONLINE" },
    { name: "Final Version", value: "FINAL_VERSION" },
  ];

  get steps() {
    return this.stepItems;
  }

  get status() {
    return this.statusItems;
  }

  get getSingleLoading(): boolean {
    return this.singleLoading;
  }

  get getCurrentReport() {
    return this.currentReport;
  }

  get getCompleteReport() {
    return this.completeReport;
  }

  get getCurrentTask() {
    return this.currentTask;
  }

  get getTasks() {
    return this.tasks;
  }

  @MutationAction({ mutate: ["currentReport", "loading"] })
  async fetchReport(params: any) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiReportRequest = params ? { ...params } : {};

    const response = await fetchReport(PARAMS);
    return { currentReport: response.data, loading: false };
  }

  @MutationAction({ mutate: ["completeReport", "loading"] })
  async fetchCompleteReport(params: any) {
    const page_size = 500;

    const tempParams = params ? { ...params } : {};
    tempParams.page_size = page_size;

    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiReportRequest = tempParams ? { ...tempParams } : {};
    let response = await fetchReport(PARAMS);

    if (response.data.count) {
      if (page_size < response.data.count) {
        PARAMS.page_size = response.data.count;
        response = await fetchReport(PARAMS);
        return { completeReport: response.data, loading: false };
      }
      return { completeReport: response.data, loading: false };
    }
    return { completeReport: null, loading: false };
  }

  @MutationAction({ mutate: ["tasks", "loading"] })
  async fetchTasks(params?: ApiTaskRequest) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiTaskRequest = params ? { ...params } : {};
    const response = await getTasks(PARAMS);
    return { tasks: response.data, loading: false };
  }

  @MutationAction({ mutate: ["currentTask", "singleLoading"] })
  async fetchCurrentTask(id: number) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const response = await getOneTask(id);
    // TODO get also collections for this lemma
    return { currentTask: response.data, singleLoading: false };
  }

  @MutationAction({ mutate: ["currentTask", "singleLoading"] })
  async editTask(task: any) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const response = await putTask(task.prevTask, task.id);
    if (response.data) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully changed task with id:${response.data.id}`,
        icon: "mdi-check-bold",
      });
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be signed in in order to edit the Task",
        icon: "mdi-medical-bag",
      });
    }

    // @ts-ignore
    this.dispatch("createTask", task.newTask);
    return { currentTask: response.data, singleLoading: false };
  }

  @MutationAction({ mutate: ["singleLoading"] })
  async editSingleTask(task: any) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const response = await putTask(task.prevTask, task.id);
    if (response.data) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully changed task with id:${response.data.id}`,
        icon: "mdi-check-bold",
      });
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be signed in in order to edit the Task",
        icon: "mdi-medical-bag",
      });
    }
    return { singleLoading: false };
  }

  @MutationAction({ mutate: ["currentTask"] })
  async createTask(task: ApiTaskPost) {
    const response = await postTask(task);
    let failed = false;
    let msg = `added task for lemma`;
    let icn = "mdi-creation";

    if (response.status === 400) {
      msg = "Couln't create task.";
      icn = "mdi-alert-circle";
      failed = true;

      if (response.data.status && response.data.step)
        msg = "Couln't create task: Step & Status need to be set.";
      else if (response.data.status) msg = "Couln't create task: Status needs to be set.";
      else if (response.data.step) msg = "Couln't create task: Step needs to be set.";
    }
    if (response.status === 401) {
      msg = "You need to be logged in to create a task.";
      icn = "mdi-alert-circle";
      failed = true;
    }

    if (!failed) {
      NotificationsModule.setSuccess({
        message: msg,
        icon: icn,
      });
      return { currentTask: response.data };
    }
    NotificationsModule.setError({
      message: msg,
      icon: icn,
    });
    return { currentTask: this.currentTask };
  }

  @Action
  async deleteTask(id: number) {
    const response = await deleteTask(id);
    NotificationsModule.setSuccess({
      message: `Deleted task for lemma ${response.data}`,
      icon: "mdi-creation",
    });
  }

  @Action
  async fetchNextTaskPage() {
    if (!this.tasks) {
      NotificationsModule.setError({
        message: "no lemmata fetched so no next lemmata fetchable",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.tasks.next) {
      // @ts-ignore
      this.context.dispatch("fetchTasksByUrl", this.tasks.next);
    }
  }

  @Action
  async fetchPreviousTaskPage() {
    if (!this.tasks) {
      NotificationsModule.setError({
        message: "There is not a previous page",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.tasks.previous) {
      // @ts-ignore
      this.context.dispatch("fetchTasksByUrl", this.tasks.previous);
    }
  }

  @MutationAction({ mutate: ["tasks", "loading"] })
  async fetchTasksByUrl(url: string) {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await api.get(url);
    return { tasks: response.data, loading: false };
  }

  @Mutation
  resetFilters() {
    this.filters = {};
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSingleLoading(loading: boolean) {
    this.singleLoading = loading;
  }

  @Mutation
  setcurrentTask(lemma: ApiTask | null) {
    this.currentTask = lemma;
  }

  @Mutation
  setFilters(filters: ApiTaskRequest) {
    this.filters = filters;
  }

  isReportLemma(obj: any): obj is ApiReportTypeLemma {
    return obj.user__username !== undefined;
  }

  isReportUser(obj: any): obj is ApiReportTypeUser {
    return obj.lemma_count !== undefined;
  }

  isReportStepStatus(obj: any): obj is ApiReportTypeStepStatus {
    return obj.stati !== undefined;
  }
}

export const TaskModule = getModule(Tasks);
