import { AxiosResponse } from "axios";
import { ApiLemmaRequest, ApiLemma, ApiPostLemma } from "@/static/apiModels";

import api from ".";

export const getLemma = (params: ApiLemmaRequest): Promise<AxiosResponse<any>> => {
  const PARAMS = { ...params };
  return api.get("/api/lemmas", { params: PARAMS });
};

export const getSingleLemma = (id: number): Promise<AxiosResponse<any>> => {
  return api.get(`/api/lemmas/${id}`);
};

export const postLemma = (newLemma: ApiPostLemma): Promise<AxiosResponse<any>> => {
  return api.post("/api/lemmas/", newLemma);
};

export const editLemma = (lemma: ApiLemma, id: number): Promise<AxiosResponse<any>> => {
  return api.put(`/api/lemmas/${id}/`, lemma);
};
