/* eslint-disable no-underscore-dangle */
import { Module, VuexModule, MutationAction, Mutation, getModule } from "vuex-module-decorators";
import Vue from "@/main";
import Logger from "@/services/LoggerService";
import store from "@/store";
import { createDocumentsForCollection } from "@/api/django/documents";
import { getScans } from "@/api/DBdata/Scans";
import { patchCollection, getOneCollection } from "../../api/django/collections";
import {
  CompleteDocument,
  ApiDocumentsFilter,
} from "../../static/apiModels";
import { NotificationsModule } from "./notifications";

export interface EditDoc {
  docXML: string | null;
  xmlLoading: boolean;
  updateXMLLoading: boolean;
  editDocId: string | null;
  serverError: boolean;
  user: string | null;
  password: string | null;
}

export interface IDocumentState {
  documents: CompleteDocument[];
  loading: boolean;
  filters: ApiDocumentsFilter;
  editDoc: EditDoc;
}

const l = new Logger("DocumentsModule.vue");

@Module({
  dynamic: true,
  store,
  name: "documents",
  namespaced: true,
})
class Documents extends VuexModule implements IDocumentState {
  documents: CompleteDocument[] = [];

  loading: boolean = false;

  filters: ApiDocumentsFilter = { exist: false };

  editDoc: EditDoc = {
    docXML: null,
    xmlLoading: false,
    editDocId: null,
    serverError: false,
    user: null,
    password: null,
    updateXMLLoading: false,
  };

  get getUpdateXMLLoading() {
    return this.editDoc.updateXMLLoading;
  }

  @Mutation
  setUpdateXMLLoading(val: boolean) {
    this.editDoc.updateXMLLoading = val;
  }

  get getXmlLoading() {
    return this.editDoc.xmlLoading;
  }

  @Mutation
  setLoading(val: boolean) {
    this.loading = val || true;
  }

  @Mutation
  setFilters(filters: ApiDocumentsFilter) {
    this.filters = filters;
  }

  get getFilters() {
    return this.filters;
  }

  get getDocXML() {
    return this.editDoc.docXML;
  }

  get getEditDocId() {
    return this.editDoc.editDocId;
  }

  get getEditDocError() {
    return this.editDoc.serverError;
  }

  get getEditDoc() {
    return this.editDoc;
  }

  @Mutation
  setEditPW(val: string | null) {
    this.editDoc.password = val;
  }

  @Mutation
  setEditUser(val: string | null) {
    this.editDoc.user = val;
  }

  @Mutation setEditDocId(id: string | null) {
    this.editDoc.editDocId = id;
  }

  @Mutation
  setXmlLoading(val: boolean) {
    this.editDoc.xmlLoading = val;
  }

  @MutationAction({
    mutate: ["editDoc"],
    rawError: true,
  })
  async getDocumentXML(args: any) {
    // @ts-ignore
    this.commit("setXmlLoading", true);
    let error = false;
    const response = await getScans(args).catch((err) => {
      l.error("there was a data fetching error on the server:", err);
      error = true;
      NotificationsModule.setError({
        fullData: err.response.data,
        message: `Could not fetch document ${args.id}. It might not exist in this database`,
        icon: "mdi-emoticon-sad",
      });
    });
    const XMLEntry = response ? response.data._embedded.entries[0].entry : null;
    l.debug("got xml entry", XMLEntry);
    return {
      editDoc: {
        docXML: XMLEntry,
        serverError: error,
        xmlLoading: false,
        // @ts-ignore
        editDocId: this.state.editDoc.editDocId,
        // @ts-ignore
        user: this.state.editDoc.user,
        // @ts-ignore
        password: this.state.editDoc.password,
        // @ts-ignore
        updateXMLLoading: this.state.editDoc.updateXMLLoading,
      },
    };
  }

  @MutationAction({ mutate: ["loading"] })
  async createDocumentsForCollection(params: {
    // eslint-disable-next-line camelcase
    esIds: string[];
  }): Promise<{ loading: boolean; response?: any }> {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await createDocumentsForCollection(params.esIds).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      NotificationsModule.setError({
        message: "Error on inserting Documents. Please contact the development Team!",
        icon: "mdi-smile",
      });
    });
    return { loading: false, response };
  }

  @MutationAction({ mutate: ["loading"] })
  async addESDocumentsToCollection(params: {
    documents: any[];
    collectionId: number | null;
    goToColl: boolean;
  }) {
    // @ts-ignore
    this.commit("setLoading", true);
    const docs = params.documents.map((document) => {
      // eslint-disable-next-line no-underscore-dangle
      return { es_id: document._id };
    });

    let out = false;

    const response = await createDocumentsForCollection(docs).catch(() => {
      out = true;
      NotificationsModule.setError({
        message: "Error on inserting Documents. Please contact the development Team!",
        icon: "mdi-smile",
      });
    });

    if (out) return { loading: this.loading };

    const documentsUrls: string[] = [];
    // @ts-ignore
    response.data.forEach((doc: any) => {
      documentsUrls.push(doc.url);
    });

    if (!params.collectionId) return { loading: this.loading };
    const coll = (await getOneCollection(params.collectionId)).data;
    if (!coll) {
      NotificationsModule.setError({
        message: "error code: 654. please report to dev guys!",
        icon: "mdi-smile",
      });
      return { loading: this.loading };
    }

    const newDocs = coll.es_document.concat(documentsUrls);
    await patchCollection(
      {
        es_document: newDocs,
      },
      coll.id,
    ).catch((err) => {
      NotificationsModule.setError({
        fullData: err.response.data,
        message: "You might not have the permissions to edit this collection.",
        icon: "mdi-smile",
      });
      out = true;
    });
    if (out) return { loading: this.loading };
    NotificationsModule.setSuccess({
      message: "Added successfully.",
      icon: "mdi-smile",
    });
    if (params.goToColl) {
      Vue.$router.push(`/collections/${coll.id}`);
    }
    return { loading: this.loading };
  }
}

export const DocumentsModule = getModule(Documents);
