import api from ".";

export interface ESVersion {
  data: {
    version: {
      number: string;
    };
  };
}

export const getESVersion = (): Promise<ESVersion> => {
  return api.get(`/`);
};
