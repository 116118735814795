<template>
  <div>
    <v-snackbar
      vertical
      bottom
      right
      :timeout="0"
      time
      :color="latestLogColor"
      :value="showSnackbar || userSnackbar"
    >
      <v-layout column>
        <Log
          plain="true"
          limit="1"
        ></Log>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn
          :data-test="`${componentName}-close-snackbar`"
          color="white"
          text
          v-bind="attrs"
          @click="closeSnackbar()"
          class="float-right"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { SNACK_BAR_TIME } from "../constants/index";
import NotificationLog from "../models/Notification";
import Log from "./Log.vue";
import { NotificationsModule } from "../store/modules/notifications";
import { SettingsModule } from "../store/modules/settings";
import Logger from "../services/LoggerService";

@Component({
  components: { Log },
  props: {},
  name: "Snackbars",
})
export default class Snackbars extends Vue {
  componentName = "Snackbars";

  l = new Logger("Snackbars.vue");

  get snackbars() {
    return NotificationsModule.notifications;
  }

  get latestLogColor() {
    if (!this.snackbars || this.snackbars.length < 1) {
      return "info";
    }
    return this.snackbars[this.snackbars.length - 1].type;
  }

  get userSnackbar() {
    return SettingsModule.notifications;
  }

  get time() {
    return NotificationsModule.timeOfLastActivation;
  }

  get showSnackbar() {
    return NotificationsModule.showSnackbar;
  }

  get darkmode() {
    return SettingsModule.darkmode;
  }

  closeSnackbar() {
    NotificationsModule.setShow(false);
  }

  timeSinceOpened = 0;

  calculateTimeSinceSnackbarUpdate() {
    /*  setInterval(() => {
      if (this.time) {
        this.timeSinceOpened = Math.floor(
          (Date.now() - this.time.getTime()) / 1000
        );
      }
    }); */
  }

  get values() {
    const millis = Date.now();
    return this.snackbars.map((s: NotificationLog) => {
      return millis - new Date(s.time).getTime() < SNACK_BAR_TIME;
    });
  }

  showOneSnackbar(snackbar: NotificationLog): Boolean {
    const millis = new Date().getTime();
    return millis - new Date(snackbar.time).getTime() < SNACK_BAR_TIME;
  }

  created() {
    this.calculateTimeSinceSnackbarUpdate();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  cursor: pointer;
}
</style>
