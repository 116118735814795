// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-v-103a1e3d] {
  padding: 20px;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  color: #023660;
  background-color: #f7f7f7;
}
h1[data-v-103a1e3d] {
  font-size: 1.8em;
}
.switch-wrapper[data-v-103a1e3d] {
  margin: 30px 0;
  font-size: 0;
}
.switch-wrapper input[data-v-103a1e3d] {
  display: none;
}
.switch-label[data-v-103a1e3d] {
  font-size: 1rem;
  display: inline-block;
  line-height: 22.5px;
  vertical-align: top;
  padding: 5px 10px;
  transition: transform ease-in 0.2s;
  font-weight: bold;
  opacity: 0.6;
}
.switch[data-v-103a1e3d] {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22.5px;
  cursor: pointer;
  border-radius: 22.5px;
  border: 2px solid #eee;
  background-color: #2196f3;
  background-image: radial-gradient(circle, #fefefe 20%, transparent 30%), radial-gradient(circle, #fefefe 20%, transparent 30%), radial-gradient(circle, #fefefe 20%, transparent 30%), radial-gradient(circle, #fefefe 20%, transparent 30%), radial-gradient(circle, #fefefe 20%, transparent 30%), radial-gradient(circle, #fefefe 20%, transparent 30%);
  background-size: 50% 50%, 60% 60%, 40% 40%, 40% 40%, 50% 50%, 40% 40%;
  background-position: 60% 82%, 80% 80%, 82% 80%, 110% 20%, 134% 12%, 130% 20%;
  background-repeat: no-repeat;
  transition: 0.4s;
  overflow: hidden;
}
.switch[data-v-103a1e3d]:before {
  position: absolute;
  content: "";
  height: 17.5px;
  width: 17.5px;
  top: 50%;
  left: 2px;
  cursor: pointer;
  transform: translateY(-50%);
  background-color: #ffeb3b;
  background-image: radial-gradient(circle, #ffc107 15%, #ffeb3b 100%);
  box-shadow: 0px 0px 20px 10px rgba(255, 235, 255, 0.4);
  transition: transform ease-in 0.2s;
  border-radius: 50%;
  background-repeat: no-repeat;
}
.switch-label.day[data-v-103a1e3d] {
  transform: scale(1.1);
  opacity: 1;
}
input:checked ~ .switch[data-v-103a1e3d] {
  background-color: #023660;
  background-image: radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%), radial-gradient(circle, #fff 10%, transparent 30%);
  background-size: 8% 8%, 7% 7%, 6% 6%, 8% 8%, 8% 8%, 6% 6%, 6% 6%, 8% 8%, 7% 7%;
  background-position: 20% 20%, 45% 30%, 25% 55%, 20% 90%, 40% 60%, 5% 50%, 58% 10%, 58% 60%, 50% 80%;
  background-repeat: no-repeat;
}
input:checked ~ .switch[data-v-103a1e3d]:before {
  background-color: #ddd;
  background-image: radial-gradient(circle, #c4c4c4 50%, transparent 50%), radial-gradient(circle, #c9c9c9 50%, transparent 50%), radial-gradient(circle, #c9c9c9 50%, transparent 50%), radial-gradient(circle, #c9c9c9 50%, transparent 50%);
  background-size: 40% 40%, 25% 25%, 50% 50%, 30% 30%;
  background-position: 20% 20%, 65% 30%, 120% 100%, 20% 80%;
  box-shadow: 0px 0px 20px 10px rgba(255, 255, 255, 0.2);
}
.switch-wrapper input:checked ~ .switch-label.day[data-v-103a1e3d] {
  transform: scale(1);
  opacity: 0.6;
}
.switch-wrapper input:checked ~ .switch-label.night[data-v-103a1e3d] {
  transform: scale(1.1);
  opacity: 1;
}
.switch-wrapper input:checked ~ .switch[data-v-103a1e3d]:before {
  transform: translate(22.5px, -50%);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
