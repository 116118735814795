<template>
  <v-app-bar app>
    <v-toolbar-title class="headline text-uppercase">
      <router-link
        tag="button"
        to="/"
        data-test="collectioncat-logo-link"
        ><span>Collection</span><span class="bold">Cat</span></router-link
      >
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <v-btn
      text
      to="/home"
      v-if="AM.loggedIn"
      data-test="home-link"
    >
      {{ $t("TopNavigationBar.home") }}
    </v-btn>

    <!-- <select>
      <option v-for="btn in btnOpt" :value="btn" v-bind="btn">
        {{ btn.text }}
      </option>
    </select> -->
    <v-menu
      offset-y
      v-if="AM.loggedIn"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          data-test="sammlungen-dropdown"
        >
          {{ $t("TopNavigationBar.collections") }}
        </v-btn>
      </template>
      <v-card>
        <v-list-item
          v-for="option in btnOpt"
          :key="`${option.text}`"
          :to="`${option.value}`"
          data-test="sammlungen-dropdown-option"
        >
          <v-list-item-title>
            {{ $t(option.text) }}
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-menu>

    <v-btn
      text
      to="/lemma"
      data-test="lemma-link"
    >
      {{ $t("TopNavigationBar.lemma") }}
    </v-btn>

    <v-btn
      text
      to="/task"
      data-test="task-link"
    >
      {{ $t("TopNavigationBar.task") }}
    </v-btn>

    <v-btn
      text
      to="/log"
      data-test="log-link"
    >
      {{ $t("TopNavigationBar.log") }}
    </v-btn>

    <v-btn
      text
      to="/scans"
      data-test="scans-link"
    >
      {{ $t("TopNavigationBar.scans") }}
    </v-btn>

    <v-btn
      text
      to="/"
      v-if="!AM.loggedIn"
      data-test="collections-link"
    >
      {{ $t("TopNavigationBar.collections") }}
    </v-btn>

    <!-- Sammlungen - anzeigen - erstellen - geloeschte -->

    <span data-test="darkmode-switch">
      <DarkmodeSwitch></DarkmodeSwitch>
    </span>

    <LanguageSelect data-test="language-select"></LanguageSelect>

    <v-menu
      offset-y
      left
      v-if="AM.loggedIn"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          data-test="user-dropdown"
        >
          {{ AM.getUser?.username }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="text-xs-center">
        <v-list-item
          @click="$router.push('/settings')"
          data-test="settings-link"
        >
          <v-list-item-title>
            <v-icon>mdi-decagram-outline</v-icon>
            {{ $t("TopNavigationBar.settings").toUpperCase() }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list class="text-xs-center">
        <v-list-item
          @click="AM.logOff()"
          data-test="logout-link"
        >
          <v-list-item-title>
            <v-icon color="error">mdi-logout-variant</v-icon>
            {{ $t("TopNavigationBar.log_out").toUpperCase() }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      v-else
      text
      @click="doTheLogIn()"
      v-show="!AM.loggedIn"
      data-test="login-link"
    >
      <v-icon color="success">mdi-login-variant</v-icon>
      {{ $t("TopNavigationBar.login") }}
    </v-btn>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import LanguageSelect from "@/components/LanguageSelect.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import menu from "../menu/index";
import { AuthModule } from "../store/modules/auth";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: { DarkmodeSwitch, LanguageSelect },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "TopNavigationBar",
})
export default class TopNavigationBar extends Vue {
  l = new Logger("TopNavigatinoBar.vue");

  menu = menu;

  AM = AuthModule;

  btnOpt = [
    { text: "Navigation.collections.base", value: "/" },
    { text: "Navigation.collections.create", value: "/create" },
    { text: "Navigation.collections.deleted", value: "/deleted" },
  ];

  // selectedOption: string|null|undefined;

  changeToDropdown(event: { target: { text: string } }) {
    if (event.target.text === "collections") {
      this.$router.push({ path: "/" });
    } else if (event.target.text === "create") {
      this.$router.push({ path: "/create" });
    }
  }

  doTheLogIn() {
    this.$router.push({
      name: "login",
      query: { redirect: this.$router.currentRoute.fullPath },
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bold {
  font-weight: bold;
}
</style>
