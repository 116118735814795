import { AxiosResponse } from "axios";
import { ApiTaskRequest, ApiTaskPost, ApiReportRequest } from "@/static/apiModels";

import api from ".";

export const getTasks = (params: ApiTaskRequest): Promise<AxiosResponse<any>> => {
  const PARAMS = { ...params };
  return api.get("/api/article_edits/", { params: PARAMS });
};

export const putTask = (task: ApiTaskPost, id: number): Promise<AxiosResponse<any>> => {
  return api.put(`/api/article_edits/${id}/`, task);
};

export const getOneTask = (id: number): Promise<AxiosResponse<any>> => {
  return api.get(`/api/article_edits/${id}/`);
};

export const deleteTask = (id: number): Promise<AxiosResponse<any>> => {
  return api.delete(`/api/article_edits/${id}`);
};

export const postTask = (newTask: ApiTaskPost): Promise<AxiosResponse<any>> => {
  return api.post("/api/article_edits/", newTask, {
    validateStatus: (status) => (status >= 200 && status < 300) || (status >= 400 && status <= 401),
  });
};

export const fetchReport = (request: ApiReportRequest): Promise<AxiosResponse<any>> => {
  const PARAMS = { ...request };
  return api.get("/api/article_edits/", { params: PARAMS });
};
