<template>
  <v-layout justify-center>
    {{ $t("wait") }} <v-icon class="mdi-spin cat">mdi-fish</v-icon>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Logger from "../services/LoggerService";

@Component({
  components: {},
  name: "CatSpinner",
})
export default class CatSpinner extends Vue {
  l = new Logger("CatSpinner.vue");
}
</script>

<style scoped lang="scss">
.cat {
  //font-size: 64em;
}
</style>
