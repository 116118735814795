import { DateTimeFormats } from "vue-i18n";

export const SNACK_BAR_TIME = 5000; // milliseconds

export const DJANGO_BASE_URL = process.env.VUE_APP_DJANGOBASEURL;
// 'https://dboeannotation.acdh-dev.oeaw.ac.at/';

export const ELASTIC_SEARCH_URL = process.env.VUE_APP_ELASTICSEARCHURL;
// 'https://walk-want-grew.acdh-dev.oeaw.ac.at/dboe-test/';
// 'https://walk-want-grew-kube.acdh-dev.oeaw.ac.at/dboe/';
// 'https://walk-want-grew.acdh-dev.oeaw.ac.at/dboe/';

export const dateTimeFormats: DateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  de: {
    short: {
      day: "numeric",
      month: "short",
      year: "numeric",
    },
    long: {
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      year: "numeric",
    },
  },
};
