/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import {
  Module,
  VuexModule,
  MutationAction,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import isObject from "isobject";

import { editDocumentTags } from "@/api/django/documents";

import store from "@/store";

import {
  getCollections,
  getDeletedCollections,
  getOneCollection,
  getDocsOfCollection,
  postCollection,
  patchCollection,
} from "@/api/django/collections";
import { getDocuments } from "@/api/elastic/documents";
import Logger from "@/services/LoggerService";

import api from "@/api/django";
import i18n from "@/i18n";
import {
  CollectionCategory,
  noCollectionCategory,
  possibleCollectionCategories,
} from "../../static/collectionConstants";

import { TagsModule } from "./tags";

import { NotificationsModule } from "./notifications";
import {
  SingleCollection,
  ApiCollectioRequest,
  ApiCollectionPage,
  ApiCollectionParameters,
  CompleteDocument,
  ApiCollectionResponse,
} from "../../static/apiModels";

import Vue from "../../main";

export interface ICollectionState {
  collections: ApiCollectionPage | null;
  currentCollection: ApiCollectionResponse | null;
  results: ApiCollectionResponse[] | null;
  loading: boolean;
  singleLoading: boolean;
  filters: ApiCollectioRequest;
  selection: string[];
  updateCounter: number;
}

export interface CollDocsEditParams {
  url: string;
  docs: string[];
}

export interface CreateCollectionArguments {
  newCollection: ApiCollectionParameters;
  jumpToNewCollection: boolean;
}

export interface AddDocumentsToCollectionArguments {
  collection: SingleCollection;
  documents: string[];
  jump: boolean;
}

export function getCollectionCategoryFromId(id: string): CollectionCategory {
  return (
    possibleCollectionCategories.find((category) => category.id === id) || noCollectionCategory
  );
}

const l = new Logger("collectionsModule.vue");

@Module({
  dynamic: true,
  store,
  name: "collections",
  namespaced: true,
})
class Collections extends VuexModule implements ICollectionState {
  currentCollection: SingleCollection | null = null;

  collections: ApiCollectionPage | null = null;

  results: ApiCollectionResponse[] | null = []; // for testing

  loading: boolean = false;

  filters: ApiCollectioRequest = {};

  addToCollectionLoading: boolean = false;

  selection: string[] = [];

  updateCounter: number = 0;

  singleLoading: boolean = false;

  filterGuard: boolean = false;

  @Mutation
  setFilterGuard(state: boolean) {
    this.filterGuard = state;
  }

  get getSingleLoading(): boolean {
    return this.singleLoading;
  }

  @Mutation
  setCurrentCollection(collection: SingleCollection | null) {
    this.currentCollection = collection;
  }

  @Mutation
  setFilters(filters: ApiCollectioRequest) {
    this.filters = filters;
  }

  @Mutation
  updateFilters(filters: ApiCollectioRequest) {
    if (this.filterGuard) return;

    this.filters = {
      ...this.filters,
      ...filters,
    };
    if (!filters.page) {
      this.filters.page = 1;
    }
  }

  get addToCollectionIsLoading() {
    return this.addToCollectionLoading;
  }

  @Mutation
  setAddToCollectionLoading(loading: boolean) {
    this.addToCollectionLoading = loading;
  }

  @Action
  async addToCollection({ collection, documents, jump }: AddDocumentsToCollectionArguments) {
    // TODO edit current collection
    l.log("add to collection", collection);
    this.context.commit("setAddToCollectionLoading", true);
    if (!collection.documents) {
      // eslint-disable-next-line no-param-reassign
      collection.documents = [];
    }
    const editedCollection = {
      url: collection.url,
      es_document: documents.concat(collection.es_document),
    };

    try {
      // @ts-ignore
      const result = await patchCollection(editedCollection, collection.id);

      if (result.data) {
        NotificationsModule.setSuccess({
          // @ts-ignore
          message: `Successfully added  ${
            result.data.es_document.length - collection.es_document.length
          } documents to ${collection.title}`,
          icon: "mdi-check-bold",
        });
        if (jump) {
          Vue.$router.push(`/collections/${result.data.id}/`);
        }
      }
    } catch (err: any) {
      NotificationsModule.setError({
        fullData: err.response.data,
        message: "You need to be curator or creator of the collection in order to edit it",
        icon: "mdi-medical-bag",
      });
    }
    this.context.commit("setAddToCollectionLoading", false);
  }

  @Mutation
  deleteCollectionMutation(collurl: string) {
    if (!this.collections || !this.collections.results) return;
    // @ts-ignore
    this.collections.results = this.collections.results.filter((coll: ApiCollectionResponse) => {
      return coll.url !== collurl;
    });
  }

  @Action
  async deleteCollection(coll: ApiCollectionResponse | null) {
    if (coll === null) {
      NotificationsModule.setError({
        // @ts-ignore
        message: "there is no collection selected",
        icon: "mdi-medical-bag",
      });
      return;
    }
    l.log("delete a collection", coll);
    const deleteObj: any = { url: coll.url, deleted: true };
    const result = await patchCollection(deleteObj, coll.id);
    if (result) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully deleted ${coll.title}`,
        icon: "mdi-trash",
      });
      this.context.commit("deleteCollectionMutation", coll.url);
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be the creator of the collection in order to delete it",
        icon: "mdi-medical-bag",
      });
    }
  }

  @Action
  async restoreCollection(coll: ApiCollectionResponse | null) {
    if (coll === null) {
      NotificationsModule.setError({
        // @ts-ignore
        message: "there is no collection selected",
        icon: "mdi-medical-bag",
      });
      return;
    }
    l.log("restore a collection", coll);
    const restoreObj: any = { url: coll.url, deleted: false };
    const result = await patchCollection(restoreObj, coll.id);
    if (result) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully restored ${coll.title}`,
        icon: "mdi-trash",
      });
      this.context.commit("restoreCollectionMutation", coll.url);
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be the creator of the collection in order to restore it",
        icon: "mdi-medical-bag",
      });
    }
  }

  @Mutation
  editCurentColl(coll: ApiCollectionResponse) {
    if (!this.currentCollection) return;
    this.currentCollection.title = coll.title;
    this.currentCollection.comment = coll.comment;
    this.currentCollection.public = coll.public;

    this.currentCollection.curator = coll.curator;
    this.currentCollection.description = coll.description;
  }

  @Action
  async editCollection(object: any) {
    // TODO edit current collection
    l.log("edit a collection", object.collection);
    this.context.commit("setLoading", true);
    const result = await patchCollection(object.collection, object.id);
    if (result.data) {
      NotificationsModule.setSuccess({
        // @ts-ignore
        message: `Successfully changed ${object.collection.title}`,
        icon: "mdi-check-bold",
      });
    } else {
      NotificationsModule.setError({
        // @ts-ignore
        message: "You need to be curator or creator of the collection in order to edit it",
        icon: "mdi-medical-bag",
      });
    }
    this.context.commit("editCurentColl", result.data);
    this.context.commit("setLoading", false);
  }

  get collectionsLimited(): Function {
    return (limit: number) => {
      if (limit && this.collections && this.collections.results.length > limit) {
        return this.collections.results.slice(this.collections.results.length - limit);
      }
      return this.collections;
    };
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setSingleLoading(loading: boolean) {
    this.singleLoading = loading;
  }

  get getSelection() {
    return this.selection;
  }

  @Mutation
  setSelection(selection: string[]) {
    this.selection = selection;
  }

  @Mutation
  addTagToCurrentColl(url: string) {
    if (!this.currentCollection) return;
    if (!this.currentCollection.tags.includes(url)) {
      this.currentCollection.tags.push(url);
    }
  }

  get byUrl() {
    return (url: string | null) => {
      if (!this.collections || !url) return null;
      return this.collections.results.find((coll) => coll.url === url);
    };
  }

  get byId() {
    return (id: number | null) => {
      if (!this.collections || !id) return null;
      return this.collections.results.find((coll) => coll.id === id);
    };
  }

  get getCurrentCollection() {
    return this.currentCollection;
  }

  @MutationAction({ mutate: ["collections", "loading"] })
  async fetchCollections(params?: ApiCollectioRequest) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiCollectioRequest = params ? { ...params } : {};
    if (PARAMS.annotations__category && isObject(PARAMS.annotations__category)) {
      [PARAMS.annotations__category] = PARAMS.annotations__category.url.match(/\d+/);
    }
    let response = null;
    try {
      response = await getCollections(PARAMS);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 404) {
          NotificationsModule.setNeutral({
            message: i18n.t("Collections.fetch_error").toString(),
            fullData: error,
            icon: "mdi-alert",
          });
        } else {
          this.handleHTTPErrorGeneric(error);
        }
      }
    }
    const collections = response?.data;

    return { collections, loading: false };
  }

  handleHTTPErrorGeneric(error: any) {
    l.log(error.response.data);
    l.log(error.response.status);
    l.log(error.response.headers);
  }

  @MutationAction({ mutate: ["collections", "loading"] })
  async fetchDeletedCollections(params?: ApiCollectioRequest) {
    // @ts-ignore
    this.commit("setLoading", true);
    const PARAMS: ApiCollectioRequest = params ? { ...params } : {};
    if (PARAMS.annotations__category && isObject(PARAMS.annotations__category)) {
      [PARAMS.annotations__category] = PARAMS.annotations__category.url.match(/\d+/);
    }
    l.log("Fetch deleted collections", PARAMS, params);
    let response = await getDeletedCollections(PARAMS);

    if (response.status === 404) {
      PARAMS.page = 1;
      response = await getDeletedCollections(PARAMS);
    }

    return { collections: response.data, loading: false };
  }

  @Action
  // @ts-ignore
  async addTagToSelection({ tag }) {
    l.debug("addTagToSelection", tag);
    if (!tag.url) {
      l.error("only add existing tags to documents");
    }
    if (!this.currentCollection || !this.currentCollection.documents) return;

    const sels = this.selection.map((url) =>
      // @ts-ignore
      this.currentCollection.documents.find((doc) => doc.url === url),
    );

    sels.forEach((sel) => {
      if (!sel) return;
      if (sel.tag.includes(tag.url)) return;
      sel.tag.push(tag.url);
      editDocumentTags(sel).then((response) => {
        NotificationsModule.setSuccess({
          // @ts-ignore
          message: `added tag ${tag.name} to ${response.data.es_id}`,
          icon: "mdi-tag-plus",
        });
      });
    });
  }

  @Action
  // @ts-ignore
  async removeTagFromSelection({ tag }) {
    l.debug("addTagToSeremoveTagFromSelectionlection", tag);
    if (!tag.url) {
      l.error("only remove existing tags to documents");
    }
    if (!this.currentCollection || !this.currentCollection.documents) return;

    const sels = this.selection.map((url) =>
      // @ts-ignore
      this.currentCollection.documents.find((doc) => doc.url === url),
    );

    sels.forEach((sel) => {
      if (!sel) return;
      if (!sel.tag.includes(tag.url)) return;
      // eslint-disable-next-line no-param-reassign
      sel.tag = sel.tag.filter((t) => t !== tag.url);
      editDocumentTags(sel).then((response) => {
        NotificationsModule.setSuccess({
          // @ts-ignore
          message: `removed tag ${tag.name} to ${response.data.es_id}`,
          icon: "mdi-tag-minus",
        });
      });
    });
  }

  // todo add checks so this does not fuck up anything
  @MutationAction({ mutate: ["collections", "loading"] })
  async fetchCollectionsByUrl(url: string) {
    // @ts-ignore
    this.commit("setLoading", true);
    const response = await api.get(url);
    return { collections: response.data, loading: false };
  }

  @Action
  fetchNextCollectionPage() {
    if (!this.collections) {
      NotificationsModule.setError({
        message: "no collections fetched so no next collection fetchable",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.collections.next) {
      // @ts-ignore
      this.context.dispatch("fetchCollectionsByUrl", this.collections.next);
    }
  }

  @Action
  fetchPreviousCollectionPage() {
    if (!this.collections) {
      NotificationsModule.setError({
        message: "no collections fetched so no previous collection fetchable",
        icon: "mdi-hat",
      });
      return;
    }
    if (this.collections.previous) {
      // @ts-ignore
      this.context.dispatch("fetchCollectionsByUrl", this.collections.previous);
    }
  }

  @MutationAction({ mutate: ["currentCollection"] })
  async fetchCurrentCollection(id: number) {
    // @ts-ignore
    this.commit("setSingleLoading", true);
    const [response, docs] = await Promise.all([getOneCollection(id), getDocsOfCollection(id)]);

    response.data.documents = docs.data.results;

    response?.data?.documents?.forEach((doc: CompleteDocument) => {
      // eslint-disable-next-line no-param-reassign
      doc.tags = [];
      doc.tag.forEach((tagUrl) => {
        const tag = TagsModule.getTag(tagUrl);
        if (tag) {
          doc.tags?.push(tag);
        }
      });
    });

    // @ts-ignore
    this.dispatch("fetchDocumentsOfCurrentCollection", response.data.documents);
    return { currentCollection: response.data };
  }

  @Mutation
  // eslint-disable-next-line camelcase
  addESDocToDjangoDocuments(es_documents: any[]) {
    l.debug("addESDocToDjangoDocuments exists?", es_documents);
    if (this.currentCollection && this.currentCollection.documents) {
      this.currentCollection.documents.forEach((doc) => {
        // eslint-disable-next-line no-param-reassign
        doc.elastic_content = es_documents.find(
          // eslint-disable-next-line no-underscore-dangle
          (esd) => esd && esd._id === doc.es_id,
        );
      });
    } else {
      l.error("Documents should be defined at this point!");
    }
  }

  @Action
  async fetchDocumentsOfCurrentCollection(djangoDocuments: CompleteDocument[]) {
    this.context.commit("setLoading", true);
    const ids: string[] = djangoDocuments.map((id) => id.es_id);
    // get more documents
    const documentsOfCollection = await getDocuments(ids).catch((err) => {
      l.error("error on getting all da documents", err);
    });

    // @ts-ignore
    this.context.commit(
      "esDocsToCurrentCollection",
      // @ts-ignore
      documentsOfCollection.data.hits.hits,
    );

    this.context.commit("setLoading", false);
  }

  @Mutation
  esDocsToCurrentCollection(docs: any) {
    if (this.currentCollection && this.currentCollection.documents) {
      this.currentCollection.documents.forEach((doc) => {
        // eslint-disable-next-line no-underscore-dangle, camelcase
        // @ts-ignore
        const es_doc = docs.find((esd) => esd && esd._id === doc.es_id);
        // eslint-disable-next-line camelcase, no-param-reassign
        doc.elastic_content = es_doc || doc.elastic_content;
      });
    }
    this.singleLoading = false;
  }

  // overwrites a document in store with new infos, only considers given values
  @Mutation
  updateDocument(document: CompleteDocument) {
    if (this.currentCollection && this.currentCollection.documents) {
      this.currentCollection.documents.forEach((doc) => {
        if (doc.url === document.url) {
          Object.keys(document).forEach((key) => {
            // @ts-ignore
            doc[key] = document[key]; // eslint-disable-line no-param-reassign
          });
        }
      });
      document.tag.forEach((tag) => {
        // @ts-ignore
        if (!this.currentCollection.tags.includes(tag)) {
          // @ts-ignore
          this.currentCollection.tags.push(tag);
        }
      });
    }
  }

  @Action
  async createCollection({ newCollection, jumpToNewCollection }: CreateCollectionArguments) {
    this.context.commit("setAddToCollectionLoading", true);
    const response = await postCollection(newCollection);
    l.debug("response of posted collection");
    NotificationsModule.setSuccess({
      message: `added collection ${response.data.title}`,
      icon: "mdi-creation",
    });
    if (jumpToNewCollection) {
      // route here
      Vue.$router.push(`/collections/${response.data.url.match(/\d+/)[0]}`);
    }
    this.context.commit("setAddToCollectionLoading", false);
  }

  get currentCollectionCategory(): CollectionCategory {
    if (!this.currentCollection) return noCollectionCategory;
    // @ts-ignore possibly-null
    return (
      possibleCollectionCategories.find(
        // @ts-ignore possibly-null
        (category) => category.id === this.currentCollection.category,
      ) || noCollectionCategory
    );
  }
}

export const CollectionsModule = getModule(Collections);
