import { ApiCollectioRequest } from "./apiModels";

export const separatorQueryKey = "&";

/**
 * Parts or the whole title of a collection
 * E.g.: t=Lemma
 */
export const titleQueryKey = "t";

/**
 * Can be assigned a @see CollectionCategory
 * E.g.: c=distribution
 */
export const categoryQueryKey = "c";

/**
 * Can be assigned 'linked', 'unlinked' or non existant
 * E.g.: l=linked
 */
export const linkedLemmaQueryKey = "ul";
/**
 * Can be assigned the unique User Id's/Names
 * E.g.: u=Musterm
 */
export const userQueryKey = "u";

/**
 * Can be assigned 'pu', 'pr' or non existant
 * E.g.: v=pu
 */
export const visibilityQueryKey = "v";

/**
 * Can be assigned PageNumber followed by the page size. Separated by ','
 * E.g.: p=1,25
 */
export const paginationQueryKey = "p";

/**
 * The field to sort by. Can be preceeded by a '-'' to change the order from ascending to descending
 * E.g.: o=modified
 * or: o=-modified
 */
export const orderQueryKey = "o";

export interface CollectionCategory {
  id: string;
  categoryNameLocalizationKey: string;
}
const noCollectionCategory: CollectionCategory = {
  id: "",
  categoryNameLocalizationKey: "Collections.categories.no-category",
};

const possibleCollectionCategories: CollectionCategory[] = [
  noCollectionCategory,
  {
    id: "distribution",
    categoryNameLocalizationKey: "Collections.categories.distribution",
  },
  {
    id: "sense",
    categoryNameLocalizationKey: "Collections.categories.sense",
  },
  {
    id: "multi_word_expression",
    categoryNameLocalizationKey: "Collections.categories.multi_word_expression",
  },
  {
    id: "etymology",
    categoryNameLocalizationKey: "Collections.categories.etymolgy",
  },
  {
    id: "compound",
    categoryNameLocalizationKey: "Collections.categories.compound",
  },
  {
    id: "lemma",
    categoryNameLocalizationKey: "Collections.categories.lemma",
  },
];

const possibleCategoryMorphems = ["verbr", "bed", "redew", "etym", "wortb", "lemma"];

function MorphemToCategory(morphem: string): CollectionCategory {
  const index = possibleCategoryMorphems.indexOf(morphem);
  if (index === -1) return noCollectionCategory;
  return possibleCollectionCategories[index + 1];
}
export {
  noCollectionCategory,
  possibleCollectionCategories,
  MorphemToCategory,
  possibleCategoryMorphems,
};

export const collectionHomeFilters: ApiCollectioRequest = {
  page: 1,
  page_size: 25,
  ordering: "-modified",
};
