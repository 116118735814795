// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoText[data-v-4e8fe29d] {
  line-height: 1;
  border: 1px solid black;
  word-wrap: break-word;
  /* IE 5.5-7 */
  white-space: -moz-pre-wrap;
  /* Firefox 1.0-2.0 */
  white-space: pre-wrap;
  /* current browsers */
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;
}
tbody tr[data-v-4e8fe29d]:nth-of-type(odd) {
  background-color: var(--v-secondary-base);
}
[data-v-4e8fe29d]:root {
  --big-info-font-size: 1.095em;
  --medium-info-font-size: 1.075em;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
