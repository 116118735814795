<template>
  <vContainer>
    <h1>{{ $t("full_history") }}</h1>
    <Log></Log>
  </vContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Log from "@/components/Log.vue";

@Component({
  components: {
    Log,
  },
  name: "LogView",
})
export default class LogView extends Vue {}
</script>
