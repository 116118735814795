<template>
  <v-dialog
    v-model="dialog"
    width="1000"
  >
    <template #activator="{ on: on_dialog }">
      <v-tooltip top>
        <template #activator="{ on: on_tooltip }">
          <v-btn
            data-test="open-collection-details-button"
            color="primary"
            icon
            v-on="{ ...on_dialog, ...on_tooltip }"
          >
            <v-icon
              class="mb-1"
              size="18px"
              >mdi-information</v-icon
            >
          </v-btn>
        </template>
        <span>Details</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title
        class="headline grey"
        primary-title
      >
        {{ collection.title }}
      </v-card-title>

      <v-card-text>
        <v-icon class="showyIcons">mdi-archive</v-icon>
        <h3 class="showyIcons">{{ $t(category()) }}</h3>
        <hr class="seperator" />
        <v-icon class="showyIcons">mdi-account</v-icon>
        <h3 class="showyIcons">{{ collection.created_by }}</h3>
        <hr class="seperator" />
        <v-icon class="showyIcons">mdi-file-document</v-icon>
        <h3 class="showyIcons">{{ collection.description }}</h3>
        <hr class="seperator" />
        <v-icon class="showyIcons">mdi-message-text-outline</v-icon>
        <h3 class="showyIcons">{{ collection.comment }}</h3>
        <hr class="seperator" />
        <v-icon class="showyIcons">mdi-history</v-icon>
        <h3 class="showyIcons">{{ collection.modified.slice(0, 10) }}</h3>
        <hr class="seperator" />
        <v-icon class="showyIcons">mdi-account-multiple</v-icon>
        <h3
          class="showyIcons"
          v-for="(item, index) in collection.curator"
          v-bind:key="index"
        >
          {{ item }}
        </h3>
      </v-card-text>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import { CollectionsModule } from "../store/modules/collections";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "DetailedProperties",
})
export default class DetailedProperties extends Vue {
  @Prop(String) readonly CollId!: number;

  dialog: boolean = false;

  get collection() {
    return CollectionsModule.currentCollection;
  }

  category() {
    return CollectionsModule.currentCollectionCategory.categoryNameLocalizationKey;
  }

  // lifecycle hook
  mounted() {}

  created() {}

  updated() {}

  destroyed() {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.seperator {
  width: 50px;
}

.showyIcons {
  margin-left: 13px;
  float: left;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

hr {
  clear: both;
}
</style>
