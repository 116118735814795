<template>
  <div v-if="dialog">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <h2>{{ $t("HeaderSelect.title") }}</h2>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-col
      xl="mb-6"
      lg="mb-6"
      md="mb-6"
      sm="mb-6"
      xs="mb-6"
    >
      <v-row>
        <v-btn
          :data-test="`header-select-${pre.value}`"
          v-for="pre in presets"
          :key="pre.value"
          text
          block
          @click="clickPreset(pre)"
          >{{ pre.value }}</v-btn
        >
        <v-btn
          data-test="header-select-hide-empty-cols"
          text
          block
          @click="removeEmptyColoumns()"
          >{{ $t("HeaderSelect.empty") }}</v-btn
        >
      </v-row>
    </v-col>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            data-test="header-select-name-filter"
            name="search"
            label="Filter"
            v-model="namefilter"
            clearable
          ></v-text-field>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :data-test="`header-select-${dH.text}-toggle-visible`"
        v-for="dH in header"
        :key="dH.value"
        @click="switchShow(dH.value)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ dH.text }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-checkbox
            @click="switchShow(dH.value)"
            v-model="dH.show"
          ></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Logger from "../services/LoggerService";
import { CollectionsModule } from "../store/modules/collections";
import { ApiTag } from "../static/apiModels";
import { SettingsModule } from "../store/modules/settings";
import DocHeaderFilter from "./DocHeaderFilter.vue";
import { HeadersModule } from "../store/modules/headers";
import Preset from "../models/Preset";

@Component({
  components: { draggable, DocHeaderFilter },
  name: "HeaderSelect",
})
export default class HeaderSelect extends Vue {
  l = new Logger("HeaderSelect.vue");

  SM = SettingsModule;

  HM = HeadersModule;

  namefilter: string = "";

  // @Watch('namefilter')
  // onNamefilterChange(){
  //   if(!this.namefilter) return;
  //   this.
  // }

  get header() {
    return HeadersModule.getAllHeader.filter(
      (header) =>
        !this.namefilter || header.text.toLowerCase().includes(this.namefilter.toLowerCase()),
    );
  }

  removeEmptyColoumns() {
    const tempThis = this;
    let deleteHead = true;
    // eslint-disable-next-line
    tempThis.HM.getShownHeaders.forEach(function (head) {
      deleteHead = true;
      // @ts-ignore
      // eslint-disable-next-line
      tempThis.collection.documents.forEach(function (doc) {
        // @ts-ignore
        // eslint-disable-next-line
        if (doc?.elastic_content?._source[head.pathInObject] !== undefined) {
          deleteHead = false;
        }
      });
      if (deleteHead === true && head.value !== "es_id" && head.value !== "tag") {
        // @ts-ignore
        tempThis.HM.swapShow(head.value);
      }
    });
  }

  @Watch("$route")
  onRouteChange(before: any, after: any) {
    this.l.debug("routing", before, after);
    this.SM.setHeaderDialog(false);
  }

  get collection() {
    return CollectionsModule.currentCollection;
  }

  get presets() {
    return this.HM.getPresets;
  }

  get dialog() {
    return this.SM.getHeaderDialog;
  }

  set dialog(val: boolean) {
    this.SM.setHeaderDialog(false);
  }

  clickPreset(preset: Preset) {
    this.HM.doPresets(preset);
  }

  switchShow(Ele: string) {
    this.HM.swapShow(Ele);
  }

  selection: ApiTag[] = [];
}
</script>

<style scoped lang="scss"></style>
