<template>
  <v-container>
    <template v-if="!loading">
      <v-container fluid>
        <h2>Aufgabe bearbeiten</h2>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Step </v-subheader>
          </v-col>
          <v-col cols="6">
            <v-select
              :data-test="`${componentName}-step-select`"
              v-model="task.step"
              :items="stepItems"
              item-text="name"
              item-value="value"
              label="Select"
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Status </v-subheader>
          </v-col>

          <v-col cols="6">
            <v-select
              :data-test="`${componentName}-status-select`"
              v-model="task.status"
              :items="statusItems"
              item-text="name"
              item-value="value"
              label="Select"
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Zugeordnetes Lemma </v-subheader>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              :data-test="`${componentName}-lemma-select`"
              v-model="selLemma"
              :items="lemmata"
              item-text="lemmatisierung"
              value="task.lemma_name"
              :loading="lemmaLoading"
              :search-input.sync="search"
              clearable
              label="Lemmatisierung auswählen"
              return-object
              dense
              filled
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Zugeordneter User </v-subheader>
          </v-col>

          <v-col cols="6">
            <ApiSelect
              :data-test="`${componentName}-user-select`"
              itemText="username"
              label="user"
              type="users"
              @input="l.debug('as', $event)"
              :multiple="false"
              v-model="assignedUser"
              :filterValue="assignedUser"
            ></ApiSelect>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Fälligkeitsdatum </v-subheader>
          </v-col>
          <v-col cols="6">
            <v-menu
              :data-test="`${componentName}-end-date-select`"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :data-test="`${componentName}-end-date-textfield`"
                  v-model="date"
                  label="Picker in menu"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  :data-test="`${componentName}-end-date-cancel`"
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :data-test="`${componentName}-end-date-ok`"
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="6">
            <v-subheader> Kommentar </v-subheader>
          </v-col>

          <v-col cols="6">
            <v-textarea
              :data-test="`${componentName}-comment-textarea`"
              name="input-7-1"
              filled
              label="Kommentar"
              v-model="task.description"
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
        <v-btn
          :data-test="`${componentName}-confirm-edit-button`"
          block
          color="primary"
          style="float: left"
          @click="editCurrentTask()"
          >Bearbeiten</v-btn
        >
        <v-btn
          :data-test="`${componentName}-cancel-edit-button`"
          block
          color="error"
          style="float: left"
          @click="cancelCreate()"
          >Abbrechen</v-btn
        >
      </v-container></template
    >
    <template v-else>
      <CatSpinner></CatSpinner>
    </template>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { TaskModule } from "@/store/modules/task";
import { ApiTask, ApiTaskPost, ApiUser, SingleLemma } from "@/static/apiModels";

import { LemmaModule } from "@/store/modules/lemma";
import ApiSelect from "./ApiSelect.vue";

import { UsersModule } from "../store/modules/users";
import Logger from "../services/LoggerService";
import CatSpinner from "./CatSpinner.vue";

@Component({
  components: {
    ApiSelect,
    CatSpinner,
  },
  name: "TaskEdit",
})
export default class TaskEdit extends Vue {
  componentName = "TaskEdit";

  l = new Logger("TaskEdit.vue");

  LM = LemmaModule;

  menu = null;

  date = "";

  oldTask: ApiTaskPost | null = null;

  step: string = "";

  status: string = "";

  selLemma: SingleLemma | null = null;

  user: string = "";

  comment: string = "";

  search: string = "";

  dateDue: Date = new Date();

  _searchTimerId: any;

  assignedUser: ApiUser | null = null;

  createLoading: boolean = false;

  get stepItems() {
    return TaskModule.stepItems;
  }

  get statusItems() {
    return TaskModule.status;
  }

  TM = TaskModule;

  UM = UsersModule;

  get users() {
    return this.UM.users;
  }

  get lemmaLoading() {
    return this.LM.loading;
  }

  get lemmata() {
    return this.LM.searchLemma;
  }

  get task() {
    return this.TM.currentTask ? this.TM.currentTask : ({} as ApiTask);
  }

  get id() {
    return +this.$route.params.id;
  }

  get loading() {
    return this.TM.singleLoading && this.createLoading;
  }

  get lemma() {
    return LemmaModule.currentLemma;
  }

  @Watch("search")
  onSearchChange() {
    if (!this.search || this.search.length === 0 || this.search === "") return;
    if (this.lemma && this.search === this.lemma.org) return;
    // eslint-disable-next-line no-underscore-dangle
    clearTimeout(this._searchTimerId);
    // eslint-disable-next-line no-underscore-dangle
    this._searchTimerId = setTimeout(() => {
      LemmaModule.fetchSearchLemma({
        lemmatisierung: this.search,
        has__simplex: 2,
        page_size: 20,
      });
    }, 500);
  }

  @Watch("lemma.simplex")
  onSimplexChange() {
    if (this.task && !this.task.lemma.match(/api\/\d*/g)) {
      this.l.log("Lemma matches");
    }
  }

  // Called when a Task has been edited
  // This function sets current of the old task to false
  // and creates a new one with true
  editCurrentTask() {
    if (this.oldTask) {
      this.oldTask.current = false;
    }

    // Old Task
    const oldTask = {
      status: this.oldTask ? this.oldTask.status : this.statusItems[0].value,
      step: this.oldTask ? this.oldTask.step : this.stepItems[0].value,
      deadline: this.oldTask ? (this.oldTask.deadline as Date) : null,
      user: this.oldTask ? this.oldTask.user : null,
      description: this.oldTask ? this.oldTask.description : null,
      current: false,
      finished_date: new Date().toISOString(),
      lemma: this.oldTask ? this.oldTask.lemma : "",
    };

    // Get the assigned User
    let assignedUserUrl = null;
    if (this.oldTask) {
      assignedUserUrl = this.oldTask.user;
    }

    // get the assigned Lemma
    let assignedLemma = "";
    if (this.task && this.selLemma) {
      assignedLemma = this.selLemma.url;
    }

    // Create a new Task
    const currTask: ApiTaskPost = {
      status: this.task ? this.task.status : this.statusItems[0].value,
      step: this.task ? this.task.step : this.stepItems[0].value,
      deadline: this.date ? new Date(this.date) : null,
      user: this.assignedUser ? this.assignedUser.url : assignedUserUrl,
      description: this.task ? this.task.description : null,
      current: true,
      lemma: assignedLemma,
    };

    if (currTask.status !== oldTask.status || currTask.step !== oldTask.step) {
      oldTask.finished_date = new Date().toISOString();
    }

    TaskModule.editSingleTask({
      id: +this.$route.params.id,
      prevTask: oldTask,
    });

    TaskModule.createTask(currTask).then(() => {
      this.$router.push(`/task/${this.task.id}`);
    });
  }

  created() {
    this.createLoading = true;
    TaskModule.fetchCurrentTask(+this.$route.params.id).then(() => {
      this.oldTask = {
        status: this.task ? this.task.status : this.statusItems[0].value,
        step: this.task ? this.task.step : this.stepItems[0].value,
        deadline: this.task ? (this.task.deadline as Date) : null,
        user: this.task ? this.task.user : null,
        description: this.task ? this.task.description : null,
        finished_date: this.task ? (this.task.finished_date as Date) : null,
        current: false,
        lemma: this.task ? this.task.lemma : "",
      };
      if (this.oldTask.deadline) {
        this.date = new Date(this.oldTask.deadline).toISOString().substring(0, 10);
      }
      const user = this.task ? this.task.user : "";
      if (user !== "") {
        const curr = this.users.find(
          // eslint-disable-next-line func-names
          function (value) {
            return value.url === user;
          },
        );
        this.assignedUser = curr || null;
      }
      if (this.task !== null && this.task.lemma) {
        LemmaModule.fetchSingleLemmaByUrl(this.task.lemma).then(() => {
          this.selLemma = LemmaModule.currentLemma;
          if (LemmaModule.currentLemma) LemmaModule.appendLemma(LemmaModule.currentLemma);
        });
      }
      this.createLoading = false;
    });
  }

  cancelCreate() {
    this.$router.push(`/task/`);
  }
}
</script>
