import axios from "axios";
// import { AuthModule } from '@/store/modules/auth';

const api = axios.create({
  baseURL: process.env.VUE_APP_IMAGEDATASERVER,
  timeout: 100000,
});

export const getImagesOfDrawer = (drawer: string) =>
  api.get(`/iiif/presentation/hk-${drawer.toUpperCase()}/manifest`);

export type DrawerWithScansJson = {
  "@context": string;
  "@id": string;
  "@type": string;
  label: string;
  viewingHint: string;
  description: string;
  license: string;
  attribution: string;
  members: {
    "@id": string;
    "@type": string;
    label: string;
  }[];
  manifests: {
    "@id": string;
    "@type": string;
    label: string;
  }[];
};

function getJsonOfDrawersWithScans(): Promise<DrawerWithScansJson> {
  return api.get("/iiif/presentation/collection/hk").then((response) => {
    return response.data as DrawerWithScansJson;
  });
}

export const fetchLabelsOfDrawersWithScans = (): Promise<string[]> => {
  return getJsonOfDrawersWithScans().then((response) => {
    return response.members.map((drawer) => drawer.label);
  });
};

export default api;
