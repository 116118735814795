<template>
  <RecycleScroller
    data-test="image-scroller"
    class="scroller"
    :items="pics"
    width="500px"
    style="width: 500px"
    :item-size="32"
    v-slot="{ item }"
    key-field="label"
  >
    <img
      width="500px"
      height="400px"
      :src="item.src"
      :alt="item.alt ? item.alt : 'image in scroller, missing alt text'"
    />
    {{ item.label }}
  </RecycleScroller>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { NotificationsModule } from "@/store/modules/notifications";
import Logger from "../services/LoggerService";

@Component({
  name: "ImageScroller",
})
export default class ImageScroller extends Vue {
  @Prop() images: any;

  l = new Logger("ImageScroller.vue");

  showMSG(msg: string) {
    NotificationsModule.setNeutral({
      message: msg,
      icon: "",
    });
  }

  get pics(): string[] {
    return this.images || [];
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
