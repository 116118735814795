import api from ".";

export interface DjangoDBVersion {
  data: {
    version: {
      sw: String;
    };
  };
}

export const getDjangoVersion = (): Promise<DjangoDBVersion> => {
  return api.get(`/version`);
};
