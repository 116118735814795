<template>
  <span>
    <v-dialog
      v-model="dialog"
      @close="dialog = false"
      width="75vw"
    >
      <v-card>
        <v-card-title>
          <h2>{{ $t("TeiEditDialog.edit") }}: {{ DM.getEditDocId }}</h2>
        </v-card-title>
        <v-layout>
          <v-container>
            <v-form>
              <v-row>
                <v-text-field
                  data-test="tei-edit-dialog-user"
                  v-model="user"
                  class="login"
                  label="User"
                ></v-text-field>
                <v-text-field
                  data-test="tei-edit-dialog-password"
                  v-model="password"
                  class="login"
                  :type="'password'"
                  label="Password"
                ></v-text-field>
                <v-btn
                  data-test="tei-edit-dialog-get-doc-btn"
                  type="submit"
                  @click="documentXML"
                  >Get</v-btn
                >
              </v-row>
            </v-form>
            <v-row>
              <CatSpinner v-if="loading"></CatSpinner>
              <div v-if="!user || !password">Bitte elastic search userdaten eingeben</div>
              <div
                id="TeiEditDialog"
                v-else-if="!serverError && !loading && code"
              >
                <codemirror
                  data-test="tei-edit-dialog-xml-editor"
                  v-if="code"
                  id="xml"
                  v-model="code"
                  :options="cmOptions"
                ></codemirror>
                <span v-else>please enter your credentials and hit get</span>
              </div>
              <div v-else-if="!loading && serverError">
                <h3>
                  The document's xml could not be found.<br />
                  please complain to the techguys and make sure to include the document's id:
                  {{ DM.getEditDocId }}. cheers!
                </h3>
              </div>
            </v-row>
            <v-row>
              <v-btn
                data-test="tei-edit-dialog-submit-btn"
                v-if="code"
                @click="saveTEIDocument()"
                :disabled="getUpdateXMLLoading"
                block
                >Submit<v-progress-circular
                  indeterminate
                  size="9"
                  v-if="getUpdateXMLLoading"
                ></v-progress-circular>
              </v-btn>
            </v-row>
          </v-container>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="false"
      v-model="errorDialog"
    >
      <v-card>
        <v-card-title class="headline">Could not save</v-card-title>
        <v-card-text>
          {{ error }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script lang="ts">
/* eslint-disable no-underscore-dangle */
import { Component, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import { codemirror } from "vue-codemirror";
import { EditorConfiguration } from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/theme/shadowfox.css";
// @ts-ignore
import CatSpinner from "@/components/CatSpinner";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import { SettingsModule } from "../store/modules/settings";
import { DocumentsModule } from "../store/modules/documents";
import { NotificationsModule } from "../store/modules/notifications";
import { getScans, putScan } from "../api/DBdata/Scans";

@Component({
  components: { SnackbarSwitch, DarkmodeSwitch, codemirror, CatSpinner },
  name: "TeiEditDialog",
})
export default class TeiEditDialog extends Vue {
  // @ts-ignore

  DM = DocumentsModule;

  get loading() {
    return DocumentsModule.getXmlLoading;
  }

  get serverError() {
    return DocumentsModule.getEditDocError;
  }

  cmOptions: EditorConfiguration = {
    theme: "shadowfox",
    // styleActiveLine: true,
    lineNumbers: true,
  };

  thisOneWeSend: any = "";

  l = new Logger("TeiEditDialog.vue");

  SM = SettingsModule;

  get dialog() {
    this.l.debug("open dialog", this.SM.getTeiEditDialog);
    if (this.SM.getTeiEditDialog) {
      if (!this.DM.getEditDocId) {
        NotificationsModule.setError({
          message: "no doc selected",
          icon: ":/",
        });
        this.dialog = false;
        return false;
      }
    }
    return this.SM.getTeiEditDialog;
  }

  set dialog(val: boolean) {
    this.SM.setTeiEditDialog(false);
  }

  get getUpdateXMLLoading() {
    return this.DM.getUpdateXMLLoading;
  }

  get editId() {
    return this.DM.getEditDocId;
  }

  @Watch("editId")
  onEditidChange(before: string, after: string) {
    if (this.DM.getEditDocId && before !== after && this.user && this.password) {
      this.documentXML();
    }
  }

  get user() {
    return this.DM.editDoc.user;
  }

  set user(val) {
    this.DM.setEditUser(val);
  }

  get password() {
    return this.DM.editDoc.password;
  }

  set password(val) {
    this.DM.setEditPW(val);
  }

  get errorDialog() {
    return !!this.error;
  }

  set errorDialog(value) {
    this.l.debug("set Error dialog");
  }

  string = "";

  get document() {
    return this.SM.getTeiEditDialog;
  }

  get code() {
    this.l.debug("code", DocumentsModule.getDocXML);
    return DocumentsModule.getDocXML || null;
  }

  set code(val) {
    this.l.debug("WHATS HAPPENING HERE", val);
    this.thisOneWeSend = val;
  }

  async documentXML() {
    this.l.debug("id is", DocumentsModule.getEditDocId);
    this.error = null;
    const args: any = {
      // @ts-ignore
      id: DocumentsModule.getEditDocId,
      creds: {
        username: this.user,
        password: this.password,
      },
    };
    const docXML = await DocumentsModule.getDocumentXML(args);

    return docXML;
  }

  error: null | string = null;

  async saveTEIDocument() {
    this.error = null;
    this.DM.setUpdateXMLLoading(true);
    if (!DocumentsModule.getEditDocId) return;
    const entryToSend: any = {
      id: DocumentsModule.getEditDocId,
      sid: "",
      lemma: "",
      entry: this.thisOneWeSend,
    };
    const creds: any = {
      username: "mhasenberger",
      password: "Turn8neighbor",
    };

    if (!entryToSend || !entryToSend.id) return;

    await getScans({ id: entryToSend.id, creds });
    putScan(entryToSend, creds)
      .then((resp) => {
        this.l.debug("now there is an response", resp);
        NotificationsModule.setSuccess({
          message: `successfully updated Document ${entryToSend.id}`,
          icon: ":D",
        });
        this.DM.setUpdateXMLLoading(false);
      })
      .catch((err) => {
        this.l.debug("error in updating document", err);
        // this.l.debug('error on safe', JSON.stringify(err));
        NotificationsModule.setError({
          fullData: err.response.data,
          message: `could not update Document ${entryToSend.id}; error on evaluation or missing permissions.`,
          icon: ":(",
        });
        this.error = "Error: error on evaluation or missing permissions.";
        this.DM.setUpdateXMLLoading(false);
      });
  }

  destroyed() {
    this.SM.setTeiEditDialog(false);
  }
}
</script>

<style lang="scss">
#TeiEditDialog {
  height: 69vh !important;
}

#TeiEditDialog .CodeMirror {
  height: 100% !important;
}

#TeiEditDialog .vue-codemirror {
  height: 100% !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.login {
  margin: 10px;
}

#xml {
  width: 100%;
  height: 90vh;
}
</style>
