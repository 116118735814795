<template>
  <v-layout justify-center>
    <template v-if="!loading">
      <vContainer
        fluid
        justify-center
      >
        <v-layout row>
          <v-flex xs-12>
            <h1 style="float: left">Aufgabe Nr. {{ $route.params.id }}</h1>
            <v-btn
              :data-test="`${componentName}-goto-edit-task`"
              fab
              small
              style="float: left; margin-left: 20px; margin-top: 4px"
              @click="goToEdit($route.params.id)"
              color="primary"
            >
              <v-icon>mdi-border-color</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-flex
          xs-12
          md-6
        >
          <v-layout row>
            <v-flex xs-12>
              Aufgaben Status:
              {{ statusItems.find((o) => o.value === task.status).name }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs-12>
              Aufgaben step:
              {{ stepItems.find((o) => o.value === task.step).name }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs-12> Zugewiesener User: {{ task.user_name }} </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs-12> Zugewiesenes Lemma: {{ task.lemma_name }} </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs-12>
              Zuletzt Bearbeitet am:
              {{ new Date(task.last_edited).toDateString() }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs-12> Kommentar: {{ task.comment }} </v-flex>
          </v-layout>
        </v-flex>
        <v-row no-gutters>
          <v-flex
            xs-12
            md-12
          >
            <v-row>
              <v-col
                cols="9"
                offset-md="1"
              >
                <v-flex xs-9>
                  <h2 style="float: left">Historie der Bearbeitungen:</h2>
                </v-flex>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="9"
                offset-md="1"
              >
                <v-spacer></v-spacer>
                <v-flex xs-9>
                  <v-data-table
                    :data-test="`${componentName}-history-table`"
                    :loading="historyLoading"
                    :items="history"
                    :headers="headers"
                    :options.sync="pagination"
                    @click:row="goToKomp($event.id)"
                    fixed-header
                  >
                    <template v-slot:[`item.deadline`]="{ item }">
                      {{
                        item.deadline
                          ? new Date(item.deadline).toDateString()
                          : "Derzeit keine Deadline vorhanden"
                      }}
                    </template>
                    <template v-slot:[`item.last_edited`]="{ item }">
                      {{ new Date(item.last_edited).toDateString() }}
                      {{ new Date(item.last_edited).toLocaleTimeString() }}
                    </template>
                    <template v-slot:[`item.finished_date`]="{ item }">
                      {{
                        item.finished_date
                          ? new Date(item.finished_date).toDateString()
                          : "Kein Datum vorhanden"
                      }}
                    </template>
                  </v-data-table>
                </v-flex>
              </v-col>
            </v-row>
          </v-flex>
        </v-row>
      </vContainer>
    </template>
    <template v-else>
      <CatSpinner></CatSpinner>
    </template>
  </v-layout>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { TaskModule } from "@/store/modules/task";

import { AuthModule } from "@/store/modules/auth";
import { ApiTask } from "@/static/apiModels";
import Logger from "../services/LoggerService";
import CatSpinner from "./CatSpinner.vue";

@Component({
  components: {
    CatSpinner,
  },
  name: "TaskSingleView",
})
export default class TaskSingleView extends Vue {
  componentName = "TaskSingleView";

  l = new Logger("TaskSingleView.vue");

  TM = TaskModule;

  AM = AuthModule;

  currentPage: number = 1;

  itemsPerPageItems = [10, 25, 50, 100, 500, 1000, 2000];

  pagination: any = {
    itemsPerPage: 25,
    sortBy: ["last_edited"],
    sortDesc: [true],
  };

  headers = [
    { text: "ID", value: "id" },
    { text: "Lemma", value: "lemma_name", sortable: true },
    { text: "Fällig", value: "deadline", sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Step", value: "step", sortable: true },
    { text: "Zuletzt bearbeitet", value: "last_edited", sortable: true },
    { text: "Description", value: "comment", sortable: false },
    { text: "Assigned User", value: "user_name", sortable: true },
    { text: "Abgeschlossen am", value: "finished_date", sortable: true },
  ];

  goToEdit(id: number) {
    this.$router.push(`/task/${id.toString()}/edit`);
  }

  get filters() {
    return TaskModule.filters;
  }

  get task() {
    return TaskModule.currentTask;
  }

  get history() {
    let results: ApiTask[] = [];

    if (!this.historyLoading) {
      results = TaskModule.tasks ? TaskModule.tasks.results : [];
    }

    return results;
  }

  get id() {
    return +this.$route.params.id;
  }

  get loading() {
    return TaskModule.singleLoading;
  }

  get historyLoading() {
    return TaskModule.loading;
  }

  get stepItems() {
    return TaskModule.stepItems;
  }

  get statusItems() {
    return TaskModule.status;
  }

  @Watch("$route", { immediate: true, deep: true })
  onIdChange(val: any) {
    this.l.log("ID updated", val);
    this.TM.fetchCurrentTask(+this.$route.params.id);
  }

  goToKomp(url: string) {
    // go to a another Lemma based on its URL
    this.l.log(url);
    this.l.log("gotolemma", url);
    this.$router.replace({
      path: `/task/${url}`,
    });
  }

  @Watch("pagination", { deep: true })
  onPaginationChange(val: any) {
    this.TM.setFilters({
      lemma: this.task ? this.task.lemma_name : "",
      current: true,
    });
    this.l.log("Switching page to val", val.page);
    if (val.page > this.currentPage) {
      this.TM.fetchNextTaskPage();
      this.currentPage += 1;
    } else if (val.page < this.currentPage) {
      this.TM.fetchPreviousTaskPage();
      this.currentPage -= 1;
    } else {
      this.l.log("Load page");
      this.TM.fetchTasks({
        current: false,
        lemma: this.task ? this.task.lemma_name : "",
        page_size: this.pagination.itemsPerPage,
        page: this.pagination.page,
        ordering: `${this.pagination.sortDesc[0] ? "-" : ""}${this.pagination.sortBy}`,
      });
    }
  }

  beforeCreate() {
    TaskModule.fetchCurrentTask(+this.$route.params.id);
  }
}
</script>
