// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finduz-italico[data-v-13158afb] {
  font-family: "WOFF Fiduz-Italic";
}
.finduz-regular[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
}

/*
table * {
  font-family: "WOFF Fiduz-Regular";
  font-feature-settings: "kern" on, "liga" on, "calt" on;
			-moz-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-webkit-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-ms-font-feature-settings: "kern" on, "liga" on, "calt" on;
			-o-font-feature-settings: "kern" on, "liga" on, "calt" on;
}
*/
ul[data-v-13158afb] {
  list-style-type: none;
  padding: 0;
}
h2[data-v-13158afb] {
  float: left;
}
li[data-v-13158afb] {
  display: inline-block;
  margin: 0 10px;
}
a[data-v-13158afb] {
  color: #42b983;
}
hr[data-v-13158afb] {
  clear: both;
  margin-bottom: 10px;
}
.current[data-v-13158afb] {
  background-color: #42b983 !important;
}
.delete[data-v-13158afb] {
  cursor: pointer;
}
.crossfade[data-v-13158afb] {
  cursor: crosshair;
}
td[data-v-13158afb],
th[data-v-13158afb] {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
  border-right: 1px solid rgba(255, 255, 255, 0.12);
}
.noColorItalico[data-v-13158afb] {
  font-family: "WOFF Fiduz-Italic";
}
.noColorRegular[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
}
.redColsDark[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(85, 0, 0);
}
.greenColsDark[data-v-13158afb] {
  font-family: "WOFF Fiduz-Italic";
  background-color: rgb(0, 85, 0);
}
.yellowColsDark[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(85, 85, 0);
}
.blueColsDark[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(0, 0, 85);
}
.redColsLight[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(255, 170, 170);
}
.greenColsLight[data-v-13158afb] {
  font-family: "WOFF Fiduz-Italic";
  background-color: rgb(170, 255, 170);
}
.yellowColsLight[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(255, 255, 170);
}
.blueColsLight[data-v-13158afb] {
  font-family: "WOFF Fiduz-Regular";
  background-color: rgb(170, 170, 255);
}
.stickysidecol[data-v-13158afb] {
  position: sticky;
  left: 0;
  background-color: rgba(255, 255, 255, 0.12);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
