import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      de,
      en,
    },
    current: "de",
  },
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: {
        primary: "#ee44aa",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        danger: "#91112d",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      light: {
        primary: "#ee44aa",
        secondary: "#C9C9C9",
        accent: "#82B1FF",
        error: "#FF5252",
        danger: "#91112d",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
