<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "Main",
})
export default class Main extends Vue {
  l = new Logger("Main.vue");
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
