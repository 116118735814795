<template>
  <v-dialog
    v-model="dialog"
    width="1000"
  >
    <v-card>
      <v-card-title
        class="headline grey"
        primary-title
        >{{ $t("EditTags.title") }}</v-card-title
      >

      <v-card-text>
        {{ $t("EditTags.information") }}
        <v-text-field
          data-test="edit-tags-search"
          v-model="search"
          label="Tags"
          v-on:keyup="onSearchCommitted()"
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="localTags"
          must-sort
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
          }"
          :options.sync="pagination"
          @contextmenu:row="handleClick($event, item)"
        >
          <!--
            v-slot:item -> acces the slot prop "item" from the v-data-table component
            item : tag -> rename property "item" to tag for usagae within the code. This is just for beauty reasons
            -->
          <template v-slot:item="{ item: tag }">
            <tr
              :data-test="`edit-tags-row-${tag.name}`"
              v-if="editTag.edit === true"
              @contextmenu.prevent.stop="handleClick($event, tag)"
            ></tr>
            <tr v-if="editTag.tag === tag.url && editTag.edit === true">
              <td>
                <v-text-field
                  :data-test="`edit-tags-name-${tag.name}`"
                  v-model="editingTag.name"
                  height="20"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  :data-test="`edit-tags-color-${tag.name}`"
                  v-model="editingTag.color"
                  height="20"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  :data-test="`edit-tags-emoji-${tag.name}`"
                  v-model="editingTag.emoji"
                  height="20"
                ></v-text-field>
              </td>
              <td>
                <v-btn
                  :data-test="`edit-tags-submit-${tag.name}`"
                  fab
                  small
                  @click="submitEdit()"
                  color="primary"
                >
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  :data-test="`edit-tags-cancel-${tag.name}`"
                  fab
                  small
                  @click="cancelEdit()"
                  color="primary"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="editTag.edit === false || editTag.tag != tag.url"
              @contextmenu.prevent.stop="handleClick($event, tag)"
            >
              <td>{{ tag.name }}</td>
              <td>{{ tag.emoji }}</td>
              <td>{{ tag.color }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <VueSimpleContextMenu
      data-test="edit-tags-context-menu"
      :elementId="'ContextMenu'"
      :options="optionsArray"
      :ref="'vueSimpleContextMenu'"
      @option-clicked="optionClicked"
    ></VueSimpleContextMenu>

    <v-dialog
      v-model="confirmIt"
      max-width="290"
    >
      <v-card>
        <v-card-text>{{ $t("EditTags.confirmation") }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            data-test="edit-tags-delete-confirm"
            color="primary"
            text
            @click="deleteTag()"
            >{{ $t("basics.confirm") }}</v-btn
          >

          <v-btn
            data-test="edit-tags-delete-cancel"
            color="primary"
            text
            @click="confirmIt = false"
            >{{ $t("basics.cancle") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Watch, Vue } from "vue-property-decorator";
import { ApiTag, ApiTagPost } from "@/static/apiModels";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import { TagsModule } from "../store/modules/tags";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import VueSimpleContextMenu from "./simple-context-menu.vue";

@Component({
  components: { SnackbarSwitch, DarkmodeSwitch, VueSimpleContextMenu },
  name: "editTags",
})
export default class EditTags extends Vue {
  l = new Logger("editTags.vue");

  search: string = "";

  editingTag: ApiTagPost = { name: "" };

  confirmIt: boolean = false;

  editTag = {
    tag: "",
    edit: false,
  };

  rowsPerPageItems = [5, 10, 25, 50];

  pagination = {
    rowsPerPage: 25,
  };

  TM = TagsModule;

  get dialog() {
    return this.TM.getEditDialog;
  }

  set dialog(val: boolean) {
    this.TM.setEditDialog(false);
    this.editTag = {
      tag: "",
      edit: false,
    };
  }

  localTags: ApiTag[] = this.TM.getTags;

  handleClick(event: any, item: any) {
    // @ts-ignore
    this.$refs.vueSimpleContextMenu.showMenu(event, item);
  }

  cancelEdit() {
    this.editTag.tag = "";
    this.editTag.edit = false;
  }

  onSearchCommitted() {
    const allTags: ApiTag[] = this.TM.getTags;
    const filteredTags: ApiTag[] = [];
    const outerThis = this;
    allTags.forEach((item) => {
      if (item.name.toLowerCase().includes(outerThis.search.toLowerCase())) {
        filteredTags.push(item);
      }
    });
    this.localTags = filteredTags;
  }

  revertFilter() {
    this.TM.fetchTags();
  }

  @Watch("dialog")
  doRevertFilter() {
    this.TM.fetchTags();
  }

  submitEdit() {
    const changedThing: ApiTag = {
      color: "",
      name: "",
      emoji: "",
      url: "",
      es_documents: [],
      meta: null,
    };
    let toBeEdited = { ...changedThing };
    this.localTags.forEach((tagListTag: ApiTag) => {
      if (tagListTag.url === this.editTag.tag) {
        toBeEdited = tagListTag;
        // @ts-ignore
        toBeEdited.color = this.editingTag.color;
        toBeEdited.name = this.editingTag.name;
        toBeEdited.meta = this.editingTag.meta;
        // @ts-ignore
        toBeEdited.emoji = this.editingTag.emoji;
      }
    });
    this.TM.editTagServerside(toBeEdited);

    this.editTag.tag = "";
    this.editTag.edit = false;
  }

  deleteTag() {
    if (this.editTag.tag != null) {
      // @ts-ignore
      this.TM.deleteTagOnServer(this.editTag.tag);
      this.editTag.tag = "";
      this.editTag.edit = false;
      this.confirmIt = false;

      // Refresh the tag List after Tag deletion
      this.onSearchCommitted();
    }
  }

  optionsArray = [
    {
      name: "Edit",
      slug: "edit",
    },
    {
      name: "Delete",
      slug: "delete",
    },
  ];

  headers = [
    { text: "Name", value: "name" },
    { text: "emoji", value: "emoji" },
    { text: "color", value: "color" },
  ];

  optionClicked(event: any) {
    switch (event.option.slug) {
      case "delete":
        this.editTag = {
          tag: event.item.url,
          edit: false,
        };
        this.confirmIt = true;
        break;
      case "edit":
        this.editTag = {
          tag: event.item.url,
          edit: true,
        };
        this.editingTag.color = event.item.color;
        this.editingTag.name = event.item.name;
        this.editingTag.meta = event.item.meta;
        this.editingTag.emoji = event.item.emoji;
        break;
      default:
        this.l.error("@dev did not implement this feature yet!!");
    }
  }
}
</script>
