import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate'
import VuexPersistence from "vuex-persist";
import { ISettingsState } from "./modules/settings";
import { INotificationState } from "./modules/notifications";
import { ICategoryState } from "./modules/categories";
import { IUserState } from "./modules/users";
import { IAuthState } from "./modules/auth";
import { ISearchState } from "./modules/search";
import { ITagsState } from "./modules/tags";
import { IHeaderState } from "./modules/headers";
import { IAnnotationState } from "./modules/annotations";
import { IDocumentState } from "./modules/documents";
import { ILemmaState } from "./modules/lemma";
import { ITaskState } from "./modules/task";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // @ts-ignore
  reducer: (state) => ({
    // @ts-ignore
    auth: state.auth,
    // @ts-ignore
    settings: state.settings,
    // @ts-ignore
    localScan: state.localScan,
  }),
});

Vue.use(Vuex);

export interface IRootState {
  settings: ISettingsState;
  notifications: INotificationState;
  categories: ICategoryState;
  annotations: IAnnotationState;
  users: IUserState;
  auth: IAuthState;
  search: ISearchState;
  tags: ITagsState;
  header: IHeaderState;
  documents: IDocumentState;
  lemma: ILemmaState;
  task: ITaskState;
}

export default new Vuex.Store<IRootState>({
  // @ts-ignore
  plugins: [vuexLocal.plugin],
});
