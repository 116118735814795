import axios from "axios";
// import { AuthModule } from '@/store/modules/auth';

const api = axios.create({
  baseURL: process.env.VUE_APP_ELASTICSEARCHURL,
  timeout: 100000,
});

/*
// Request interceptors
api.interceptors.request.use(
  config => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    if (AuthModule.token) {
      config.headers.Authorization = `Token ${AuthModule.token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
*/

export default api;
