import { AxiosResponse } from "axios";

import api from ".";

export const patchScans = (
  params: any[],
  creds: { username: string; password: string },
): Promise<AxiosResponse<any>> => {
  return api.patch(
    `/restvle/dicts/_qdb-TEI-02/entries/`,
    { entries: params },
    {
      headers: { Accept: "application/vnd.wde.v2+json" },
      withCredentials: true,
      auth: creds,
    },
  );
};

export const putScan = (
  params: any,
  creds: { username: string; password: string },
): Promise<AxiosResponse<any>> => {
  return api.put(`/restvle/dicts/_qdb-TEI-02/entries/${params.id}`, params, {
    headers: { Accept: "application/vnd.wde.v2+json" },
    withCredentials: true,
    auth: creds,
  });
};

export const getScans = (args: {
  id: string;
  creds: { username: string; password: string };
}): Promise<AxiosResponse<any>> => {
  return api.get(`/restvle/dicts/_qdb-TEI-02/entries/`, {
    headers: { Accept: "application/vnd.wde.v2+json" },
    withCredentials: true,
    auth: args.creds,
    params: {
      ids: args.id,
      lock: "true",
    },
  });
};
