<template>
  <v-main class="mt-0 pt-3">
    <v-container fluid>
      <h1 class="mx-4">
        {{ showDeleted ? "Gelöschte Sammlungen" : $t("collection") }}
      </h1>
      <v-row
        class="mx-6"
        justify="center"
        align="center"
      >
        <v-col cols="10">
          <v-text-field
            v-model="search"
            full-width
            append-icon="mdi-filter"
            append-outer-icon="mdi-magnify"
            @click:append-outer="onSearchSubmitted()"
            @click:append="showFilters()"
            :label="$t('Collections.search')"
            v-on:keyup.enter="onSearchSubmitted()"
            outline
            clearable
            @click:clear.prevent="onClearClicked()"
            data-test="search-field"
          ></v-text-field>
        </v-col>
        <v-col
          cols="2"
          class="pt-9"
        >
          <CollectionCategorySelection
            :selectionLabel="'Collections.categories-filter-label'"
            v-model="selectedCategoryFilter"
          ></CollectionCategorySelection>
        </v-col>
      </v-row>

      <v-row
        class="mb-2"
        justify="center"
      >
        <CollectionFilters v-if="filtersVisible"></CollectionFilters>
      </v-row>

      <v-row
        class="mb-1"
        justify="center"
      >
        <v-col md="auto">
          <v-btn-toggle
            class="justify-center"
            v-model="lemmaConnectedFilter"
            mandatory
          >
            <v-btn data-test="filter-lemma-connection-show-all">
              {{ $t("Collections.filter.lemma-connected.show-all") }}
            </v-btn>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  data-test="filter-lemma-connection-not-connected"
                  v-on="on"
                >
                  {{ $t("Collections.filter.lemma-connected.not-connected") }}
                </v-btn>
              </template>
              <span>{{ $t("Collections.filter.lemma-connected.tooltip.not-connected") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  data-test="filter-lemma-connection-connected"
                  v-on="on"
                >
                  {{ $t("Collections.filter.lemma-connected.only-connected") }}
                </v-btn>
              </template>
              <span>{{ $t("Collections.filter.lemma-connected.tooltip.only-connected") }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-data-table
        class="mx-6"
        :loading="loading"
        height="70vh"
        fixed-header
        :headers="headers"
        @click:row="goToColl($event.id)"
        :items="collections"
        :footer-props="{
          'items-per-page-options': itemsPerPageItems,
        }"
        :server-items-length="collectionsResponse ? collectionsResponse.count : 0"
        :options.sync="pagination"
        @contextmenu:row="handleClick($event, item)"
      >
        <template v-slot:item="{ item }">
          <tr
            :data-test="`collection-${item.id}`"
            @click="goToColl(item.id)"
            @contextmenu.prevent.stop="handleClick($event, item)"
          >
            <td
              :key="i"
              v-for="(h, i) of headers"
            >
              <!-- es_id -->
              <template v-if="h.value === 'public'">
                <v-icon :color="item.public ? 'success' : 'warning'">
                  {{ item.public ? "mdi-lock-open-variant" : "mdi-lock" }}
                </v-icon>
              </template>
              <template v-else-if="h.value === 'modified'">{{
                new Date(item[h.value]).toDateString()
              }}</template>
              <template v-else-if="h.value === 'category'">
                <p>{{ $t(getColCategoryTitleKey(item.category)) }}</p>
              </template>
              <template v-else>{{ item[h.value] }}</template>
            </td>
          </tr>
        </template>
      </v-data-table>

      <VueSimpleContextMenu
        :elementId="'ContextMenu'"
        :options="contextMenuOptionsArray"
        :ref="'vueSimpleContextMenu'"
        @option-clicked="optionClicked"
      ></VueSimpleContextMenu>

      <ConfirmationModal
        v-model="confirmDeletion"
        :text="$t('Collections.confirmation')"
        :effectedItemTitle="toBeDeleted ? toBeDeleted.title : ''"
        :action="() => deleteCollection()"
      />
      <ConfirmationModal
        v-model="confirmRestore"
        :text="$t('Collections.restoring_conf')"
        :effectedItemTitle="toBeRestored ? toBeRestored.title : ''"
        :action="() => restoreCollection()"
      />
    </v-container>
  </v-main>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { debounce } from "debounce";
import CollectionCategorySelection from "./CollectionCategorySelection.vue";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import { VuetifyDatatTablePagination } from "../static/types";
import {
  CollectionCategory,
  categoryQueryKey,
  linkedLemmaQueryKey,
  noCollectionCategory,
  orderQueryKey,
  paginationQueryKey,
  separatorQueryKey,
  titleQueryKey,
  userQueryKey,
  visibilityQueryKey,
  collectionHomeFilters,
} from "../static/collectionConstants";

import { CollectionsModule, getCollectionCategoryFromId } from "../store/modules/collections";
import VueSimpleContextMenu from "./simple-context-menu.vue";
import { ApiCollectioRequest, ApiCollectionResponse } from "../static/apiModels";
import CollectionFilters from "./CollectionFilters.vue";
import Logger from "../services/LoggerService";

import { SettingsModule } from "../store/modules/settings";
import ConfirmationModal from "./Utilities/ConfirmationModal.vue";

// eslint-disable-next-line no-shadow
enum lemmaConnectionOptions {
  "showAll",
  "onlyNotConnected",
  "onlyConnected",
}

@Component({
  // if you use components add them here
  components: {
    SnackbarSwitch,
    CollectionFilters,
    DarkmodeSwitch,
    VueSimpleContextMenu,
    CollectionCategorySelection,
    ConfirmationModal,
  },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "Collections",
})
export default class Collections extends Vue {
  l = new Logger("Collections.vue");

  CM = CollectionsModule;

  search: string = "";

  filtersVisible: boolean = false;

  showSearch: boolean = false;

  confirmDeletion: boolean = false;

  confirmRestore: boolean = false;

  itemsPerPageItems: number[] = [10, 25, 50, 100, 500, 1000, 2000];

  pagination: VuetifyDatatTablePagination = {
    page: 1,
    itemsPerPage: 25,
    sortBy: ["modified"],
    sortDesc: [true],
  };

  lemmaConnectedFilter: lemmaConnectionOptions = lemmaConnectionOptions.showAll;

  selectedCategoryFilter: CollectionCategory = noCollectionCategory;

  getColCategoryTitleKey(id: string) {
    return getCollectionCategoryFromId(id).categoryNameLocalizationKey;
  }

  FetchCollections() {
    if (this.showDeleted) {
      CollectionsModule.fetchDeletedCollections(this.collectionStoreFilters);
    } else {
      CollectionsModule.fetchCollections(this.collectionStoreFilters);
    }
  }

  @Watch("selectedCategoryFilter")
  OnSelectedCategoryFilterChange() {
    if (this.CM.filters.category === this.selectedCategoryFilter.id) return;
    this.CM.updateFilters({
      category: this.selectedCategoryFilter.id,
    });
  }

  clearFilters() {
    this.CM.setFilters({});
  }

  @Watch("lemmaConnectedFilter")
  OnNoLemmaFilterChange() {
    let hasConnectedLemma: boolean | undefined;
    if (this.lemmaConnectedFilter === lemmaConnectionOptions.onlyNotConnected) {
      hasConnectedLemma = true;
    } else if (this.lemmaConnectedFilter === lemmaConnectionOptions.onlyConnected) {
      hasConnectedLemma = false;
    } else {
      hasConnectedLemma = undefined;
    }
    if (this.CM.filters.lemma_id__isnull === hasConnectedLemma) return;
    this.CM.updateFilters({
      lemma_id__isnull: hasConnectedLemma,
    });
  }

  @Watch("pagination", { deep: true })
  onPaginationChange() {
    if (this.paginationSetFromFilters) {
      this.paginationSetFromFilters = false;
      return;
    }
    const { page } = this.pagination;
    const { itemsPerPage } = this.pagination;
    let ordering: string | undefined;

    if (this.pagination.sortBy?.length === 1) {
      ordering = (this.pagination.sortDesc?.[0] ? "-" : "") + this.pagination.sortBy;
    } else {
      ordering = undefined;
    }
    if (
      this.CM.filters.page === page &&
      this.CM.filters.page_size === itemsPerPage &&
      this.CM.filters.ordering === ordering
    )
      return;
    this.CM.updateFilters({
      page,
      page_size: itemsPerPage,
      ordering,
    });
  }

  deleteCollection() {
    this.confirmDeletion = false;
    if (!this.toBeDeleted) return;
    this.CM.deleteCollection(this.toBeDeleted);
  }

  restoreCollection() {
    this.confirmRestore = false;
    if (!this.toBeRestored) return;
    this.CM.restoreCollection(this.toBeRestored);
  }

  // enables clearing search before initializing the search (before Watch is triggered)
  onClearClicked() {
    this.clearTitle();
  }

  @Watch("search")
  onSearchCleared() {
    // if search got cleared
    if (!this.search || this.search.length === 0) {
      this.clearTitle();
    }
  }

  clearTitle() {
    if (this.CM.filters.title === "") return;
    this.CM.updateFilters({
      title: "",
    });
  }

  get collectionStoreFilters() {
    return CollectionsModule.filters;
  }

  @Watch("collectionStoreFilters", { deep: true })
  onStoreFiltersChanged() {
    if (!this.collectionStoreFilters) return;

    this.syncFieldsFromStoreFilters();
    this.syncFiltersToRoute();
  }

  private syncFiltersToRoute() {
    this.$router.push(this.filtersToQueryString(this.CM.filters)).catch((err) => {
      if (
        err.name !== "NavigationDuplicated" &&
        !err.message.includes("Avoided redundant navigation to current location")
      ) {
        this.l.error(err);
      }
    });
  }

  private filtersToQueryString(params: ApiCollectioRequest): string {
    const stringFilters: string[] = [];

    if (params.title && params.title.length > 0) {
      stringFilters.push(titleQueryKey.concat("=", encodeURIComponent(params.title!)));
    }

    if (params.created_by) {
      stringFilters.push(userQueryKey.concat("=", params.created_by.toString()));
    }

    if (params.public !== undefined) {
      stringFilters.push(visibilityQueryKey.concat("=", params.public.toString()));
    }

    if (params.page) {
      stringFilters.push(
        `${paginationQueryKey.concat("=") + params.page},${params.page_size ?? ""}`,
      );
    }

    if (params.ordering) {
      stringFilters.push(orderQueryKey.concat("=", encodeURIComponent(params.ordering)));
    }

    if (params.category) {
      stringFilters.push(categoryQueryKey.concat("=", encodeURIComponent(params.category)));
    }

    if (params.lemma_id__isnull !== undefined) {
      stringFilters.push(
        linkedLemmaQueryKey.concat("=", params.lemma_id__isnull ? "unlinked" : "linked"),
      );
    }

    const queryString = "?".concat(stringFilters.join(separatorQueryKey));
    return queryString;
  }

  get showDeleted(): boolean {
    return !!this.$route.path.match("deleted");
  }

  @Watch("showDeleted")
  onShowDeletedChange() {}

  debouncedFetchCollection = debounce(this.FetchCollections, 200);

  @Watch("$route", { immediate: true })
  onRouteChange() {
    if (this.$route.path.match("home")) {
      this.CM.setFilters(collectionHomeFilters);
      this.$router.push("/");
      return;
    }

    this.syncFieldsFromStoreFilters();
    this.debouncedFetchCollection();
  }

  @Watch("showDeleted", { immediate: true })
  onShowDeletedChanged() {
    if (this.showDeleted) {
      this.contextMenuOptionsArray[3].name = "Restore";
      this.contextMenuOptionsArray[3].slug = "restore";
    } else {
      // toggle back to the delete option if the normal collections were called
      this.contextMenuOptionsArray[3].name = "Delete";
      this.contextMenuOptionsArray[3].slug = "delete";
    }
  }

  onSearchSubmitted() {
    if (this.CM.filters.title === this.search) return;
    this.CM.updateFilters({
      title: this.search,
    });
  }

  get filtersFromQuery(): ApiCollectioRequest {
    const extractedFilters: ApiCollectioRequest = {};
    const { query } = this.$route;

    if (query[titleQueryKey]) {
      extractedFilters.title = String(query[titleQueryKey]);
    }

    if (query[categoryQueryKey]) {
      extractedFilters.category = String(query[categoryQueryKey]);
    }

    if (query[linkedLemmaQueryKey]) {
      extractedFilters.lemma_id__isnull = query[linkedLemmaQueryKey] === "unlinked";
    }

    if (query[userQueryKey]) {
      extractedFilters.created_by = Number(query[userQueryKey]);
    }

    if (query[visibilityQueryKey]) {
      extractedFilters.public = query[visibilityQueryKey] === "pu";
    }

    if (query[paginationQueryKey]) {
      const splitPagination: string[] = String(query[paginationQueryKey]).split(",");
      extractedFilters.page = Number(splitPagination[0]);

      if (splitPagination[1]) {
        extractedFilters.page_size = Number(splitPagination[1]);
      }
    }

    if (query[orderQueryKey]) {
      extractedFilters.ordering = String(query[orderQueryKey]);
    }

    return extractedFilters;
  }

  get loading() {
    return CollectionsModule.loading;
  }

  //
  bottomNav: number = 3;

  headers = [
    { text: "ID", value: "id" },
    { text: "Name", value: "title" },
    { text: "Description", value: "description", sortable: false },
    { text: "Documents", value: "document_count", sortable: false },
    { text: "Modified", value: "modified", sortable: true },
    { text: "Category", value: "category", sortable: true },
    { text: "Public", value: "public", sortable: true },
  ];

  showFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  get collections() {
    const items = CollectionsModule.collections ? CollectionsModule.collections.results : [];
    return items;
  }

  get collectionsResponse() {
    return CollectionsModule.collections ? CollectionsModule.collections : null;
  }

  // ============================================================
  // ================== CONTEXT MENU ============================
  // ============================================================

  contextMenuOptionsArray = [
    {
      name: "Open Collection in a new Tab",
      slug: "newtab",
    },
    {
      name: "Show in Database",
      slug: "show",
    },
    {
      name: "Edit",
      slug: "edit",
    },
    {
      name: "Delete",
      slug: "delete",
    },
  ];

  goToColl(id: number) {
    this.$router.push(`/collections/${id.toString()}`);
  }

  /**
   * Resolves the Collection Url by Id and Opens that in a new tab
   */
  openCollInNewTab(id: number) {
    // Get url of collection from the router
    const resolvedRoute = this.$router.resolve(`/collections/${id.toString()}`);
    // Open resolved Url in new Tab
    window.open(resolvedRoute.href, "_blank");
  }

  handleClick(event: any, item: any) {
    // @ts-ignore
    this.$refs.vueSimpleContextMenu.showMenu(event, item);
  }

  optionClicked(event: any) {
    switch (event.option.slug) {
      case "delete":
        this.confirmDeletion = true;
        this.toBeDeleted = event.item;
        break;
      case "edit":
        this.$router.push(`collections/${event.item.id}/edit`);
        break;
      case "show":
        window.open(event.item.url, "_blank");
        break;
      case "newtab":
        this.openCollInNewTab(event.item.id);
        break;
      case "restore":
        this.confirmRestore = true;
        this.toBeRestored = event.item;
        break;
      default:
    }
  }

  toBeDeleted: ApiCollectionResponse | null = null;

  toBeRestored: ApiCollectionResponse | null = null;

  // ============================================================
  // ================== END OF CONTEXT MENU =====================
  // ============================================================

  // lifecycle hook

  beforeMount() {
    this.syncFieldsFromStoreFilters();
  }

  mounted() {
    SettingsModule.leaveTaggingMode();
  }

  syncFieldsFromStoreFilters() {
    this.syncLemmaConnected();
    this.syncCategory();
    this.syncSearch();
    this.syncPagination();
  }

  syncLemmaConnected() {
    if (this.collectionStoreFilters.lemma_id__isnull === true) {
      this.lemmaConnectedFilter = lemmaConnectionOptions.onlyNotConnected;
    } else if (this.collectionStoreFilters.lemma_id__isnull === false) {
      this.lemmaConnectedFilter = lemmaConnectionOptions.onlyConnected;
    } else {
      this.lemmaConnectedFilter = lemmaConnectionOptions.showAll;
    }
  }

  syncCategory() {
    if (this.collectionStoreFilters.category) {
      this.selectedCategoryFilter = getCollectionCategoryFromId(
        this.collectionStoreFilters.category,
      );
    } else {
      this.selectedCategoryFilter = noCollectionCategory;
    }
  }

  syncSearch() {
    if (this.collectionStoreFilters.title) {
      this.search = this.collectionStoreFilters.title;
    } else {
      this.search = "";
    }
  }

  paginationSetFromFilters = false;

  syncPagination() {
    const newPaginationObj: VuetifyDatatTablePagination = {
      page: collectionHomeFilters.page ?? 1,
      itemsPerPage: collectionHomeFilters.page_size ?? 25,
    };
    if (this.collectionStoreFilters.page) {
      newPaginationObj.page = this.collectionStoreFilters.page;
      this.paginationSetFromFilters = true;
    }

    if (this.collectionStoreFilters.page_size) {
      newPaginationObj.itemsPerPage = this.collectionStoreFilters.page_size;
      this.paginationSetFromFilters = true;
    }

    if (this.collectionStoreFilters.ordering) {
      let orderingString: string = this.collectionStoreFilters.ordering;
      const isDesc: boolean = orderingString[0] === "-";
      newPaginationObj.sortDesc = [isDesc];
      this.paginationSetFromFilters = true;
      if (isDesc) {
        orderingString = orderingString.substring(1);
      }
      newPaginationObj.sortBy = [orderingString];
    }

    Object.assign(this.pagination, newPaginationObj);
  }

  created() {
    this.CM.setFilters(this.filtersFromQuery);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

td {
  cursor: pointer;
}

a {
  color: #42b983;
}

td {
  cursor: pointer;
}
</style>
