<template>
  <vContainer>
    <h1>Settings</h1>

    lock snackbar: <SnackbarSwitch></SnackbarSwitch> darkmmode:
    <DarkmodeSwitch></DarkmodeSwitch>
    <h3>select your language!</h3>
    <LanguageSelect></LanguageSelect>

    {{ $t("message.hello") }}
  </vContainer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import Logger from "../services/LoggerService";
import LanguageSelect from "./LanguageSelect.vue";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch, LanguageSelect },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "Settings",
})
export default class Settings extends Vue {
  l = new Logger("Settings.vue");
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
