<template>
  <div>
    <v-container>
      <h1 style="text-align: center; margin-top: 10px; margin-bottom: 50px">Report erstellen</h1>

      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <h2>Reporttyp auswählen</h2>
          <v-select
            data-test="report-view-report-type-select"
            v-model="selType"
            :items="reportTypes"
            item-text="name"
            item-value="id"
            label="Reporttyp auswählen"
          >
          </v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="4"
        >
          <h2>User auswählen</h2>
          <ApiSelect
            data-test="report-view-assigned-user-select"
            itemText="username"
            label="user"
            type="users"
            @input="l.debug('as', $event)"
            :multiple="false"
            v-model="assignedUser"
            :filterValue="assignedUser"
          ></ApiSelect>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <div>
            <v-switch :label="$t('ReportView.only_active')" v-model="onlyActiveTasks"> </v-switch>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          cols="12"
          sm="4"
        >
          <h2 class="mb-2">
            {{ $t("ReportView.time_filter_start") }}
          </h2>

          <v-select
            data-test="report-view-date-type-select"
            v-model="selectedDateType"
            :items="dateTypes"
            dense
            outlined
            hide-details
            class="mb-2"
          >
            <template #item="{ item }">
              <span>{{ $t(item.text) }}</span>
            </template>
            <template #selection="{ item }">
              <span>{{ $t(item.text) }}</span>
            </template>
          </v-select>

          <v-layout align-content-space-between>
            <v-flex>
              <h3>{{ $t("ReportView.time_filter_middle") }}</h3>
              <v-menu
                data-test="report-view-start-date-menu"
                v-model="startMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="150px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    data-test="report-view-start-date-text-field"
                    v-model="startDate"
                    label="Startdatum auswählen"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click="startMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  data-test="report-view-start-date-picker"
                  v-model="startDate"
                  no-title
                  scrollable
                  @input="startMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>

            <v-spacer />

            <v-flex>
              <h3>{{ $t("ReportView.time_filter_end") }}</h3>
              <v-menu
                data-test="report-view-end-date-menu"
                v-model="endMenu"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="150px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    data-test="report-view-end-date-text-field"
                    v-model="endDate"
                    label="Enddatum auswählen"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    @click="endMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  data-test="report-view-end-date-picker"
                  v-model="endDate"
                  no-title
                  scrollable
                  @input="endMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          sm="4"
        >
          <h2>{{ $t("ReportView.additional_filters") }}</h2>

          <v-select
            data-test="report-view-step-select"
            v-model="step"
            :items="stepItems"
            item-text="name"
            item-value="value"
            label="Step auswählen"
            persistent-hint
            single-line
            clearable
          ></v-select>

          <v-select
            data-test="report-view-status-select"
            v-model="status"
            :items="statusItems"
            item-text="name"
            item-value="value"
            label="Status auswählen"
            persistent-hint
            single-line
            clearable
          ></v-select>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="2">
          <v-btn
            data-test="report-view-create-report-button"
            @click="createNewReport()"
            color="primary"
          >
            Erzeuge neuen Report
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            data-test="report-view-export-report-button"
            @click="exportReport()"
            color="primary"
          >
            Report als csv exportieren
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        justify="center"
      >
        <v-col
          cols="10"
          sm="10"
          style="margin-top: 25px"
        >
          <v-data-table
            :loading="loading"
            :headers="reportTable[selType]"
            :items="report.results"
            :search="search"
            :footer-props="{
              'items-per-page-options': itemsPerPageItems,
            }"
            :server-items-length="report ? report.count : 0"
            :options.sync="pagination"
            fixed-header
          >
            <template v-slot:item.document__count="{ item }">
              {{ item.document__count === 0 ? "-" : item.document__count }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import * as json2csv from "json2csv";

import { AuthModule } from "@/store/modules/auth";

import { TaskModule } from "@/store/modules/task";
import {
  ApiReportRequest,
  ApiUser,
  ApiReportPage,
  ApiReportTypeUser,
  ApiReportTypeStepStatus,
  ApiReportTypeLemma,
} from "@/static/apiModels";
import { VuetifyDatatTablePagination } from "@/static/types";
import Logger from "../services/LoggerService";

import { UsersModule } from "../store/modules/users";
import ApiSelect from "./ApiSelect.vue";

export interface IItem {
  text: string;
  value: string;
  sortable: boolean;
}

@Component({
  components: {
    ApiSelect,
  },
  name: "ReportView",
})
export default class ReportView extends Vue {
  l = new Logger("ReportView.vue");

  selType: number = 0;

  reportTypes = [
    { id: 0, name: "Nach Step und Status gruppieren" },
    { id: 1, name: "Bearbeitete Lemma nach User" },
    {
      id: 2,
      name: "Nach User gruppieren",
    },
  ];

  reportTable = [
    [
      { text: "Step", value: "step", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Anzahl der Aufgaben im Step", value: "steps", sortable: true },
      { text: "Anzahl der Aufgaben im Status", value: "stati", sortable: true },
    ],
    [
      { text: "HL", value: "lemma__lemmatisierung", sortable: true },
      { text: "Beleganzahl", value: "document__count", sortable: true },
      { text: "Username", value: "user__username", sortable: true },
    ],
    [
      { text: "Username", value: "user__username", sortable: true },
      {
        text: "Anzahl der Bearbeiteten Lemmata",
        value: "lemma_count",
        sortable: true,
      },
    ],
  ];

  onlyActiveTasks: boolean = false;

  selectedDateType = "none";

  dateTypes = [
    { text: "ReportView.finished_date", value: "finished_date" },
    { text: "ReportView.begin_time", value: "begin_time" },
    { text: "ReportView.last_edited", value: "last_edited" },
    { text: "ReportView.none", value: "none" },
  ];

  step: string = "";

  status: string = "";

  startDate: Date | null = null;

  startMenu: boolean = false;

  endMenu: boolean = false;

  endDate: Date | null = null;

  assignedUser: ApiUser | null = null;

  tab = null;

  TM = TaskModule;

  UM = UsersModule;

  AM = AuthModule;

  search: string = "";

  boolFilters: boolean = false;

  showSearch: boolean = false;

  confirmIt: boolean = false;

  itemsPerPageItems = [10, 25, 50, 100, 500, 1000, 2000];

  pagination: VuetifyDatatTablePagination = {
    page: 1,
    itemsPerPage: 100,
    sortBy: ["last_edited"],
    sortDesc: [true],
  };

  lastItemsPerPage: number = 100;

  tabItems = [
    "Meine offenen Aufgaben",
    "Meine abgeschlossenen Aufgaben",
    "Zuletzt bearbeitete Aufgaben",
  ];

  tabItemsOff = ["Zuletzt bearbeitete Aufgaben"];

  headers = [
    { text: "ID", value: "id" },
    { text: "Lemma", value: "lemma", sortable: true },
    { text: "Fällig", value: "deadline", sortable: true },
    { text: "Status", value: "status", sortable: true },
    { text: "Step", value: "step", sortable: true },
    { text: "Zuletzt bearbeitet", value: "last_edited", sortable: true },
    { text: "Description", value: "description", sortable: false },
    { text: "Assigned User", value: "user_name", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ];

  get stepItems() {
    return TaskModule.stepItems;
  }

  get statusItems() {
    return TaskModule.status;
  }

  get report() {
    return TaskModule.currentReport ? TaskModule.currentReport : ({} as ApiReportPage);
  }

  get completeReport() {
    return TaskModule.completeReport ? TaskModule.completeReport : ({} as ApiReportPage);
  }

  get users() {
    return this.UM.users;
  }

  get filters() {
    return TaskModule.filters;
  }

  get loading() {
    return TaskModule.loading;
  }

  get tasks() {
    return TaskModule.tasks ? TaskModule.tasks : [];
  }

  createNewReport() {
    this.pagination.page = 1;
    this.fetchReport();
  }

  fetchReport() {
    let user: string = "";
    if (this.assignedUser !== undefined) {
      user = this.assignedUser === null ? "" : this.assignedUser.username;
    }

    const request: ApiReportRequest = {
      // eslint-disable-line
      reporting: this.selType,
      user,
      status: this.status,
      step: this.step,
      current: this.onlyActiveTasks,
      page: this.pagination.page,
      page_size: this.pagination.itemsPerPage,
    };

    switch (this.selectedDateType) {
      case "finished_date":
        request.finished_date_before = this.endDate;
        request.finished_date_after = this.startDate;
        break;
      case "begin_time":
        request.begin_time_before = this.endDate;
        request.begin_time_after = this.startDate;
        break;
      case "last_edited":
        request.last_edited_before = this.endDate;
        request.last_edited_after = this.startDate;
        break;
      default:
        break;
    }

    this.TM.fetchReport(request);
  }

  fetchAllEntriesOfReport(): Promise<any> {
    let user: string = "";
    if (this.assignedUser !== undefined) {
      user = this.assignedUser === null ? "" : this.assignedUser.username;
    }

    const request: ApiReportRequest = {
      // eslint-disable-line
      reporting: this.selType,
      user,
      status: this.status,
      step: this.step,
      current: this.onlyActiveTasks,
      page: this.pagination.page,
      page_size: this.pagination.itemsPerPage,
    };

    switch (this.selectedDateType) {
      case "finished_date":
        request.finished_date_before = this.endDate;
        request.finished_date_after = this.startDate;
        break;
      case "begin_time":
        request.begin_time_before = this.endDate;
        request.begin_time_after = this.startDate;
        break;
      case "last_edited":
        request.last_edited_before = this.endDate;
        request.last_edited_after = this.startDate;
        break;
      default:
        this.l.log("default req", request, this.selectedDateType);
        break;
    }

    return this.TM.fetchCompleteReport(request);
  }

  @Watch("tab")
  onTabChange(val: any) {
    this.TM.setFilters({});
    this.l.log("Tab has been switched to", val);
    this.TM.filters.current = true;
    if (AuthModule.loggedIn) {
      if (val === 0) {
        this.pagination.sortBy = ["last_edited"];
        const username = AuthModule.getUser ? AuthModule.getUser.username : "";
        this.filters.user = username;
      } else if (val === 1) {
        this.pagination.sortBy = ["last_edited"];
        const username = AuthModule.getUser ? AuthModule.getUser.username : "";
        this.filters.user = username;
        this.filters.status = "FINAL_VERSION";
      } else {
        this.pagination.sortBy = ["last_edited"];
        this.filters.currentstatus = 2;
      }
    } else {
      this.pagination.sortBy = ["last_edited"];
    }
    this.filters.ordering = `${this.pagination.sortDesc?.[0] ? "-" : ""}${this.pagination.sortBy}`;
    this.TM.fetchTasks(this.filters);
  }

  @Watch("pagination", { deep: true })
  onPaginationChange() {
    this.fetchReport();
  }

  isReportLemma(obj: any): obj is ApiReportTypeLemma {
    return obj.user__username !== undefined;
  }

  isReportUser(obj: any): obj is ApiReportTypeUser {
    return obj.lemma_count !== undefined;
  }

  isReportStepStatus(obj: any): obj is ApiReportTypeStepStatus {
    return obj.stati !== undefined;
  }

  // Export Function for the report
  async exportReport() {
    await this.fetchAllEntriesOfReport();
    let csv: string = "";
    let res;
    if (this.completeReport && this.completeReport.results.length > 0) {
      if (this.isReportLemma(this.completeReport.results[0])) {
        res = this.completeReport.results as ApiReportTypeLemma[];
        const header = [
          { value: "lemma__lemmatisierung", label: "Hauptlemma" },
          { value: "document__count", label: "Belegzahl" },
          { value: "user__username", label: "Zugewiesener User" },
        ];
        const opt = { fields: header };
        csv = json2csv.parse(res, opt);
      } else if (this.isReportUser(this.completeReport.results[0])) {
        res = this.completeReport.results as ApiReportTypeStepStatus[];
        const header = [
          { value: "lemma_count", label: "Belegzahl" },
          { value: "user__username", label: "Zugewiesener User" },
        ];
        const opt = { fields: header };
        csv = json2csv.parse(res, opt);
      } else if (this.isReportStepStatus(this.completeReport.results[0])) {
        res = this.completeReport.results as ApiReportTypeUser[];
        const header = [
          { value: "step", label: "Step" },
          { value: "status", label: "Status" },
          { value: "steps", label: "Anzahl der Aufgaben mit Step" },
          { value: "stati", label: "Anzahl der Aufgaben mit Status" },
        ];
        const opt = { fields: header };
        csv = json2csv.parse(res, opt);
      }

      // Creates a new element in DOM to download the resulting data in a csv File
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = `report.csv`;
      hiddenElement.click();
    }
  }

  deleteTask(id: number) {
    TaskModule.deleteTask(id);
    this.$router.push(`/task`);
  }

  goToTask(id: number) {
    this.l.log("Go to single View");
    this.$router.push(`/task/${id.toString()}`);
  }

  goToEdit(id: number) {
    this.l.log("Go to edit view");
    this.$router.push(`/task/${id.toString()}/edit`);
  }

  goToCreate() {
    this.l.log("Go to create view");
    this.$router.push(`/task/create`);
  }

  goToLemma(url: string) {
    const regexp = /\/(\d+)\/$/g;
    const id = regexp.exec(url);
    if (id === null) {
      return;
    }
    this.l.log("gotolemma", id[1]);
    this.$router.push(`/lemma/${id[1].toString()}`);
  }

  // lifecycle hook

  created() {
    if (AuthModule.loggedIn) {
      this.filters.user = AuthModule.getUser ? AuthModule.getUser.username : "";
    }
  }
}
</script>
