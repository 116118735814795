<template>
  <v-dialog
    v-model="dialogValue"
    max-width="290"
    :data-test="`confirmation-modal-${effectedItemTitle}`"
  >
    <v-card>
      <v-card-text>
        {{ text ?? $t("basics.confirmation_text") }}
        <br />
        {{ effectedItemTitle ?? "" }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          @click="dialogValue = false"
          data-test="confirmation-modal-cancel"
        >
          {{ negativeButtonText ?? $t("basics.cancle") }}
        </v-btn>

        <v-btn
          color="primary"
          @click="action"
          data-test="confirmation-modal-confirm"
        >
          {{ positiveButtonText ?? $t("basics.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, VModel } from "vue-property-decorator";

@Component({
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "ConfirmationModal",
})
export default class ConfirmationModal extends Vue {
  @VModel({ type: Boolean }) dialogValue!: Boolean;

  @Prop(String)
  readonly text: string | undefined;

  @Prop(String) readonly effectedItemTitle: string | undefined;

  @Prop(String) readonly positiveButtonText: string | undefined;

  @Prop(String) readonly negativeButtonText: string | undefined;

  @Prop(Function) readonly action!: Function;
}
</script>
