<!-- meant to be copied to be a new component-->
<template>
  <v-autocomplete
    data-test="tag-autocomplete"
    v-model="selection"
    :items="tags"
    :loading="loading"
    :search-input.sync="search"
    chips
    clearable
    hide-details
    hide-selected
    item-text="name"
    auto-select-first
    item-value="url"
    multiple
    :label="$t(placeholder)"
    solo
    @change="$emit('input', selection)"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          <span data-test="tag-autocomplet-no-data">{{ $t(placeholder) }}</span>
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item }">
      <TagChip
        :data-test="`tag-autocomplete-selected-${item.name}`"
        :url="item.url"
      ></TagChip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <TagChip
          :data-test="`tag-autocomplete-select-${item.name}`"
          :url="item.url"
        ></TagChip>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import { TagsModule } from "../store/modules/tags";
import TagChip from "./TagChip.vue";
import { CollectionsModule } from "../store/modules/collections";

@Component({
  components: { TagChip },
  name: "TagAutocomplete",
})
export default class TagAutocomplete extends Vue {
  // @ts-ignore
  @Prop({ default: false }) readonly onlyCurrent: boolean;

  // @ts-ignore
  @Prop({ default: "select_tag" }) readonly placeholder: string;

  l = new Logger("TagAutocomplete.vue");

  TM = TagsModule;

  get loading() {
    return this.TM.getLoading;
  }

  search: string = "";

  get tags() {
    if (this.onlyCurrent && CollectionsModule.currentCollection) {
      return this.TM.getTags.filter((tag) =>
        // @ts-ignore
        CollectionsModule.currentCollection.tags.includes(tag.url),
      );
    }
    return this.TM.getTags;
  }

  selection = [];
}
</script>

<style scoped lang="scss"></style>
