import { AxiosResponse } from "axios";
import { ApiTagPost, ApiTag } from "../../static/apiModels";

import api from ".";

/**
 * fetches allTags
 */
export const getTags = (): Promise<AxiosResponse<any>> => {
  return api.get("/api/tags/", { params: { page_size: 100000 } });
};

export const postTag = (tag: ApiTagPost): Promise<AxiosResponse<any>> => {
  return api.post("/api/tags/", tag);
};

export const editTag = (tag: ApiTag): Promise<AxiosResponse<any>> => {
  return api.put(tag.url, tag);
};

export const deleteTag = (tagUrl: string): Promise<AxiosResponse<any>> => {
  return api.delete(tagUrl);
};
