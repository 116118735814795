<template>
  <v-content class="ml-4 pr-0 mr-0">
    <v-row>
      <v-col cols="3">
        <v-row
          v-if="!hasDjangoDoc"
          class="pa-0 ma-0 d-flex justify-center"
        >
          <v-alert
            color="primary"
            border="left"
            colored-border
            elevation="3"
            icon="mdi-alert"
          >
            <h4>{{ $t("ScanConnectRow.missing-django-doc.header") }}</h4>
            <p>{{ $t("ScanConnectRow.missing-django-doc.descr") }}</p>
            <!-- <v-container class="d-flex justify-end py-0 mt-0 mb-0 ">
              <v-btn class="my-0 py-0" color="success" outlined>Create</v-btn>
            </v-container> -->
          </v-alert>
        </v-row>

        <!-- XML Info Box - Overview -->
        <v-row class="pa-0 ma-0">
          <h4 class="pl-1 mb-2">Archiv: {{ archiveInfo }}</h4>
          <v-layout child-flex>
            <v-simple-table class="pa-0 ma-0">
              <template v-slot:default>
                <tbody>
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.src')"
                    :value="connectDocument.QU"
                    :important="true"
                  />
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.lt1')"
                    :value="connectDocument.LT1"
                    useCleanedValue
                    important
                    largeFont
                  />
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.kt1')"
                    :value="connectDocument.KT1"
                    useCleanedValue
                    important
                    largeFont
                  />
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.lemma')"
                    :value="connectDocument.HL"
                    useCleanedValue
                    important
                  />
                  <!-- Essential Info End -->
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.lt2')"
                    :value="connectDocument.LT2"
                    useCleanedValue
                    important
                    largeFont
                  />
                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.kt2')"
                    :value="connectDocument.KT2"
                    useCleanedValue
                    important
                    largeFont
                  />
                  <!-- Additional Kontexts End -->

                  <XMLInfoRow
                    :label="$t('ScanConnectRow.xml-info-row.nr')"
                    :value="connectDocument.NR"
                    :cleanValue="cleanedFragebogenNrInfo"
                    useCleanedValue
                  />
                  <XMLInfoRow
                    v-if="!Array.isArray(bkis)"
                    :label="$t('ScanConnectRow.xml-info-row.meaning')"
                    :value="bkis"
                  />
                  <template v-else>
                    <XMLInfoRow
                      v-for="(bki, i) in bkis"
                      :key="i"
                      :label="
                        $t('ScanConnectRow.xml-info-row.meaning-multiple').toString() +
                        (i + 1) +
                        ':'
                      "
                      :value="bki"
                    />
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-layout>
        </v-row>
        <v-row justify="space-around">
          <v-dialog
            v-model="xmlModal"
            scrollable
            max-width="85%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :data-test="`${componentName}-${connectDocument.es_id}-load-xml-btn`"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="loadXML()"
              >
                {{ $t("ScanConnectRow.show-xml") }}
              </v-btn>
            </template>
            <v-card
              height="80vh"
              :loading="loadingXML"
            >
              <v-card-title
                >XML
                <v-spacer></v-spacer>
                <v-btn
                  :data-test="`${componentName}-${connectDocument.es_id}-close-xml-modal-btn`"
                  color="red darken-1"
                  text
                  @click="xmlModal = false"
                >
                  <v-icon>mdi-close</v-icon>
                  <span class="d-sr-only">Close</span>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-textarea
                  :label="$t('ScanConnectRow.xml-textarea-label')"
                  readonly
                  auto-grow
                  v-model="connectDocument.xml"
                >
                </v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :data-test="`${componentName}-${connectDocument.es_id}-reload-xml-btn`"
                  color="primary"
                  dark
                  text
                  @click="loadXML()"
                >
                  {{ $t("ScanConnectRow.xml-btn") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-row justify="space-around">
          <v-carousel
            :data-test="`${componentName}-${connectDocument.es_id}-carousel`"
            v-if="scans.length"
            v-model="carousel"
            :show-arrows="showCarouselNavigation"
            mandatory
            height="400"
          >
            <v-carousel-item
              v-for="(scan, index) in scans"
              :key="`${index}_${scan}`"
              :alt="scan.path"
              @click="$emit('showPic', srcFromIFF(scan, '!2500, 1500'))"
              :label="
                $t('ScanConnectRow.connected-scan.label').toString() +
                (index + 1) +
                '/' +
                scans.length
              "
            >
              <v-img :src="srcFromIFF(scan)" />
            </v-carousel-item>
          </v-carousel>
          <v-btn
            :data-test="`${componentName}-${connectDocument.es_id}-delete-scan-btn`"
            v-if="scans.length"
            small
            color="danger"
            class="ma-3"
            :disabled="loading"
            @click="deleteScan()"
            >{{ $t("ScanConnectRow.connected-scan.delete") }}</v-btn
          >

          <!-- IF EMPTY -->
          <p
            v-else
            class="mt-16"
          >
            {{ $t("ScanConnectRow.connected-scan.missing-scans") }}
          </p>
        </v-row>
      </v-col>
      <v-col
        cols="2"
        class="mx-4"
      >
        <b>ID:{{ connectDocument.es_id }}</b>
        <v-textarea
          :label="$t('ScanConnectRow.connect-functionality.drag-drop')"
          outlined
          class="shrink mb-0 mt-6"
          v-model="droppedImageLink"
          :disabled="loading"
          :loading="loading"
          rows="5"
          row-height="30"
          auto-grow
          filled
          :data-test="`${componentName}-${connectDocument.es_id}-drop-image-link-field`"
        ></v-textarea>

        <v-divider class="mb-3 mt-0 pt-0" />

        <b class="mt-4">
          {{ $t("ScanConnectRow.proposed-pictures.navigation-header") }}
        </b>

        <v-row class="pl-3 mt-1">
          <v-text-field
            :data-test="`${componentName}-${connectDocument.es_id}-picture-offset-input`"
            :label="$t('ScanConnectRow.proposed-pictures.offset')"
            v-model="pictureOffset"
            hide-details
            type="number"
            class="mb-6 shrink"
          />
          <div
            style="white-space: nowrap; display: inline-block"
            class="ma-0"
          >
            <v-btn
              :data-test="`${componentName}-${connectDocument.es_id}-picture-offset-up-btn`"
              icon
              class="ml-2 mr-0 mt-1 px-0"
              @click="shiftPicturesUp()"
            >
              <v-icon class="mx-0 px-0">mdi-arrow-up</v-icon>
              <span class="d-sr-only">{{ $t("ScanConnectRow.proposed-pictures.shift-imgs-up") }}</span>
            </v-btn>
            <v-btn
              :data-test="`${componentName}-${connectDocument.es_id}-picture-offset-down-btn`"
              icon
              class="mr-2 ml-0 mt-4 px-0"
              @click="shiftPicturesDown()"
            >
              <v-icon class="mx-0 px-0">mdi-arrow-down</v-icon>
              <span class="d-sr-only">{{ $t("ScanConnectRow.proposed-pictures.shift-imgs-down") }}</span>
            </v-btn>
          </div>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-row>
          <v-img
            :data-test="`${componentName}-${connectDocument.es_id}-proposed-picture`"
            v-if="proposedPicture"
            @click="$emit('showPic', srcFromIFF(proposedPicture, '!2500, 1500'))"
            :src="srcFromIFF(proposedPicture)"
            :key="proposedPicture.path"
            contain
            height="400"
            max-width="575"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-row>
        <v-row
          class="pa-3"
          justify="space-around"
        >
          <v-btn
            :data-test="`${componentName}-${connectDocument.es_id}-proposed-picture-add-btn`"
            small
            primary
            v-if="proposedPicture"
            @click="addScan(proposedPicture)"
            :disabled="loading"
            >{{ $t("ScanConnectRow.proposed-pictures.add-btn") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
  </v-content>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import XMLInfoRow from "@/components/XMLInfoRow.vue";
import { type ScanDocument } from "@/views/ScanView.vue";
import { NotificationsModule } from "@/store/modules/notifications";
import { createDocument, getDjangoDocumentByEsID, patchDocument } from "@/api/django/documents";
import { getXMLofDocument } from "@/api/xml/documents";
import { IFFFPicture, extractPathFromIFFUrl, getSrcFromIFFPicture } from "@/utils/IFFFHandler";
import Logger from "../services/LoggerService";

@Component({
  name: "ScanConnectRow",
  components: { XMLInfoRow },
})
export default class ScanConnectRow extends Vue {
  componentName = "ScanConnectRow";

  @Prop() connectDocument!: ScanDocument;

  @Prop() proposedPicture!: IFFFPicture;

  l = new Logger("connectDocument.vue");

  // todo turn into getter and setter
  pictureMissing = false;

  editLater = false;

  droppedImageLink = "";

  loading = false;

  carousel = 0;

  xmlModal = false;

  loadingXML = false;

  xmlParser = new DOMParser();

  xmlMissingString = "XML Missing!";

  pictureOffset = 1;

  scans: IFFFPicture[] = [];

  xmlDocument: any = null;

  cachedXML: string = "";

  hasDjangoDoc: boolean = false;

  srcFromIFF(pic: IFFFPicture, size?: string) {
    return getSrcFromIFFPicture(pic, size);
  }

  get showCarouselNavigation() {
    return this.scans && this.scans.length > 1;
  }

  shiftPicturesUp() {
    this.$emit("shiftImage", +this.pictureOffset);
    this.resetPictureOffset();
  }

  shiftPicturesDown() {
    this.$emit("shiftImage", -this.pictureOffset);
    this.resetPictureOffset();
  }

  resetPictureOffset() {
    this.pictureOffset = 1;
  }

  deleteScan() {
    this.scans.splice(this.carousel, 1);
    this.carousel = 0;
    this.updateXMLScans(this.scans);
  }

  @Watch("droppedImageLink")
  onImageURLChange() {
    if (this.droppedImageLink.length < 5) return;

    const picPath: string | null = extractPathFromIFFUrl(this.droppedImageLink);
    if (!picPath) {
      this.droppedImageLink = "";
      return;
    }
    this.addScan({
      path: picPath,
    });
  }

  @Watch("loading")
  onLoadingChanged() {
    if (!this.loading) {
      this.droppedImageLink = "";
    }
  }

  patchScans(scans: string[]) {
    if (!this.connectDocument.es_id) {
      NotificationsModule.setError({
        message: "Elastic Search ID missing for document.",
        icon: "",
      });
      return;
    }
    patchDocument(this.connectDocument.es_id, {
      scans,
    })
      .then((resp) => {
        this.connectDocument.scans = resp.data.scans || [];
        this.droppedImageLink = "";
        this.loading = false;
      })
      .catch((err) => {
        this.showMSG(`There was an error: ${err}`);
      });
  }

  addScan(scanToAdd: IFFFPicture) {
    this.addScanToXml(scanToAdd);
  }

  showMSG(msg: string) {
    NotificationsModule.setNeutral({
      message: msg,
      icon: "",
    });
  }

  setHasDjangoDoc() {
    this.hasDjangoDoc = this.connectDocument.url !== undefined && this.connectDocument.url !== "";
  }

  mounted() {
    this.resetScanConnectRow();
  }

  @Watch("connectDocument")
  onConnectedDocumentChange() {
    this.resetScanConnectRow();
  }

  resetScanConnectRow() {
    this.scans.length = 0;
    this.cachedXML = "";
    this.setHasDjangoDoc();
    this.loadScans();
  }

  loadScans() {
    if (this.getScansFromXML()) {
      this.scans = this.getScansFromXML() ?? [];
    }
  }

  getScansFromXML(): IFFFPicture[] | null {
    if (!this.connectDocument.xml) if (!this.connectDocument.xml) return null;

    const xmlFacs = this.getXmlFacs();
    if (!xmlFacs) {
      return null;
    }

    const scanArr: string[] = xmlFacs.split(" ");

    return scanArr.map((scan): IFFFPicture => {
      return {
        path: decodeURIComponent(scan),
      };
    });
  }

  getXmlDoc() {
    if (!this.isXML(this.connectDocument.xml)) return null;

    if (!this.connectDocument.xml) return null;

    if (this.xmlDocument === null)
      this.xmlDocument = this.xmlParser.parseFromString(this.connectDocument.xml, "text/xml");
    else if (this.cachedXML !== this.connectDocument.xml) {
      this.cachedXML = this.connectDocument.xml;
      this.xmlDocument = this.xmlParser.parseFromString(this.connectDocument.xml, "text/xml");
    }

    if (!this.xmlDocument) return null;

    return this.xmlDocument;
  }

  getXmlMainEntry() {
    const xmlDoc = this.getXmlDoc();
    if (!xmlDoc) return null;

    if (!xmlDoc.getElementsByTagName("entry")[0]) return null;

    return xmlDoc.getElementsByTagName("entry")[0];
  }

  getXmlFacs(): string | null {
    const xmlMainEntry = this.getXmlMainEntry();

    if (!xmlMainEntry) this.l.log("No XML Main Entry for", this.connectDocument.django_id);
    if (!xmlMainEntry) return null;

    const facs = xmlMainEntry.getAttributeNode("facs");

    if (!facs || !facs.nodeValue) return null;
    return facs.nodeValue;
  }

  get info() {
    return `A: ${this.connectDocument.A}
            HL: ${this.connectDocument.HL}
            QU: ${this.connectDocument.QU}
            QDB: ${this.connectDocument.QDB}
            NR: ${this.connectDocument.NR}
            LT1: ${this.connectDocument.LT1}`;
  }

  checkUndefined(val: any) {
    return val !== undefined ? val : "Nicht vorhanden";
  }

  get archiveInfo() {
    return this.checkUndefined(this.connectDocument.A);
  }

  get cleanedFragebogenNrInfo() {
    const frNr = String(this.fragebogenNrInfo);
    return frNr.substr(0, frNr.indexOf(":"));
  }

  get fragebogenNrInfo() {
    return this.checkUndefined(this.connectDocument.NR);
  }

  get bkis() {
    return this.connectDocument.bdktlts;
  }

  get bedeutungKontextInfo() {
    return this.checkUndefined(this.connectDocument.BDKTLT1);
  }

  async loadXML(onlyDjango: boolean = false) {
    if (this.loadingXML) {
      this.l.info("Canceled XML as it's already loading");
      return;
    }
    this.loadingXML = true;
    this.connectDocument.xml = "Loading XML.";
    let xmlRes;

    if (!this.connectDocument.es_id) {
      this.showMSG(
        `Failed to load XML for Doc with Django ID ${this.connectDocument.django_id} and HL ${this.connectDocument.HL}. Elastic Search ID missing for document.`,
      );
      this.loadingXML = false;
      return;
    }

    if (this.hasDjangoDoc) {
      await getDjangoDocumentByEsID(this.connectDocument.es_id).then((res) => {
        xmlRes = res.data.results[0].xml;
        if (this.isXML(xmlRes)) {
          this.applyXML(xmlRes);
          this.loadingXML = false;
        }
      });
      if (!this.loadingXML) return;
    }
    if (onlyDjango) return;

    await getXMLofDocument(this.connectDocument.es_id)
      .then((res) => {
        xmlRes = res.data.entry;
        if (this.isXML(xmlRes)) {
          this.applyXML(xmlRes);
        } else {
          this.showMSG(`Couldn't find any XML for ${this.connectDocument.es_id}`);
          this.applyXML(this.xmlMissingString);
        }
      })
      .catch((err) => {
        this.showMSG(`There was an error while loading the XML: ${err}`);
        this.applyXML(this.xmlMissingString);
      });
  }

  isXML(value: any): boolean {
    if (!value) return false;
    if (value === "") return false;
    const xmlDoc = this.xmlParser.parseFromString(value, "text/xml");
    if (xmlDoc.querySelector("parsererror")) return false;

    return true;
  }

  applyXML(newXML: any) {
    this.connectDocument.xml = newXML;
    this.loadingXML = false;
  }

  async updateXMLScans(scans: IFFFPicture[]) {
    this.loading = true;
    await this.loadXML();

    // Check if a XML Document in the propper Format is present
    if (!this.getXmlDoc()) {
      this.loading = false;
      return;
    }
    if (!this.getXmlMainEntry()) {
      this.loading = false;
      return;
    }

    // Encode the Scan Urls for XML
    let scansString = "";

    if (scans && scans.length === 1) scansString = encodeURIComponent(scans[0].path);
    else if (scans && scans.length > 1) {
      scans.forEach((scan, index) => {
        if (index === 0) scansString = encodeURIComponent(scan.path);
        else scansString = scansString.concat(" ").concat(encodeURIComponent(scan.path));
      });
    }

    // Assign Scans to XML
    this.getXmlMainEntry().setAttribute("facs", scansString);

    const xmlString = new XMLSerializer().serializeToString(this.getXmlDoc().documentElement);
    if (!xmlString || xmlString === "") {
      this.loading = false;
      return;
    }

    // Update the XML in the Django DB
    this.updateDjangoXML(xmlString);
  }

  async addScanToXml(scanPic: IFFFPicture) {
    let scans: IFFFPicture[] = [scanPic];
    if (this.getScansFromXML() && Array.isArray(this.getScansFromXML()))
      scans = [...(this.getScansFromXML() ?? []), ...scans];

    this.updateXMLScans(scans);
  }

  async updateDjangoXML(_xml: string) {
    if (this.hasDjangoDoc) {
      this.patchXML(_xml);
    } else {
      this.createDjangoDocWithXML(_xml);
    }
  }

  createDjangoDocWithXML(_xml: string) {
    createDocument({
      es_id: this.connectDocument.es_id,
      xml: _xml,
    })
      .then((resp) => {
        this.connectDocument.xml = resp.data.xml || this.xmlMissingString;
        this.connectDocument.url = resp.data.url;
        this.connectDocument.django_id = resp.data.id;
        this.loadScans();
        this.setHasDjangoDoc();
        this.loading = false;
      })
      .catch((err) => {
        this.showMSG(`There was an error while creating the Doc with XML: ${err}`);
        this.connectDocument.xml = this.xmlMissingString;
        this.loading = false;
      });
  }

  patchXML(_xml: string) {
    if (!this.connectDocument.django_id) {
      this.showMSG(
        `Failed to patch XML for Doc with Elastic ID ${this.connectDocument.es_id} and HL ${this.connectDocument.HL}. Django ID missing for document.`,
      );
      this.loading = false;
      return;
    }

    patchDocument(this.connectDocument.django_id, {
      xml: _xml,
    })
      .then((resp) => {
        this.connectDocument.xml = resp.data.xml || this.xmlMissingString;
        this.loadScans();
        this.loading = false;
      })
      .catch((err) => {
        this.showMSG(`There was an error while patching the XML: ${err}`);
        this.connectDocument.xml = this.xmlMissingString;
        this.loading = false;
      });
  }

  removeNormalisedSpelling(input: String): String {
    let cleanedData = input;
    if (input.includes(",")) {
      cleanedData = input.substring(0, input.indexOf(","));
    }
    return cleanedData;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.infoText {
  line-height: 1;
  border: 1px solid black;
  word-wrap: break-word;
  /* IE 5.5-7 */
  white-space: -moz-pre-wrap;
  /* Firefox 1.0-2.0 */
  white-space: pre-wrap;
  /* current browsers */
  cursor: pointer;
  border-radius: 3px;
  padding: 5px;
}

tbody tr:nth-of-type(odd) {
  // background-color: rgb(58, 58, 58);
  background-color: var(--v-secondary-base);
}

:root {
  --big-info-font-size: 1.095em;
  --medium-info-font-size: 1.075em;
}
</style>
