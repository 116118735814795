<!-- this compoenent will be able to select any type from the api. propswise you can choose to allow multi select or only keep one -->
<template>
  <div>
    <v-select
      v-model="model"
      :label="label"
      @input="$emit('input', $event)"
      v-if="!loading"
      :items="items"
      :item-text="itemText"
      return-object
      outline
      :multiple="multiple ? true : false"
      clearable
      :data-test="`api-select-${label}`"
    ></v-select>
    <v-progress-linear v-if="loading"></v-progress-linear>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UsersModule } from "../store/modules/users";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: {},
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "ApiSelect",
})
export default class ApiSelect extends Vue {
  // todo create enum for possible types
  @Prop(String) readonly type: string | undefined;

  @Prop(String) readonly itemText: string | undefined;

  @Prop(String) readonly itemValue: string | undefined;

  @Prop(String) readonly label: string | undefined;

  @Prop(Boolean) readonly multiple: boolean | undefined;

  @Prop() filterValue: any;

  model: any = null;

  l = new Logger("ApiSelect.vue");

  get TYPE() {
    return this.type;
  }

  @Watch("filterValue")
  onFilterChange() {
    if (!this.filterValue) {
      this.model = null;
    } else {
      this.model = this.filterValue;
    }
  }

  get loading() {
    if (this.type && this.type === "users") return UsersModule.loading;
    return true;
  }

  get LABEL() {
    return this.label || "select";
  }

  get items() {
    if (this.type && this.type === "users")
      return UsersModule.users.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    return [];
  }

  created() {
    this.onFilterChange();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
