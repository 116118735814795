import axios from "axios";

import { AuthModule } from "@/store/modules/auth";

const api = axios.create({
  baseURL: process.env.VUE_APP_DJANGOBASEURL,
  timeout: 100000,
});

// Request interceptors
api.interceptors.request.use(
  (config) => {
    if (AuthModule.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Token ${AuthModule.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default api;
