import { Module, VuexModule, MutationAction, Mutation, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ApiUser, ApiUserRequest } from "@/static/apiModels";
import { getUsers } from "@/api/django/users";

export interface IUserState {
  users: ApiUser[];
  loading: boolean;
}

@Module({
  dynamic: true,
  store,
  name: "users",
  namespaced: true,
})
class Users extends VuexModule implements IUserState {
  users: ApiUser[] = [];

  loading: boolean = false;

  get getUsers(): ApiUser[] {
    return this.users || [];
  }

  get getUsersURL(): string[] {
    const temp: string[] = [];
    this.users.forEach((element) => {
      temp.push(element.url);
    });
    return temp;
  }

  get filteredCuratorsUrls(): string[] {
    return this.getUsersURL.filter((user: String) => !user.includes("users/6/"));
  }

  // returns an array of cthe catecgories in a minimum string to identify them by name.
  get uniqueUsers() {
    const names = this.users.map((user) => user.username);
    return names.map((user, userIndex) => {
      for (let i = 0; i < user.length; i += 1) {
        const stw = user.substr(0, i + 1);
        const ambigious = names.some((name, index) => {
          if (index === userIndex) {
            return false;
          }
          return name.startsWith(stw);
        });
        if (!ambigious) {
          return stw;
        }
      }
      return user;
    });
  }

  get uniqueUser() {
    return (name: string) => {
      return this.uniqueUsers[this.users.findIndex((user) => user.username === name)];
    };
  }

  get byUniqueName() {
    return (name: string) => {
      return this.users.find((user) => user.username.startsWith(name));
    };
  }

  get byId() {
    return (id: number) => {
      return this.users.find((user) => user.id === id);
    };
  }

  get byUrl() {
    return (url: string) => {
      return this.users.find((user) => user.url === url);
    };
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @MutationAction({ mutate: ["users", "loading"] })
  async fetchUsers(params?: ApiUserRequest) {
    // TODO
    // tslint:disable-next-line
    // @ts-ignore
    this.commit("setLoading", true);
    this.loading = true;
    const PARAMS = params || {
      page_size: 100,
    };
    const response = await getUsers(PARAMS);
    return { users: response.data.results, loading: false };
  }
}

export const UsersModule = getModule(Users);
