import Vue from "vue";
// @ts-ignore
import App from "@/App.vue";

import store from "@/store";
import i18n from "@/i18n";
// @ts-ignore
import { RecycleScroller } from "vue-virtual-scroller";
// @ts-ignore
import { JsonCSV } from "vue-json-csv";
import vuetify from "./plugins/vuetify";
// import '@/filters';
import router from "./router";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.component("RecycleScroller", RecycleScroller);
Vue.component("downloadCsv", JsonCSV);

// @ts-ignore
// window.Vue = Vue;

Vue.config.productionTip = false;

const vm = new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default vm;
