<!-- renders tags given an url array-->
<template>
  <span v-if="urls">
    <TagChip
      dense
      :key="index"
      :url="url"
      v-for="(url, index) of urls"
    >
    </TagChip>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import TagChip from "@/components/TagChip.vue";
import Logger from "../services/LoggerService";

@Component({
  components: { TagChip },
  name: "TagList",
})
export default class TagList extends Vue {
  @Prop() readonly urls: string[] | undefined;

  l = new Logger("TagList.vue");
}
</script>

<style scoped lang="scss"></style>
