import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import debounce from "debounce";
import NotificationLog from "@/models/Notification";

import store from "@/store";
import SimpleNotification from "@/models/SimpleNotification";
import { SNACK_BAR_TIME } from "../../constants/index";

interface INotificationState {
  notifications: Array<NotificationLog>;
  showSnackbar: Boolean;
  timeOfLastActivation: Date | null;
}

@Module({
  dynamic: true,
  store,
  name: "notifications",
  namespaced: true,
})
class Notifications extends VuexModule implements INotificationState {
  showSnackbar: Boolean = false;

  timeOfLastActivation: Date | null = null;

  notifications: Array<NotificationLog> = [];

  get getNotifications(): Array<NotificationLog> {
    return this.notifications;
  }

  get notificationsLimited(): Function {
    return (limit: number) => {
      if (limit && this.notifications.length > limit) {
        // get only as many as limit:
        return this.notifications.slice(this.notifications.length - limit);
      }
      return this.notifications;
    };
  }

  get getShowSnackbar(): Boolean {
    return this.showSnackbar;
  }

  get getTimeOfLastActivation(): Date | null {
    return this.timeOfLastActivation;
  }

  @Mutation
  setShow(show: Boolean) {
    this.showSnackbar = show;
  }

  @Mutation
  addNotification(notification: NotificationLog) {
    const now = new Date();
    const newNotification = notification;
    if (!notification.time) {
      newNotification.time = now;
    }
    this.notifications.push(newNotification);
    this.showSnackbar = true;
    this.timeOfLastActivation = now;
  }

  @Action
  setMessage(notification: NotificationLog) {
    this.context.commit("addNotification", notification);
    this.context.dispatch("debouncedShow");
  }

  @Action
  debouncedShow() {
    debounce(() => {
      if (
        this.timeOfLastActivation &&
        Date.now() - this.timeOfLastActivation.getTime() >= SNACK_BAR_TIME
      ) {
        this.context.commit("setShow", false);
      }
    }, SNACK_BAR_TIME)();
  }

  @Action
  setSuccess(notification: SimpleNotification) {
    this.context.dispatch("setMessage", {
      message: notification.message,
      icon: notification.icon,
      time: new Date(),
      type: "success",
    });
  }

  @Action
  setError(notification: SimpleNotification) {
    this.context.dispatch("setMessage", {
      message: notification.message,
      fullData: notification.fullData,
      icon: notification.icon,
      time: new Date(),
      type: "error",
    });
  }

  @Action
  setNeutral(notification: SimpleNotification) {
    this.context.dispatch("setMessage", {
      message: notification.message,
      icon: notification.icon,
      time: new Date(),
      type: "info",
    });
  }
}

const NotificationsModule = getModule(Notifications);

export { INotificationState, NotificationsModule };
