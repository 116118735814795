<!-- meant to be copied to be a new component-->
<template>
  <v-card
    v-if="selection.length > 0 || show"
    class="my-3"
  >
    <div
      class="d-flex justify-space-between flex-wrap py-4 px-12"
      style="gap: 4em"
    >
      <div
        class="flex-grow-1 flex-shrink-1"
        style="max-width: 500px; min-width=300px"
      >
        <h3>Document Selection</h3>
        {{ selection ? `${selection.length} of ${collection.documents.length}` : "" }}
        <TagAutocomplete
          @input="update()"
          v-model="filter.and"
          placeholder="and"
          :onlyCurrent="true"
        ></TagAutocomplete>
        <TagAutocomplete
          @input="update()"
          v-model="filter.or"
          placeholder="or"
          :onlyCurrent="true"
        ></TagAutocomplete>
        <TagAutocomplete
          @input="update()"
          v-model="filter.minus"
          placeholder="minus"
          :onlyCurrent="true"
        ></TagAutocomplete>
      </div>

      <div
        class="d-flex justify-space-between flex-wrap flex-grow-1 flex-shrink-1"
        style="max-width: 900px; gap: 2em"
      >
        <div
          class="nopPadding flex-grow-1 flex-shrink-1"
          style="max-width: 300px; min-width=200px"
        >
          <CollectionCreation :selection="selection" />
        </div>

        <div
          class="nopPadding flex-grow-1 flex-shrink-1"
          style="max-width: 300px; min-width=200px"
        >
          <h3>Add to existing Collection</h3>
          <v-checkbox
            data-test="collection-jump-to-new-checkbox"
            label="jump to collection after creating"
            v-model="jumpToNewCollection"
          ></v-checkbox>
          <CollectionSelect :selectedCollection.sync="existingColl"></CollectionSelect>
          <v-btn
            data-test="collection-add-to-existing-button"
            primary
            :disabled="!existingColl || selection.length === 0 || AddToExistingCollIsLoading"
            @click="addToExistingCollection()"
          >
            Add
            <v-progress-circular
              indeterminate
              size="9"
              v-if="AddToExistingCollIsLoading"
            ></v-progress-circular>
          </v-btn>
        </div>

        <div class="nopPadding flex-shrink-1">
          <h3>Delete</h3>
          <p>delete {{ selection.length }} documents</p>
          <v-btn
            color="primary"
            :disabled="selGrt1"
            @click="() => (confirmDocumentDeletion = true)"
          >
            {{ $t("DocumentSelect.delete_title") }}
          </v-btn>
          <ConfirmationModal
            v-model="confirmDocumentDeletion"
            :text="
              $tc('DocumentSelect.delete_text', selection.length, {
                documents: selection.length,
              })
            "
            :action="() => deleteDocsFromCollection(true)"
          />
        </div>
      </div>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Logger from "../services/LoggerService";
import { CollectionsModule } from "../store/modules/collections";
import TagAutocomplete from "./TagAutocomplete.vue";
import { CompleteDocument, CollectionBaseInfo } from "../static/apiModels";
import CollectionSelect from "./CollectionSelect.vue";
import CollectionCreation from "./CollectionCreation.vue";
import { patchCollection, getOneCollection } from "../api/django/collections";
import { NotificationsModule } from "../store/modules/notifications";
import ConfirmationModal from "./Utilities/ConfirmationModal.vue";

interface Filter {
  and: string[];
  or: string[];
  minus: string[];
}

@Component({
  components: {
    TagAutocomplete,
    CollectionSelect,
    CollectionCreation,
    ConfirmationModal,
  },
  name: "DocumentSelect",
})
export default class DocumentSelect extends Vue {
  @Prop({ default: true }) readonly show!: boolean;

  confirmDocumentDeletion: boolean = false;

  l = new Logger("DocumentSelect.vue");

  CM = CollectionsModule;

  jumpToNewCollection: boolean = true;

  filter: Filter = {
    and: [],
    or: [],
    minus: [],
  };

  @Watch("show", { immediate: true })
  updateSelection() {
    if (!this.show) {
      this.selection = []; // clears last selection array with each incoming false boolean variable 'show'
    }
  }

  get AddToExistingCollIsLoading() {
    return this.CM.addToCollectionIsLoading;
  }

  get selGrt1() {
    return this.selection.length < 1;
  }

  existingColl: CollectionBaseInfo | null = null;

  get collection() {
    return CollectionsModule.currentCollection;
  }

  async addToExistingCollection() {
    if (!this.existingColl) return;
    const toUpdate = (await getOneCollection(this.existingColl.id)).data;

    if (!toUpdate) return;
    this.l.debug("was für neue alte coll?", toUpdate.es_document, this.selection);
    this.CM.addToCollection({
      collection: toUpdate,
      documents: this.selection,
      jump: this.jumpToNewCollection,
    });
  }

  deleteDocsFromCollection(doIt: boolean) {
    if (!doIt) return;
    if (this.collection === null) return;
    this.l.debug("delete them docs", this.collection.es_document);
    const newDocs = this.collection.es_document.filter((doc) => !this.selection.includes(doc));
    const editedColl = { es_document: newDocs };

    patchCollection(editedColl, this.collection.id)
      .then(() => {
        NotificationsModule.setSuccess({
          message: `${this.$t("success.could_delete")}`,
          icon: "mdi-trash-can",
        });
        this.selection = [];
        // @ts-ignore
        this.CM.fetchCurrentCollection(this.collection.id);
      })
      .catch((err) => {
        NotificationsModule.setError({
          fullData: err.response.data,
          message: `${this.$t("errors.could_not_delete")}`,
          icon: "mdi-do-not-disturb",
        });
      });
  }

  update() {
    let newSelection: CompleteDocument[] = [];
    let and = false;
    let or = false;
    if (this.filter.and.length > 0) {
      and = true;
      newSelection = Object.assign([], this.docs);
      newSelection = newSelection.filter((doc) =>
        this.filter.and.every((url) => doc.tag.includes(url)),
      );
    }

    if (this.filter.or.length > 0) {
      or = true;
      this.docs.forEach((doc) => {
        if (doc.tag.some((tag) => this.filter.or.includes(tag))) {
          newSelection.push(doc);
        }
      });
    }

    if (this.filter.minus.length > 0) {
      if (!(and || or)) {
        newSelection = Object.assign([], this.docs);
      }
      newSelection = newSelection.filter(
        (doc) => !doc.tag.some((tag) => this.filter.minus.includes(tag)),
      );
    }
    newSelection = [...new Set(newSelection)];
    this.selection = newSelection.map((doc) => doc.url);
  }

  get docs() {
    const coll = CollectionsModule.getCurrentCollection;
    return coll && coll.documents ? coll.documents : [];
  }

  // array of url of selected rows
  get selection() {
    return this.CM.getSelection;
  }

  set selection(val: string[]) {
    this.CM.setSelection(val);
  }
}
</script>

<style scoped lang="scss">
.nopPadding * {
  //padding-left: 0%;
  //position: relative;
  left: 0px;
  margin: 0px;
}
</style>
