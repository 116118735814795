// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../layers/heroku_nodejs-pnpm-install/virtual/store/css-loader@6.10.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seperator[data-v-d3a42a9a] {
  width: 50px;
}
.showyIcons[data-v-d3a42a9a] {
  margin-left: 13px;
  float: left;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}
hr[data-v-d3a42a9a] {
  clear: both;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
