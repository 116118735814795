<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn
        :data-test="`${componentName}-toggle-tagging-mode`"
        :style="{ bottom: bottom, right: right }"
        right
        fab
        color="primary"
        fixed
        @click="SM.switchTaggingMode"
        v-on="on"
      >
        <v-icon>{{ SM.getTaggingMode ? "mdi-close" : "mdi-tag" }}</v-icon>
      </v-btn>
    </template>
    {{
      SM.getTaggingMode
        ? $t("tagging.toggle-btn.tooltip-close")
        : $t("tagging.toggle-btn.tooltip-open")
    }}
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { SettingsModule } from "../store/modules/settings";

@Component({
  components: {},
  name: "TaggingToggleBtn",
})
export default class TaggingToggleBtn extends Vue {
  componentName = "TaggingToggleBtn";

  @Prop({ default: 70 }) toggledOffset!: number; // "!" Is the definite assignment operator

  SM = SettingsModule;

  position: number = 24;

  get right() {
    return `${this.position + 16}px`;
  }

  get bottom() {
    if (this.SM.getTaggingMode) {
      return `${this.position + this.toggledOffset}px`;
    }
    return `${this.position}px`;
  }

  toggleTaggingMode() {
    this.SM.switchTaggingMode();
  }
}
</script>
