<template>
  <v-layout justify-center>
    <v-flex
      xs12
      sm10
      md8
      lg6
    >
      <vContainer>
        <h1>Login</h1>
        <v-form @submit.prevent="authModule.login(login)">
          <template v-if="isLoggedin">
            <v-alert
              type="info"
              outlined
              border="left"
              elevation="2"
              icon="mdi-information"
            >
              <strong>you are already logged in</strong>
            </v-alert>
          </template>
          <template v-else>
            <v-text-field
              data-test="login-field"
              type="text"
              label="User"
              v-model="login.username"
            >
            </v-text-field>
            <v-text-field
              data-test="password-field"
              type="password"
              label="Password"
              v-model="login.password"
            >
            </v-text-field>
            <v-btn
              type="submit"
              block
              :disabled="authModule.loading"
              >submit<v-progress-circular
                indeterminate
                size="9"
                v-if="authModule.loading"
              ></v-progress-circular>
            </v-btn>
          </template>
        </v-form>
      </vContainer>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import SnackbarSwitch from "./SnackbarSwitch.vue";
import DarkmodeSwitch from "./darkmodeSwitch.vue";
import { ApiUserLogin } from "../static/apiModels";

import { AuthModule } from "../store/modules/auth";

import { NotificationsModule } from "../store/modules/notifications";
import Logger from "../services/LoggerService";

@Component({
  // if you use components add them here
  components: { SnackbarSwitch, DarkmodeSwitch },
  /* name is necessary for recursive components
   * (at least in older versions, might be auto generated through the vue-property-decorator)
   */
  name: "Login",
})
export default class Login extends Vue {
  l = new Logger("Login.vue");

  NM = NotificationsModule;

  authModule = AuthModule;

  login: ApiUserLogin = { username: "", password: "" };

  // lifecycle hook

  @Watch("isLoggedin")
  onLogin() {
    // @ts-ignore
    if(this.$route && this.$route.query && this.$route.query.redirect) this.$router.push(this.$route.query.redirect);
  }

  get isLoggedin(): boolean {
    return !!AuthModule.token;
  }

  created() {
    if (AuthModule.loggedIn) {
      NotificationsModule.setNeutral({
        message: "you must log out before you can relogin",
        icon: "mdi-emoticon-wink",
      });
      this.$router.back();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
