<template>
  <v-list>
    <v-subheader>Presets</v-subheader>
    <v-list-item
      v-for="pre in presets"
      :key="pre.value"
    >
      <v-list-item-content>
        <v-btn
          :data-test="`doc-header-filter-preset-${pre.value}`"
          text
          block
          @click="clickPreset(pre.rows)"
          >{{ pre.value }}</v-btn
        >
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-subheader>Spalten</v-subheader>
    <v-list-item
      :data-test="`doc-header-toggle-${dH.value}`"
      v-for="dH in header"
      :key="dH.value"
      @click="switchShow(dH.value)"
    >
      <v-list-item-content>
        <v-list-item-title>{{ dH.text }}</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <v-checkbox
          @click.prevent="l.log('checkbox')"
          v-model="dH.show"
        ></v-checkbox>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import { HeadersModule } from "../store/modules/headers";
import Logger from "../services/LoggerService";

@Component({
  components: {},
  name: "DocumentHeaderFilter",
})
export default class HeaderClass extends Vue {
  l = new Logger("SearchView.vue");

  get s() {
    return HeadersModule.show;
  }

  get header() {
    return HeadersModule.getAllHeader;
  }

  get presets() {
    return HeadersModule.getPresetList;
  }

  switchShow(ele) {
    HeadersModule.swapShow(ele);
  }

  clickPreset(preset) {
    HeadersModule.doPresets(preset);
  }
}
</script>
