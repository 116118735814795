<template>
  <vContainer fluid>
    <h1>Lemma</h1>
    <v-autocomplete
      data-test="lemma-view-search"
      :items="searchLemma"
      item-text="lemmatisierung"
      item-value="lemmatisierung"
      full-width
      :search-input.sync="search"
      append-icon="mdi-filter"
      append-outer-icon="mdi-magnify"
      @click:append-outer="onSearchSubmitted()"
      @click:append="showFilters()"
      v-on:keyup.enter="onSearchSubmitted()"
      outline
      hide-no-data
      hide-selected
      placeholder="Suche"
      clearable
      persistent-hint
      hint="-o Original -n Normalisierung -l Lemmatisierung -a Artikel Lemma"
    ></v-autocomplete>
    <LemmaFilter v-if="boolFilters"></LemmaFilter>
    <v-data-table
      data-test="lemma-view-lemma-table"
      :loading="loading"
      :headers="headers"
      :items="lemmaResponse.results"
      :search="search"
      :sort-by.sync="pagination.sortBy"
      :sort-desc.sync="pagination.desc"
      :footer-props="{
        'items-per-page-options': itemsPerPageItems,
      }"
      @click:row="goToLemma($event.id)"
      :server-items-length="lemmaResponse ? lemmaResponse.count : 0"
      :options.sync="pagination"
      fixed-header
    >
      <template v-slot:[`item.lemmatisierung`]="{ item }">
        {{
          item.simplex
            ? item.art_lemmatisierung
            : item.lemmatisierung
              ? item.lemmatisierung
              : "Keine Lemmatisierung vorhanden"
        }}
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        {{ item.comment ? item.comment : "Kein Kommentar vorhanden" }}
      </template>
      <template v-slot:[`item.simplex`]="{ item }">
        {{ item.simplex ? item.art_lemmatisierung : "oberstes Lemma" }}
      </template>
      <template v-slot:[`item.pos`]="{ item }">
        {{ item.pos ? item.pos : "Kein POS vorhanden" }}
      </template>
      <template v-slot:[`item.suggestion`]="{ item }">
        {{ item.suggestion ? item.suggestion : " ------------" }}
      </template>
      <template v-slot:[`item.user`]="{ item }">
        {{
          item.assigned_task
            ? item.assigned_task.user !== null
              ? item.assigned_task.user_name
              : "Keinem User zugewiesen"
            : "Keinem User zugewiesen"
        }}
      </template>
      <template v-slot:[`item.task`]="{ item }">
        <template v-if="item.assigned_task">
          <v-icon
            small
            class="mr-2"
            @click="goToTask(item.assigned_task.task)"
          >
            mdi-eye
          </v-icon>
        </template>
        <template v-else> Keiner Aufgabe zugewiesen </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="goToEdit(item.id)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </vContainer>
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

import { LemmaModule } from "@/store/modules/lemma";

import { AuthModule } from "../store/modules/auth";
import LemmaFilter from "./LemmaFilter.vue";
import Logger from "../services/LoggerService";

@Component({
  components: {
    LemmaFilter,
  },
  name: "LemmaView",
})
export default class LemmaView extends Vue {
  l = new Logger("LemmaView.vue");

  AM = AuthModule;

  LM = LemmaModule;

  search: string = "";

  boolFilters: boolean = false;

  triggerSearch: boolean = false;

  showSearch: boolean = false;

  confirmIt: boolean = false;

  itemsPerPageItems = [5, 10, 25, 50, 100, 500, 1000, 2000];

  pagination: any = {
    itemsPerPage: 25,
    sortBy: ["simplex", "org"],
    sortDesc: [false],
  };

  currentPage: number = 1;

  headers = [
    { text: "HL", value: "org", sortable: true },
    { text: "Norm", value: "norm", sortable: true },
    { text: "Belegzahl", value: "count", sortable: true },
    { text: "Description", value: "comment", sortable: false },
    { text: "Lemmatisierung", value: "lemmatisierung", sortable: true },
    { text: "Artikel-Lemma", value: "simplex", sortable: true },
    { text: "POS", value: "pos", sortable: false },
    { text: "Vorschlag", value: "suggestion", sortable: false },
    {
      text: "Zugewiesener User",
      value: "user",
      sortable: false,
    },
    { text: "Zugewiesene Aufgabe", value: "task", sortable: false },
    // { text: 'Assigned User', value: 'bearbeiter', sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ];

  get filters() {
    return LemmaModule.filters;
  }

  get searchLemma() {
    return LemmaModule.searchLemma;
  }

  get loading() {
    return LemmaModule.loading;
  }

  get lemmaResponse() {
    return LemmaModule.lemmata ? LemmaModule.lemmata : [];
  }

  showFilters() {
    this.boolFilters = !this.boolFilters;
  }

  @Watch("pagination", { deep: true })
  onPaginationChange(val: any) {
    this.l.log("Switching page to val", val.page);
    if (val.page > this.currentPage) {
      this.LM.fetchNextLemmaPage();
      this.currentPage += 1;
    } else if (val.page < this.currentPage) {
      this.LM.fetchPreviousLemmaPage();
      this.currentPage -= 1;
    } else {
      this.filters.page_size = this.pagination.itemsPerPage;
      this.filters.page = this.pagination.page;
      this.filters.ordering = `${this.pagination.sortDesc[0] ? "-" : ""}${this.pagination.sortBy}`;
      LemmaModule.setFilters({ ...this.filters });
      this.LM.fetchLemmata(this.filters);
    }
  }

  @Watch("search")
  onSearchCleared() {
    if (
      (!this.search || this.search.length === 0) &&
      (this.filters.task || this.filters.assignee)
    ) {
      return;
    }

    // if search got cleared
    if (!this.search && !this.triggerSearch) {
      this.clearSearch();
      LemmaModule.fetchLemmata(this.filters);
    }

    LemmaModule.fetchSearchLemma({
      lemmatisierung: this.search,
      page_size: 20,
    });
  }

  clearSearch() {
    delete this.filters.lemmatisierung;
    delete this.filters.org;
    delete this.filters.norm;
    delete this.filters.simplex__lemmatisierung;
    LemmaModule.setFilters({ ...this.filters });
  }

  // Watcher for thr filters
  // Checks and edits the search String based on what the User entered
  @Watch("filters", { deep: true })
  onFiltersChange() {
    if (!this.filters) return;
    this.triggerSearch = true;
    this.search = "";
    if (this.filters.lemmatisierung) {
      this.search += ` -l ${this.filters.lemmatisierung}`;
    }

    if (this.filters.org) {
      this.search += `-o ${this.filters.org}`;
    }

    if (this.filters.norm) {
      this.search += ` -n ${this.filters.norm}`;
    }

    if (this.filters.simplex__lemmatisierung) {
      this.search += ` -a ${this.filters.simplex__lemmatisierung}`;
    }
    if (this.search !== null) {
      this.search = this.search.trim();
    }
    LemmaModule.fetchLemmata(this.filters).then(() => {
      this.triggerSearch = false;
    });
  }

  // Edits the filter when a new serach has been submitted
  onSearchSubmitted() {
    const o = this.search.match(/-o.+?((?=-)|$)/);
    const n = this.search.match(/-n.+?((?=-)|$)/);
    const l = this.search.match(/-l.+?((?=-)|$)/);
    const a = this.search.match(/-a.+?((?=-)|$)/);

    // Search lemmatisierung
    if (l) {
      const lemma = l[0].replace("-l", "").trim();
      this.filters.lemmatisierung = lemma;
    }

    // Search original
    if (o) {
      const org = o[0].replace("-o", "").trim();
      this.filters.org = org;
    }

    // Search normalisierung
    if (n) {
      const norm = n[0].replace("-n", "").trim();
      this.filters.norm = norm;
    }

    // Serach the Artikel Lemma
    if (a) {
      const art = a[0].replace("-a", "").trim();
      this.filters.simplex__lemmatisierung = art;
    }

    if (!(o || n || l || a)) {
      this.filters.lemmatisierung = this.search;
    }

    LemmaModule.setFilters({ ...this.filters });
    LemmaModule.fetchLemmata(this.filters);
  }

  goToLemma(id: number) {
    this.l.log("goto Lemma", id);
    this.$router.push(`/lemma/${id.toString()}`);
  }

  goToEdit(id: number) {
    this.l.log("gotoedit", id);
    this.$router.push(`/lemma/${id.toString()}/edit`);
  }

  goToTask(url: string) {
    const regexp = /\/(\d+)\/$/g;
    const id = regexp.exec(url);
    if (id === null) {
      return;
    }
    this.l.log("gototask", id[1]);
    this.$router.push(`/task/${id[1].toString()}`);
  }

  // lifecycle hook

  created() {
    LemmaModule.fetchLemmata(this.filters);
  }
}
</script>
